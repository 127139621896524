.issue-activity-item {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }

  .ant-btn-default {
    background: transparent;
  }

  .editor-shell {
    background: var(--color-layout-container) !important;
    border: 1px solid var(--color-calendar-card-border)!important;

    .text-editor-placeholder {
      top: 8px;
      left: 25px;
    }

    &.readonly-mode {
      background: transparent !important;
      border-color: transparent!important;

      .text-editor-placeholder {
        left: 0;
      }

    }

    .toolbar {
      background: transparent;
    }

  }

}

.issue-activity-item-wrap {
  padding-left: 40px;
  margin-top: 4px;
}

.issue-activity-item-content {
  width: 100%;
  display: grid;
  grid-template-columns: 87px 1fr;
  gap: 8px;
  align-items: flex-start;
  padding-top: 8px;
  padding-left: 8px;
  border-top: 1px solid var(--color-border-third);

  &_full {
    grid-template-columns: 1fr;
  }

  &__title {
    display: flex;
    padding: 4px 0;
    color: var(--color-primary-weaker-second);

    .uiKit-text {
      color: inherit;
    }

  }

  .readonly-mode {
    padding-top: 0!important;
  }

  .editor-shell.variant__new-style .ContentEditable__root {
    font-size: 14px;
  }

}


//html[data-app-theme-id="1"] {
//  .issue-activity-item {
//    .editor-shell {
//      background: var(--color-layout-container);
//
//      &.readonly-mode {
//        background: transparent !important;
//      }
//
//    }
//  }
//}
