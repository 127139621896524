//.color-picker {
//  padding: 8px;
//  box-shadow: 0 8px 32px 0 var(--color-shadow-second);
//  background: var(--color-layout-container);
//  border-radius: var(--border-radius-md);
//}

.color-picker {
  display: flex;
}

.color-picker-item {
  display: flex;
  align-items: center;

  &_open {
    .color-picker-item__arrow {
      transform: rotate(180deg);
    }
  }

  &__color {
    pointer-events: none;
    width: 20px;
    height: 20px;
    padding: 4px;
    box-sizing: border-box;

    div {
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-xs);
    }
  }

  &__arrow {
    display: flex;
    pointer-events: none;
    transition: transform .2s ease-in;
  }
}

.color-picker-list {
  position: fixed;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(6, 20px);
  gap: 8px;
  padding: 8px;
  box-shadow: 0 4px 20px 0 var(--color-shadow-second);
  background: var(--color-layout-container);
  border-radius: var(--border-radius-md);

  &_static {
    position: static;
    box-shadow: none;
    background: none;
  }

  &_row {
    display: flex;
  }
}

.color-picker-list-item {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    .color-picker-list-item__border {
      opacity: 1;
    }
  }

  &__border {
    box-sizing: border-box;
    height: 100%;
    border: 1px solid transparent;
    border-radius: var(--border-radius-xsm);
    //opacity: 0.5;
    transition: opacity .2s ease-in;
  }

  &__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.15;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    border-radius: var(--border-radius-xs);
  }
}