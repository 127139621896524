.print-metric {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //display: none;
  width: 100%;
  z-index: -2;
  background: #fff;

  //padding: 20px 32px;
  box-sizing: border-box;

  @media print {
    //position: static;
    z-index: 100;
  }

  .recharts-legend-wrapper {
    left: 20px!important;
    bottom: 2px!important;

    .uiKit-shape.__circle {
      width: 10px!important;
      height: 10px!important;
    }

    .uiKit-text {
      font-size: 12px!important;
    }

    >.d-stack-column {
      gap: 4px;
    }
  }

  .ant-input-number {
    width: 100%!important;
    border-color: transparent;
    background: transparent!important;

    input {
      text-align: center!important;
    }

  }

  .dashboard-chart__wrapper {
    //margin-left: -24px;
  }

  .recharts-wrapper {
    padding-bottom: 8px;
  }

  .recharts-layer {
    text {
      font-size: 14px!important;
      font-weight: bold;
    }
  }

  .recharts-cartesian-axis-tick {
    text {
      font-size: 14px!important;
      color: #8E939D;
    }

  }

  .recharts-tooltip-wrapper {
    display: none;
  }
}

.print-metric-wrap {
  page-break-after: always;
}


.print-metric-char {
  width: 100%;
}

.print-metric-company {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;

  &__name {
    font-size: 12px;
    color: var(--color-primary-weaker-second);
  }
}

.print-metric-user {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 56px;

  &__role, &__name {
    font-size: 15px;
    line-height: 21px;
  }

  &__role {
    font-weight: 600;
  }

  &__name {
    display: flex;
    flex-wrap: wrap;
  }
}

.print-metric-user-wrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.print-metric-wrapper {
  width: 100%;
  max-width: 800px;
}
