.user-tooltip {
  //max-width: 420px !important;
  .ant-tooltip-inner {
    //background: var(--color-white);
    //box-shadow: var(--shadow-down-lg);
  }


  &_new-style {
    //.ant-popover-inner {
    //
    //}

    .user-tooltip {
      &_name {
        font-size: 12px!important;
      }

      &_link {
        &, & a {
          font-size: 12px !important;
        }
      }
    }


  }

  &_name {
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 1.25em !important;
    //line-height: 1em !important;
    white-space: nowrap;
    //white-space: break-spaces;
    //word-spacing: 9999px;
    //width: auto;
    //display: block;
  }

  .user-tooltip_link {
    opacity: 0.8;
    transition: 0.15s;
    a {
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      transition: 0.15s;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      opacity: 1;
      a {
        //color: var(--color-primary-base)
      }
    }
  }
}
