.planning-page-metric {
  background: transparent;
  border: none;
  box-shadow: none;

  .collapse-panel, .ant-collapse-content {
    border: none!important;
    background: none!important;
  }

  .ant-collapse-content-box {
    background: none!important;
    padding: 0!important;
  }

  .ant-collapse-header {
    display: inline-flex!important;
  }
}
