.questions__digital-list {
  //lists-style-type: none;
  //counterChip-reset: num;
  padding-inline-start: 20px !important;
  margin: 0;

  li {
    //position: relative;
    //margin: 0 0 0 0;
    //padding: 0 0 10px 0;
    //&:before {
    //  content: counterChip(item) '.';
    //  counterChip-increment: item;
    //}
    &::marker {
      font-size: 15px;
      font-weight: 600;
      margin-right: 4px;
    }
  }
}

.passing-regulation-test {
  max-height: 700px;
  overflow-y: auto;

  &__dialog-wrapper {
    .MuiDialog-container {
      > div {
        width: 100%;
        max-width: 610px !important;
      }
    }

    &-fullHeight {
      .MuiDialog-container {
        > div {
          min-height: 400px;
        }
      }
    }
  }

  &__question {
    margin-left: 4px;
    font-size: 15px !important;
    font-weight: 600 !important;
    margin-bottom: 16px !important;
  }

  &__radio-group {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;

    > :not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__answer-label {
    font-size: 14px !important;
    margin: 0 !important;
    //color: var(--color-gray-base) !important;
  }

  &__checkbox {
    //padding-left: 0 !important;
    //padding-bottom: 3px !important;
  }

  &__result-block {
    //background-color: var(--color-layout-fill-base);
    border-radius: 4px;
    padding: 16px;

    &-paper {
      width: 54px;
      height: 54px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 31px;
        width: 31px;
      }
    }

    &-title {
      font-size: 15px !important;
      font-weight: 500 !important;
    }
  }
}
