.count-regulars {
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: 12px;
  line-height: 1.4;
}

.adding-regulations-to-section-dialog {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 95vh;
    overflow: hidden;
  }

  .ant-modal-body {
    min-height: 50px;
    margin-right: -15px;
    padding-right: 15px;
    overflow: auto;
  }
}