.user-card {
  border-radius: var(--border-radius-sm);
  max-width: 350px;
  display: inline-flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  cursor: default;

  &_field {
    padding: 8px;
    background-color: var(--color-txt-alwayswhite);
  }

  &__avatar {
    //margin-right: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &-name {
      font-weight: 500;
      font-size: 15px;
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-additional-info {
      font-size: 12px;
      color: var(--color-gray-base);
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  &_new-style {
    .user-card {

      &__info {
        gap: 1px;
      }

      &__info-name {
        color: var(--color-txt-primary);
      }

      &__info-additional-info {
        color: var(--color-txt-secondary);
      }

    }

  }

}

