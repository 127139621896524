.tag-edit {
  display: grid;
  grid-template-columns: 36px 1fr 16px;
  padding: 8px;
  gap: 8px;
  border: 1px solid transparent;
  transition: border-color .2s ease-in;

  &_active {
    border-color: var(--color-primary-base);
    border-radius: var(--border-radius-md);
  }

}

.tag-edit-name {
  position: relative;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-calendar-text-base);

  &_edit {
    color: transparent;
  }

  span {
    white-space: pre-line;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    font-size: inherit!important;
    font-family: inherit!important;
    background: transparent!important;
    border: none!important;
    outline: none!important;
    box-shadow: none!important;
    line-height: inherit!important;
    color: var(--color-calendar-text-base);
  }
}

.tag-edit-remove {
  display: flex;
  min-height: 16px;

  .uiKit-iconButton {
    width: 16px!important;
    height: 16px!important;
    color: var(--color-gray-second);
    transition: color .2s ease-in;
  }
}