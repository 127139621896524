.release-history {
  &__content {
    width: 100%;
    background-color: var(--color-white);
    border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
    box-shadow: var(--shadow-down-sm);
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
}
