.uiKit-button {
  --color-success-weaker: rgba(4, 214, 173, 1);
  //flex-shrink: 0;
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in;

  &.__text-wrap {
    white-space: pre-wrap !important;
    line-height: 1em !important;
  }

  //&:not(&.ant-btn-icon-only) {
  //  svg {
  //    margin-right: var(--indent-sm);
  //  }
  //}


  &._warning {
    background-color: var(--color-warning-base) !important;
    border-color: var(--color-warning-base) !important;

    &:hover,
    &:focus {
      background-color: var(--color-warning-weak) !important;
      border-color: var(--color-warning-weak) !important;
    }

    &:active {
      background-color: var(--color-warning-strong) !important;
      border-color: var(--color-warning-strong) !important;
    }
  }

  &._success {
    background-color: var(--color-success-base) !important;
    border-color: var(--color-success-base) !important;

    &:hover,
    &:focus {
      background-color: var(--color-success-weaker) !important;
      border-color: var(--color-success-weaker) !important;
    }

    &:active {
      background-color: var(--color-success-strong) !important;
      border-color: var(--color-success-strong) !important;
    }
  }

  &._success.disabled,
  &._success[disabled],
  &._success.disabled:hover,
  &._success[disabled]:hover,
  &._success.disabled:focus,
  &._success[disabled]:focus,
  &._success.disabled:active,
  &._success[disabled]:active,
  &._success.disabled.active,
  &._success[disabled].active,
  &._warning.disabled,
  &._warning[disabled],
  &._warning.disabled:hover,
  &._warning[disabled]:hover,
  &._warning.disabled:focus,
  &._warning[disabled]:focus,
  &._warning.disabled:active,
  &._warning[disabled]:active,
  &._warning.disabled.active,
  &._warning[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  &._active-filter {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      right: 4px;
      width: 10px;
      height: 10px;
      border: 1px solid var(--color-layout-container);
      border-radius: var(--border-radius-lg);
      background: var(--color-primary-base);
    }
  }
}
