.uiKit-datePicker {
  //
}

.uiKit-datePicker__dropdown {
  &.__showTimeActive {
    .ant-picker-ranges {
      display: none !important;
    }
  }

  &_static {
    position: static;

    .ant-picker-panel-container {
      box-shadow: none!important;
    }
  }
}
