.uiKit-switch {
  &.ant-switch {
    color: var(--color-txt-primary);
    background: var(--color-toggle-inactive);

    &:hover:not(.ant-switch-disabled) {
      background: var(--color-toggle-inactive-hover);
    }

    &.ant-switch-checked {
      background: var(--color-ctr-active);

      &:hover:not(.ant-switch-disabled) {
        background: var(--color-ctr-active-hover);
      }

    }
  }
}
.uiKit-switch__wrapper {
  margin-bottom: 0 !important;
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  > div {
    > div {
      > div {
        display: flex;
        align-items: center;
      }
    }
  }
}
