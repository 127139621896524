.driver-page__user {
  display: flex;
  align-items: flex-start;

  .driver-page__user--data {
    .driver-page__user--data__name {
      display: block;
      font-size: 20px;
      font-weight: bold;
      color: var(--color-dark-main);
    }

    .driver-page__user--data__timezone {
      display: block;
      font-size: 15px;
      line-height: 1.2em;
      //color: var(--color-primary-base);
      //opacity: 0.7;
    }

    .driver-page__user--data__email {
      display: block;
      font-size: 15px;
      color: var(--color-primary-base);
      text-decoration: none;
      //opacity: 0.7;
    }

    .driver-page__user--admins {
      display: block;
      font-size: 15px;
      //font-weight: bold;
      color: var(--color-dark-main);
      margin-left: 4px;
      //opacity: 0.7;
      transition: 0.15s;
      cursor: pointer;

      &:hover {
        color: var(--color-primary-base);
      }
    }

    .driver-page__user--data__company {
      display: block;
      font-size: 15px;
      color: var(--color-primary-base);
      //opacity: 0.7;
    }

    .driver-page__user--data__phone-number {
      display: block;
      font-size: 15px;
      color: var(--color-dark-main);
      //opacity: 0.7;
      text-decoration: none;
    }
  }
}

.driver-page__history {
  background: var(--color-layout-fill-base);
  border-radius: 4px;
  overflow-y: hidden;

  .__title {
    color: var(--color-primary-base);
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 4px;
  }

  .driver-page__history--list {
    overflow-y: auto;
  }
}

//.driver-page__interaction--list {
//  overflow-y: auto;

//.driver-page__interaction--item {
//  background: var(--color-layout-fill-base);
//  border-radius: 4px;
//  transition: 0.1s;
//  cursor: pointer;

//&:hover {
//  box-shadow: var(--shadow-down-sm);
//}

span {
  //font-size: 12px;
  //flex-grow: 1;
}

.driver-page__interaction--item__issue--name {
  display: block;
  font-size: 14px;
  color: var(--color-text-base);
}

.driver-page__interaction--item__issue--result {
  display: block;
  font-size: 12px;
  color: var(--color-text-weak);
}

.driver-page__interaction--item__issue--deadline {
  //display: block;
  font-size: 12px;
  color: var(--color-gray-weak);
  span {
    color: var(--color-dark-weak);
    margin-left: 0.25em;
  }
}
//}
//}

.driver-page__interaction--card {
  background: var(--color-layout-fill-base);
  border-radius: 4px;

  .driver-page__interaction--card__title {
    color: var(--color-primary-base);
    font-weight: bold;
    font-size: 16px;
  }
}

.driver-page__interaction--card__text {
  white-space: pre-wrap !important;
  max-height: 370px;
  overflow-y: auto;
}

.driver-page__history--list {
  .driver-page__history--list__item {
    border-radius: 4px;
    //box-shadow: var(--shadow-down-sm);

    .driver-page__history--list__item__name {
      margin-bottom: 0;
      font-size: 14px;
      color: var(--color-dark-middle);
      font-weight: normal;
    }

    .driver-page__history--list__item__email {
      margin-bottom: 0;
      font-size: 12px;
      color: var(--color-dark-weak);
      font-weight: normal;
    }

    .driver-page__history--list__item__date-created {
      margin-bottom: 0;
      font-size: 14px;
      color: var(--color-dark-weak);
      font-weight: normal;
    }

    .driver-page__history--list__item__item--name {
      margin-bottom: 0;
      font-size: 16px;
      color: var(--color-dark-main);
      font-weight: bold;
      word-break: break-word;
      white-space: pre-wrap;
    }

    .driver-page__history--list__item__text {
      margin-bottom: 0;
      font-size: 16px;
      color: var(--color-dark-middle);
      font-weight: normal;
      word-break: break-word;
      white-space: pre-wrap;
    }

    .driver-page__history--list__item__description {
      margin-bottom: 0;
      font-size: 14px;
      color: var(--color-dark-weak);
      font-weight: normal;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}
