.placeholder-technical-work-layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: var(--color-layout-light-gray);
}

.placeholder-technical-work {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  justify-content: center;
  padding: 20px 70px;
  color: var(--color-calendar-text-base);

  @media (max-width: 1169px) {
    padding: 20px;
  }

  &__icon {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    border: 1px solid var(--color-calendar-primary-base);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-calendar-primary-base);
  }

  &__title {
    font-size: 56px;
    font-weight: bold;

    @media (max-width: 1169px) {
      font-size: 40px;
    }

  }

  &__text {
    font-size: 24px;

    @media (max-width: 1169px) {
      font-size: 17px;
    }
  }
}

.placeholder-technical-work-logo {
  display: flex;
}
