.history {
  &__wrapper {
    overflow: hidden;
    //padding: 16px;
    //background-color: var(--color-white);
    //border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__title {
    //margin-top: 8px !important;
    //font-size: 16px !important;
    //font-weight: 500 !important;
  }

  &__list {
    display: grid;
    gap: 28px;
    margin-top: 28px;
    //max-height: 500px;
    overflow-y: auto;
    padding: 0 16px;

    .comment-card {
      margin: 0!important;
    }

  }
}

.history-header {
  height: 52px;
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid var(--color-calendar-default);
}

//.history-comment {
//  display: grid;
//  gap: 16px;
//}

.history-comment-row {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 12px;
  padding: 0 4px 0 16px;

  &_full {
    grid-template-columns: 1fr;
    padding: 0;
  }

  &__user {
    padding-top: 12px;
  }
}

.history-comment-wrap {
  display: grid;
  gap: 16px;
}

.history-comment-footer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.history-upload-button {
  margin-right: auto;
}
