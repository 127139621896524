.education-course-card-cover {
  display: flex;
  justify-content: center;
  padding-top: 26px;
  background: var(--color-srf-active);
  border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;

  img {
    width: auto;
    height: auto;
    aspect-ratio: unset;
  }
}
