.list-companies-card {
  &__delete-icon {
    transition: all 0.8s !important;
    stroke: black !important;
    svg {
      stroke: var(--color-text-weak);
    }
    &:hover {
      svg {
        transition: all 0.2s;
        stroke: var(--color-error-base);
      }
    }
  }
}
