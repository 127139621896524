.breadcrumbs-link {
  &.ant-typography {
    text-transform: capitalize !important;
    color: inherit !important;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumbs__wrapper {
  ol {
    align-items: center;

    a {
      display: block;
    }
  }
}

.overflow-text_30 {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 270px !important;
}
