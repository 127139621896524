$grid-el-height: 48px;
$grid-el-width: 84px;
$grid-el-offset: 4px;

.schedule__content-wrapper {
  display: flex;
  position: relative;
}

.schedule__main-wrapper {
  display: flex;
  flex-direction: column;
}

.schedule-header {
  box-shadow: none!important;
  background: var(--color-srf-primary);
  padding: 12px 16px;

  .ant-card-body {
    padding: 0!important;
  }
}


//.schedule-date_range_picker-button {
//  padding: 4px !important;
//  &.__no-wrap {
//    span {
//      white-space: nowrap !important;
//    }
//  }
//  span {
//    border-radius: 50%;
//    transition: 0.15s;
//    margin: auto !important;
//    font-size: 12px;
//    white-space: break-spaces;
//    line-height: 0.8em;
//    //&:hover {
//    //  background: rgba(0, 0, 0, 0.25);
//    //}
//    //&:first-of-type {
//    //  margin-right: auto !important;
//    //}
//    //&:last-of-type {
//    //  margin-left: auto !important;
//    //}
//    //svg {
//    //  font-size: 24px !important;
//    //}
//  }
//  svg {
//    flex-shrink: 0;
//  }
//}
//
//.schedule-date_range_picker {
//  height: 0 !important;
//  opacity: 0;
//  padding: 0 !important;
//  margin: 0 !important;
//  border: none !important;
//}
//
//.schedule-content__wrapper {
//  display: flex;
//  position: relative;
//
//  .schedule-roles-list__wrapper {
//    position: sticky;
//    z-index: 2;
//    left: 0;
//    //z-index: 110;
//    width: 260px;
//    //background: inherit;
//    //max-width: 256px;
//    //min-width: 200px;
//    display: flex;
//    flex-direction: column;
//    margin-right: 4px;
//    //box-shadow: var(--shadow-right-sm);
//    //resize: horizontal;
//    //overflow: auto;
//    .schedule-role__wrapper {
//      //overflow: hidden;
//      text-overflow: ellipsis;
//      margin-bottom: $grid-el-offset * 2.5;
//      &:last-child {
//        margin-bottom: 0;
//      }
//      .__name-overflowed {
//        height: 24px !important;
//        //height: $grid-el-height / 2 !important;
//        width: 100%;
//        overflow: hidden;
//        display: flex;
//        align-items: center;
//        font-size: 16px;
//        font-weight: bold;
//        white-space: nowrap;
//        transition: 0.15s;
//        .__chevron-icon {
//          //align-self: baseline;
//          opacity: 0.7;
//          transition: 0.15s;
//        }
//        .__text {
//          overflow: hidden;
//          text-overflow: ellipsis;
//        }
//        &:hover {
//          overflow: visible;
//          cursor: pointer;
//          color: var(--color-primary-base);
//          .__chevron-icon {
//            opacity: 0.9;
//          }
//          .__text {
//            //background: var(--color-layout-fill-base);
//            overflow: visible;
//          }
//        }
//        &.__selected {
//          .__chevron-icon {
//            transform: rotate(-90deg);
//          }
//        }
//      }
//    }
//
//    .schedule-roles-list-users-collapse {
//      .schedule-roles-list-users__wrapper {
//        display: flex;
//        flex-direction: column;
//        .schedule-roles-list-users_item {
//          margin-top: $grid-el-offset;
//          .ant-card-body {
//            padding: 0px 8px !important;
//            display: flex;
//            align-items: center;
//            //justify-content: space-between;
//            height: $grid-el-height;
//            overflow: hidden;
//          }
//          .__substitute {
//            margin-left: auto;
//            padding-left: 8px;
//            text-align: right;
//            font-size: 14px;
//            color: var(--color-dark-weak);
//            //opacity: 0.8;
//          }
//        }
//      }
//    }
//
//    .schedule-roles-list__limiter {
//      height: 80px;
//      //background: var(--color-white);
//      width: 100%;
//      position: sticky;
//      top: 0;
//      z-index: 1;
//      display: flex;
//      flex-direction: column;
//      justify-content: end;
//      //box-shadow: var(--shadow-down-sm);
//      //background: inherit;
//      box-sizing: content-box;
//      padding-bottom: $grid-el-offset * 2.5;
//    }
//  }
//
//  .schedule-main__wrapper {
//    display: flex;
//    flex-direction: column;
//    .schedule-calendar__wrapper {
//      //z-index: 109;
//      display: flex;
//      position: sticky;
//      top: 0;
//      z-index: 1;
//      padding-bottom: $grid-el-offset * 2.5;
//      //background: inherit;
//      .schedule-period-month_name {
//        height: 28px;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        margin-right: $grid-el-offset;
//        margin-bottom: $grid-el-offset;
//        border-radius: 4px;
//        text-transform: capitalize;
//        line-height: 1em;
//        text-align: center;
//        &:last-child {
//          margin-right: 0;
//        }
//      }
//      .schedule-period-month_dates__wrapper {
//        display: flex;
//        flex-wrap: nowrap;
//        .schedule-period-month_date-card {
//          width: $grid-el-width;
//          height: $grid-el-height;
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          margin-right: $grid-el-offset;
//          border-radius: 4px;
//          &:last-child {
//            //margin-right: 0;
//          }
//          &.__today {
//            span:first-of-type {
//              color: var(--color-primary-base);
//              font-weight: bold;
//            }
//            span:last-of-type {
//              //color: var(--color-primary-base);
//              //margin-left: 0.5em;
//              //font-size: 14px;
//              //opacity: 0.9;
//            }
//          }
//          span:first-of-type {
//            font-size: 22px;
//          }
//          span:last-of-type {
//            margin-left: 0.5em;
//            font-size: 14px;
//            opacity: 0.9;
//          }
//        }
//      }
//    }
//    .schedule-days__wrapper {
//      display: flex;
//      flex-direction: column;
//      .schedule-days-row-block__wrapper {
//        margin-bottom: $grid-el-offset * 2.5;
//        &:last-child {
//          margin-bottom: 0;
//        }
//        .__name-overflowed {
//          height: 24px !important;
//          //height: $grid-el-height / 2 !important;
//        }
//        .schedule-days-row-block-collapse {
//          .schedule-days-row-block {
//            display: flex;
//            flex-direction: column;
//            .schedule-days-row {
//              display: flex;
//              align-items: center;
//              height: $grid-el-height;
//              margin-top: $grid-el-offset;
//            }
//          }
//          //
//          //.schedule-roles-lists-users__wrapper {
//          //  display: flex;
//          //  flex-direction: column;
//          //  .schedule-roles-lists-users_item {
//          //    background: var(--color-white);
//          //    border-radius: 4px;
//          //    display: flex;
//          //    align-items: center;
//          //    height: $grid-el-height;
//          //    margin-top: $grid-el-offset;
//          //    overflow: hidden;
//          //  }
//          //}
//        }
//      }
//    }
//  }
//}
