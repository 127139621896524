.regulation-content-page {
  &__content {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    flex-direction: column;

    > .ant-card-body {
      padding: 0!important;
    }

    .editor-container {
      width: 1000px;
      max-width: 95%;
      min-width: 453px;
      margin: 0 auto;

      .TextEditorTheme__table {
        width: auto;
      }
    }

    .editor-shell {
      border-bottom: none;
    }

  }


}
