.regulation-skeleton {
  &_title {
    width: 380px !important;
  }

  &_header {
    &_user-name {
      height: 20px !important;
    }

    &_date-created {
      height: 14px !important;
    }
  }

  &_w {
    &-60 {
      width: 60% !important;

      span {
        width: 100% !important;
      }
    }

    &-70 {
      width: 70% !important;

      span {
        width: 100% !important;
      }
    }

    &-80 {
      width: 80% !important;

      span {
        width: 100% !important;
      }
    }

    &-90 {
      width: 90% !important;

      span {
        width: 100% !important;
      }
    }

    &-100 {
      width: 100% !important;

      span {
        width: 100% !important;
      }
    }

    .ant-skeleton-input {
      width: 100% !important;
    }

    span {
      width: 100% !important;
    }
  }

  &_h-14 {
    height: 14px !important;
  }
}
