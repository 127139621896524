.archive-issues-header {
  display: grid;
  gap: 4px;
  margin-bottom: 20px;

  &__sort {
    .ant-dropdown-menu {
      padding: 0!important;
      overflow: hidden;
    }

    .ant-dropdown-menu-item {
      padding: 6px 12px!important;
      //line-height: 1.4!important;
    }
  }
}

.archive-issues-header-row {
  display: grid;
  grid-template-columns: 1fr 36px 36px;
  gap: 12px;

  .uiKit-input {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .uiKit-iconButton {
    width: 36px;
    height: 36px;
  }

}