.fc {
  //
  table,
  tr,
  td,
  th {
    border-color: var(--color-brdr-default) !important;
  }

  .fc-timegrid-divider {
    background: var(--color-layout-fill-secondary) !important;
    padding: 0 !important;
  }
}

.fc-toolbar-title {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.fc-col-header-cell {
  .fc-col-header-cell-cushion {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: var(--color-text-base) !important;
  }
  &.fc-day-today {
    .fc-col-header-cell-cushion {
      color: var(--color-primary-base) !important;
      font-weight: 700 !important;
    }
  }
}

.fc-timegrid-event,
.fc-daygrid-event {
  //background: var(--color-primary-base) !important;
  //border: 1px solid var(--color-primary-base) !important;
  border-radius: 10px !important;
  .fc-event-time {
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  .fc-event-title {
    font-size: 10px !important;
    font-weight: 400 !important;
  }
}

.fc {
  tr:not(.fc-scrollgrid-section) {
    height: 50px !important;
  }

  td {
    div.fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame {
      //padding: 0 24px;
    }
  }
}

.fc-day-today {
  //background: var(--color-layout-fill-quaternary) !important;
  //border: none !important;
}

.fc-col-header-cell.fc-day {
  vertical-align: middle !important;
}

.fc-timegrid-slot-label {
  border: none !important;
  position: relative;


  .day-time {
    top: -25px;

    &_first {
      top: -16px;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 12px;
      border-top: 1px solid var(--fc-border-color);
    }

  }

}

.day-time {
  position: relative;
}

.fc-timegrid-slot-label-frame {
  text-align: center !important;
}

.__sticky-row {
  //background: white;
  //position: sticky;
  //bottom: 0;
  //z-index: 123321;
  //box-shadow: var(--shadow-down-lg);
}

.fc-event-main-frame {
  padding: 0 4px;
}

.fc-day-today {
  .fc-daygrid-day-number {
    color: var(--color-layout-fill-base)!important;
    background: var(--color-calendar-primary-base);
  }
}

.fc-timegrid-now-indicator-line {
  border-width: 2px 0 0!important;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--fc-now-indicator-color);
  }

}
