.orgchart-drawer-tab_recurring-tasks-title {
  font-size: 16px;
  color: var(--color-dark-middle);
  font-weight: bold;
  display: block;
}

.orgchart-drawer-tab_recurring-tasks-no_employee {
  font-size: 16px;
  color: var(--color-gray-weak);
  display: block;
}

.orgchart-drawer-tab_recurring-tasks-remove_button {
  opacity: 0.3;
  transition: 0.1s !important;

  &:hover {
    opacity: 1;
    color: var(--color-txt-error);
  }
}
