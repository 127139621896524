//html[data-app-theme-id="1"] {
//  .editor-shell {
//    border: 1px solid #424242;
//
//    &.variant__error {
//      border: 1px solid var(--color-error-base) !important;
//    }
//
//    &.readonly-mode:not(.variant__bordered) {
//      border: none;
//    }
//
//    .disabled-mode {
//      pointer-events: none;
//      opacity: 0.7;
//      background-color: rgba(255, 255, 255, 0.1);
//    }
//  }
//}

.editor-shell {
  border: 1px solid var(--color-inp-border-default);
  border-radius: var(--border-radius-md);
  color: var(--color-txt-primary);
  position: relative;
  line-height: 1.7;
  font-weight: 400;
  overflow: visible;
  transition: 0.3s;


  overflow: hidden;
  max-width: 100%;
  //transition: padding-top 0s;

  &.variant__error {
    border: 1px solid var(--color-brdr-error) !important;
  }

  //&:hover {
  //  border-color: var(--color-primary-base);
  //}


  //&.readonly-mode {
  //  border: none;
  //  border-radius: 0;
  //}

  .disabled-mode {
    pointer-events: none;
    opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.1);
  }


  @media print {
      overflow: visible!important;
  }
}


.readonly-mode:not(.variant__bordered) {
  border: none !important;
}

.readonly-mode.variant__bordered {
  .editor-container {
    box-sizing: border-box;
    padding: 7px 11px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.readonly-mode {
  &.variant__new-style {
    padding: 12px;
    .container-background {
      background: none !important;
    }
  }
}

.editor-shell .editor-container {
  position: relative;
  cursor: text;
  display: block;
  border-radius: var(--border-radius-md);
  flex-grow: 1;
}

.editor-shell {
  .editor-container.container-background {
    background: var(--color-srf-primary);
  }

  &_transparent {
    .editor-container.container-background {
      background: transparent;
    }
  }

  @media print {
    .editor-container {
      padding-bottom: 20px;
    }
    .editor-content {
      padding-bottom: 20px;
    }
  }

}

.editor-shell .editor-container.plain-text {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.editor-scroller {
  //border: 0;
  //resize: none;
  //cursor: text;
  //display: block;
  //position: relative;
  //outline: 0;
  ////overflow: hidden;
  ////resize: vertical;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  //resize: vertical;
}

.editor {
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
}

.typeahead-popover {
  margin-top: 20px;
}

.mentions-menu {
  width: max-content;
}

.auto-embed-menu {
  width: 150px;
}

.link-editor .button.active,
.toolbar .button.active {
  background-color: rgb(223, 232, 250);
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  //background-image: url(images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .font-size-wrapper,
.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }
  .font-size .dropdown-button-text {
    display: flex !important;
  }
  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

.editor-shell span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-shell .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-shell .editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-shell .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

//i.chevron-down {
//  background-color: transparent;
//  background-size: contain;
//  display: inline-block;
//  height: 8px;
//  width: 8px;
//  //background-image: url(images/icons/chevron-down.svg);
//}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1px;
  //background: var(--color-layout-container);
  background: transparent;
  padding: 4px;
  border-bottom: 1px solid var(--color-brdr-default);
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  vertical-align: middle;
  overflow: hidden;
  //min-height: 36px;

  &__active {
    color: var(--color-txt-active);
  }
}

//@keyframes appear {
//  0% {
//    transform: translateY(-100%);
//  }
//
//  100% {
//    transform: translateY(0);
//  }
//}
//
//.dynamicToolbar {
//  animation: appear 0.3s linear;
//}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  height: 18px;
  width: 18px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

.toolbar button.toolbar-item:disabled .icon,
.toolbar button.toolbar-item:disabled .text,
.toolbar button.toolbar-item:disabled i.format,
.toolbar button.toolbar-item:disabled .chevron-down {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: rgba(0, 0, 0, 0.04);
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .toolbar-item.font-family .text {
  display: block;
  max-width: 40px;
}

.toolbar .code-language {
  width: 150px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
  padding-right: 10px;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

.toolbar .divider {
  width: 1px;
  background-color: #eee;
  margin: 0 4px;
}

.variant__new-style.editor-shell_not-active {
  .text-editor-placeholder {
    top: 3px;
    left: -13px;
  }

  .draggable-block-menu {
    opacity: 0!important;
    pointer-events: none!important;
  }
}

.text-editor-placeholder {
  font-size: 15px;
  color: var(--color-inp-text-placeholder);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 8px;
  left: 28px;
  right: 28px;
  user-select: none;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
  transition: left .15s ease-in, top .15s ease-in;
}

// Меню @
.css-17f0j9f {
  border-radius: 6px !important;
  background-color: var(--color-layout-container) !important;
  box-shadow: var(--shadow-down-lg) !important;

  .css-1ad4gt8,
  .css-14hpmj5 {
    background-color: var(--color-layout-fill-tertiary) !important;
    border-radius: 4px;

    &:hover {
      .ant-typography {
        color: var(--color-primary-base) !important;
      }
    }
  }
}

.css-8x8b97 {
  background: var(--color-layout-background) !important;
}

span[data-lexical-decorator="true"],
[data-lexical-text="true"] {
  --editor-color-error: var(--color-txt-error);
  --editor-color-success: var(--color-txt-success);

  a:not(:hover) {
    color: var(--color-txt-active);
  }
}

//html[data-app-theme-id="1"] {
//  div[data-slate-editor="true"] {
//    --editor-color-error: var(--color-error-strong);
//    --editor-color-success: var(--color-success-strong);
//
//    a:not(:hover) {
//      color: var(--color-primary-weaker);
//    }
//
//    p span {
//      color: var(--color-text-base) !important;
//    }
//  }
//}

//slate-BalloonToolbar
.BalloonToolbar___StyledToolbarBase-sc-s0c2tw-0 {
  background: var(--color-layout-container) !important;
  //box-shadow: var(--shadow-down-sm) !important;
  border: 2px solid var(--color-layout-divider) !important;
  //border: 1px solid var(--color-text-weakest) !important;

  .slate-ToolbarButton:hover {
    svg[stroke]:not([stroke="none"]) {
      stroke: var(--color-primary-base) !important;
    }

    svg[fill]:not([fill="none"]) {
      fill: var(--color-primary-base) !important;
    }
  }
}

.table-dropdown {
  z-index: 1000;
  width: 220px;
  padding: 0 16px 0 0;
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: var(--color-layout-container);
}

.table-dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: var(--color-text-base);
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: var(--color-layout-container);
  border-radius: 8px;
  border: 0;
  width: 100%;
  //max-width: 250px;
  //min-width: 100px;
}

.table-dropdown .item.fontsize-item,
.table-dropdown .item.fontsize-item .text {
  min-width: unset;
}

.table-dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  //background-size: contain;
}

.table-dropdown .item:first-child {
  margin-top: 8px;
}

.table-dropdown .item:last-child {
  margin-bottom: 8px;
}

.table-dropdown .item:hover {
  background-color: var(--color-layout-divider);
}

.table-dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.table-dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.table-dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

.table-cell-action-button {
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  background: none;
  //color: #222;
  display: inline-block;
  cursor: pointer;
}

i.chevron-down {
  //background-color: transparent;
  //background-size: auto;
  color: var(--color-text-base);
  display: inline-block;
  height: 10px;
  width: 10px;
}


.editor-shell {
  &.variant__new-style {
    padding: 12px;
    border-color: var(--color-inp-border-default);
    border-radius: var(--border-radius-md);
    color: var(--color-txt-primary);

    .toolbar {
      //border: none;
      border-color: var(--color-inp-border-default);
      margin: -12px -12px 4px;
      padding: 12px 16px;
      border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
      background: var(--color-srf-primary);
      border-top: 1px solid var(--color-inp-border-default);
    }

    .ant-dropdown-trigger {
      display: flex;
    }

    .ContentEditable__root {
      font-size: 14px;
    }

  }

  &_not-active {
    &.variant__new-style {
      border-color: transparent!important;
      //padding: 0;
    }
    &.editor-shell_show-border {
      border: 1px solid var(--color-inp-border-default)!important;

      .ContentEditable__root {
        padding-left: 3px!important;
      }

      .text-editor-placeholder {
        top: 8px!important;
        left: 3px!important;
      }

    }
  }

  &.editor-shell_show-border {
    padding: 6px 12px;

    &.editor-shell_toolbar-view {
      padding-top: 0;
    }


    .text-editor-placeholder {
      top: 7px;
      left: 23px;
    }

    .toolbar {
      margin-top: 0;
    }
  }


}

.editor-content {
  position: relative;
}

.editor-title {
  padding: 0 24px!important;
  font-size: 36px;
  line-height: 1.3;
  font-weight: bold;

  &::placeholder {
    font-weight: normal;
  }

}

.editor-ai-container {
  transition: width .15s ease-in;
}
