.regulation-sidebar_visible {
  flex-shrink: 0;
  //width: 260px;
  //width: 100%;
  //max-width: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  //transition: width 0.3s;
  @media print {
    display: none;
  }
}

.regulation-sidebar_hidden {
  //width: 0;
  overflow: hidden;
  //transition: 0.3s;
}
