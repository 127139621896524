.driver-admin-page__wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
}

.driver-admin-page__info-cards__wrapper {
  display: grid;
  gap: var(--indent-md);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
