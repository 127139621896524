.plan-container {
  overflow: hidden;
  background: var(--color-bg-primary);
  padding-top: 12px;
}

.planning-page {
  .sidebar-visible {
    width: 300px;
  }
}

.visible {
  flex-shrink: 0;
  width: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.hide {
  width: 0;
  overflow: hidden;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
