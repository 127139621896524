.ui-progress {
  margin-inline-end: 0;
  margin-bottom: 0;
  font-size: 12px;

  .ant-progress-inner {
    background: transparent;
    height: 7px;
    border-radius: var(--border-radius-md);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--color-brdr-default);
      border-radius: var(--border-radius-md);
    }
  }

  .ant-progress-bg {
    height: 100%!important;
    border-radius: var(--border-radius-md);
  }

  &.ant-progress-show-info {
    .ant-progress-outer {
      margin-inline-end: calc(-2em - 16px);
      padding-inline-end: calc(2em + 16px);
    }
  }

  .ant-progress-text {
    margin-inline-start: 16px;
    color: var(--color-txt-secondary);
  }
}
