.breadcrumbs-new-link {
  white-space: nowrap;

  font-size: 14px!important;
  line-height: 1.4!important;
  color: var(--color-calendar-text-second)!important;
  transition: color .2s ease-in!important;
  text-decoration: none!important;
  //text-transform: capitalize !important;
  padding: 0!important;
  height: auto!important;

  &_last {
    pointer-events: none;
    color: var(--color-calendar-text-base)!important;
  }

  &:hover {
    background: none!important;
    color: var(--color-primary-base) !important;
  }

}

.breadcrumbs-new-container {
  ol {
    align-items: center;
    flex-wrap: nowrap;

    a {
      display: block;
    }
  }

  .ant-breadcrumb-overlay-link {
    .anticon {
      display: none;
      pointer-events: none;
    }
  }

  .ant-breadcrumb-separator {
    margin: 0 8px!important;
    font-size: 14px!important;
    line-height: 1.4!important;
    color: var(--color-calendar-text-second)!important;
  }

}

.overflow-text_30 {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 270px !important;
}


.breadcrumbs-new-menu {
  padding: 0 !important;

  .ant-dropdown-menu-item {
    padding: 8px 12px !important;

    font-size: 14px!important;
    line-height: 1.4!important;
    color: var(--color-calendar-text-base)!important;

    &:hover {
      background: none!important;
      color: var(--color-primary-base)!important;
    }

    .breadcrumbs-new-link {
      color: inherit!important;
    }

  }
}