.comment-card {
  width: 100%;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    //margin-top: 8px;
    width: 100%;
    padding-left: 48px;
    font-size: 14px;
    font-weight: 400;
    //color: var(--color-text-weak);
    //color: #5B6066;

    &_new-style {
      font-size: 12px;
      padding-top: 0;
      padding-bottom: 8px;
      margin-top: -5px;
      padding-left: 37px;
    }

    &_no-padding {
      padding-left: 0;
      margin-top: 0;


      .readonly-mode {
        margin-left: -12px;
      }
    }

  }

  &__attachments {
    margin-left: 5px;
    margin-top: 12px;
    padding-left: 44px;

    &_no-padding {
      padding-left: 0;
    }
  }


  &__reaction {
    display: flex;
  }
}

.comment-card-footer {
  display: flex;
  gap: 8px;
  margin-top: -13px;
  align-items: center;
  padding-left: 48px;

  &_no-padding {
    padding-left: 0;
  }

}

.comment-card-footer-action {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--color-calendar-text-second);
  width: 100%;

  .uiKit-button {
    color: var(--color-calendar-text-second);
    font-size: 12px;
    line-height: 1;
    padding: 0;
    height: auto;
  }

  &__edited {
    font-size: 12px;
    margin-left: auto;
  }

}

.comment-card-content-footer {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 12px;
}
