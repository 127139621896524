.scroll-trigger {
  .scroll-trigger__trigger {
    background: red;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12345;
    margin-top: -25px;
  }
}
