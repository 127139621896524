.employee-invite-dialog_button {
  svg {
    stroke: var(--color-gray-base);
  }

  &.__delete {
    svg {
      stroke: var(--color-error-base);
    }
  }
}
