.user-info__container {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:not(.__tooltip-hidden) {
    cursor: pointer;
  }

  .user-info__name {
    flex-grow: 1;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.2em;
    vertical-align: center;

    span {
      color: var(--color-calendar-text-second);
      font-size: 0.9em;
    }
    //margin: 0 0 0 8px;
  }
}

//.user-info {
//  &__container {
//    display: inline-flex;
//    align-items: center;
//    cursor: pointer;
//    overflow: hidden;
//  }
//
//  &__avatar {
//    &__smChar {
//      span{
//        font-size: 14px !important;
//      }
//    }
//  }
//
//  &__name {
//    flex-grow: 1;
//    font-size: 14px;
//    overflow: hidden;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    line-height: 1.5em;
//    margin: 0 0 0 8px;
//  }
//}
