.badge-being-deleted {
  padding: 8px !important;
  color: var(--color-text-weak) !important;
  border-radius: 5px !important;
  overflow: hidden;
  .user-info__container {
    flex-shrink: 0;
  }
  .badge-being-deleted__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-tag-close-icon {
    flex-shrink: 0;
    margin-left: 4px !important;
    svg {
      height: 12px;
      width: 12px;
      transition: all 0.1s ease-in-out;
      fill: var(--color-gray-strong);
    }
    &:hover {
      svg {
        fill: var(--color-primary-base);
      }
    }
  }
}
