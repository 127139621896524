.driver-statistic-card {
  .ant-card-body {
    width: 170px;
    user-select: none;
    overflow: hidden;
    padding: var(--indent-lg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark-middle);
  }

  &__digit {
    text-align: center;
    font-size: 52px;
    font-weight: bold;
    line-height: 1em;
    color: var(--color-primary-base);
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-dark-weak);
  }
}
