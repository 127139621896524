.question-wrap {
  display: grid;
  gap: 8px;
}

.question {
  &__label {
    font-size: 14px!important;
    line-height: 1.4!important;
    color: var(--color-calendar-text-base);
  }
}


.question-input-row {
  display: grid;
  grid-template-columns: 1fr 28px;
  gap: 5px;
  align-items: center;
}

.question-answer-delete-button {
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-text-second);
  transition: color .2s ease-in;

  &:hover {
    color: var(--color-primary-base);
  }

}