.sidebar-static-element {
  cursor: pointer;
  min-height: 40px;
  background: var(--color-srf-primary);
  border-radius: var(--border-radius-md);
  transition:  color 0.2s ease-in;

  &:hover {
    color: var(--color-txt-active);
  }
}
