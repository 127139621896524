.planing-sidebar-filter-content {
  .subheader-text {
    margin-bottom: 12px!important;
  }

  &__item {
    & + & {
      margin-top: 8px;
    }
  }
}