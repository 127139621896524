.issue-change-deadline-dialog {
  .ant-picker-cell-inner {
    min-width: 40px!important;
    height: 40px!important;
    line-height: 40px!important;
  }

  .ant-picker-panel {
    table {
      width: 100% !important;
    }
  }

  .ant-picker-dropdown {

    .ant-picker-date-panel {
      width: 350px!important;
    }

    .ant-picker-cell {
      padding: 0!important;
      &::before {
        height: 40px !important;
      }
    }
  }

  .ant-picker-time-panel-column {
    width: 100px!important;
  }

  .ant-picker-time-panel-cell-inner {
    width: 84px !important;
    text-align: center;
    padding: 0 4px!important;
  }

}

.issue-change-deadline-dialog-container {
  margin: 0 -24px;
}