.function-tree-item {
  display: flex;

  .ant-checkbox-wrapper {
    flex-grow: 1;

    >span:not(.ant-checkbox) {
      flex-grow: 1;
    }

  }

}


.function-tree-item-content {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  &__icon {
    font-size: 20px;
    color: var(--color-radio-border);
  }
}
