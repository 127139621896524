.uiKit-checkbox {
  color: var(--color-txt-primary)!important;
  line-height: 1.4!important;

  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  span.ant-checkbox + * {
    padding-inline-start: 12px!important;
  }

  &:hover {
    .ant-checkbox-inner {
      border-color: var(--color-ctr-inactive-hover)!important;
    }
  }

  .ant-checkbox-inner {
    border: 1px solid var(--color-ctr-inactive)!important;
    background: none!important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: var(--color-ctr-active)!important;

      &:after {
        border-color: var(--color-ctr-active);
        border-width: 1.7px;
        margin-top: -1px;
        width: 4.7142857142857135px;
        height: 10.142857142857142px;
      }

    }

    &:hover {
      .ant-checkbox-inner {
        &, &:after {
          border-color: var(--color-ctr-active-hover) !important;
        }
      }
    }
  }

}


