.uiKit-chip {
  color: var(--color-txt-alwayswhite);
  background: var(--color-icn-accent);
  height: 24px;
  min-width: 24px;
  padding: 0 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: var(--border-radius-lg);
  white-space: nowrap;

  span {
    line-height: 24px;
    vertical-align: middle;
  }

  &.__default {
    background: var(--color-srf-tertiary);
    color: var(--color-txt-primary);
  }

  &.__primary {
    background: var(--color-primary-weak);
    color: var(--color-txt-inverse);
  }

  &.__success {
    background: var(--color-success-weak);
    color: var(--color-txt-alwaysdark);
  }

  &.__error {
    background: var(--color-error-weak);
    //color: var(--color-text-base);
  }

  &.__warning {
    background: var(--color-status-declined);
    color: var(--color-txt-alwaysdark);
  }

  &.__info {
    background: var(--color-info-weak);
    //color: var(--color-text-base);
  }
}
