@import "./variables/variables_sass";

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -ms-user-drag: none;
  user-drag: none;
}

.no-interact {
  pointer-events: none;
}

.outside-progress-10s {
  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    opacity: 0.25;
    border: 3px solid var(--color-primary-base);
    border-radius: 50%;
  }

  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    border: 3px solid var(--color-primary-base);
    border-radius: 50%;
    animation: fill linear 10s;
  }
}

@keyframes fill {
  0% {
    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 0%, 50% 50%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  37.5% {
    clip-path: polygon(50% 0%, 50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%);
  }

  62.5% {
    clip-path: polygon(50% 0%, 50% 50%, 0% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%);
  }

  87.5% {
    clip-path: polygon(50% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }

  100% {
    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
}

.blink {
  animation: blinker 0.8s ease-in-out infinite;
}

.rotate {
  animation: rotator 1.6s linear infinite;
}

.blink-slower {
  animation: blinker 1.6s ease-in-out infinite;
}

@keyframes blinker {
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  0% {
    opacity: 1;
  }
}

@keyframes rotator {
  100% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(90deg);
  }

  0% {
    transform: rotate(0deg);
  }
}

.d-stack,
.d-stack-row,
.d-stack-column {
  display: flex;
}

.d-stack,
.d-stack-row {
  flex-direction: row;
}

.d-stack-column {
  flex-direction: column;
}

@for $i from 0 to 100 {
  // Stack row
  .d-stack,
  .d-stack-row {
    //display: grid !important;
    //grid-auto-flow: column;
    &.spacing-#{$i} {
      //gap: #{$i}px;
      > :not(:last-child) {
        margin-right: $i * $indent-sm !important;
      }

      &.stack-divided {
        > :not(:first-child) {
          position: relative;

          &:before {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            left: -1 * calc(($i * $indent-sm) / 2) !important;
            background: var(--color-layout-divider);
            width: 1px;
          }
        }
      }
    }
  }

  // Stack column
  .d-stack-column {
    //grid-auto-flow: row;
    &.spacing-#{$i} {
      //gap: #{$i}px;
      > :not(:last-child) {
        margin-bottom: $i * $indent-sm !important;
      }

      &.stack-divided {
        > :not(:first-child) {
          position: relative;

          &:before {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            //- 0.5
            top: -1 * calc(($i * $indent-sm) / 2) !important;
            background: var(--color-layout-divider);
            height: 1px;
          }
        }
      }
    }
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

@for $i from 0 to 64 {
  // Margin negative
  .ma-n#{$i} {
    margin: -1 * $i * $indent-sm !important;
  }

  .mx-n#{$i} {
    margin-left: -1 * $i * $indent-sm !important;
    margin-right: -1 * $i * $indent-sm !important;
  }

  .my-n#{$i} {
    margin-top: -1 * $i * $indent-sm !important;
    margin-bottom: -1 * $i * $indent-sm !important;
  }

  .mt-n#{$i} {
    margin-top: -1 * $i * $indent-sm !important;
  }

  .mr-n#{$i} {
    margin-right: -1 * $i * $indent-sm !important;
  }

  .mb-n#{$i} {
    margin-bottom: -1 * $i * $indent-sm !important;
  }

  .ml-n#{$i} {
    margin-left: -1 * $i * $indent-sm !important;
  }

  // Margin
  .ma-#{$i} {
    margin: $i * $indent-sm !important;
  }

  .mx-#{$i} {
    margin-left: $i * $indent-sm !important;
    margin-right: $i * $indent-sm !important;
  }

  .my-#{$i} {
    margin-top: $i * $indent-sm !important;
    margin-bottom: $i * $indent-sm !important;
  }

  .mt-#{$i} {
    margin-top: $i * $indent-sm !important;
  }

  .mr-#{$i} {
    margin-right: $i * $indent-sm !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * $indent-sm !important;
  }

  .ml-#{$i} {
    margin-left: $i * $indent-sm !important;
  }

  // Padding
  .pa-#{$i} {
    padding: $i * $indent-sm !important;
  }

  .px-#{$i} {
    padding-left: $i * $indent-sm !important;
    padding-right: $i * $indent-sm !important;
  }

  .py-#{$i} {
    padding-top: $i * $indent-sm !important;
    padding-bottom: $i * $indent-sm !important;
  }

  .pt-#{$i} {
    padding-top: $i * $indent-sm !important;
  }

  .pr-#{$i} {
    padding-right: $i * $indent-sm !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * $indent-sm !important;
  }

  .pl-#{$i} {
    padding-left: $i * $indent-sm !important;
  }
}

// BORDER RADIUS
.br-none {
  border-radius: 0;
}

.br-xs {
  border-radius: 2px;
}

.br-sm {
  border-radius: 5px;
}

.br-md {
  border-radius: 8px;
}

.br-lg {
  border-radius: 10px;
}

.br-xl {
  border-radius: 25px;
}

.br-circle {
  border-radius: 50%;
}

// DISPLAY
.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-auto {
  align-self: auto;
}

.align-self-stretch {
  align-self: stretch;
}

.align-content-start {
  align-content: start;
}

.align-content-end {
  align-content: end;
}

.align-content-center {
  align-content: center;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-space-evenly {
  align-content: space-evenly;
}

.align-content-stretch {
  align-content: stretch;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

// TODO: Check
@for $i from 0 to 360 {
  .rotated-#{$i} {
    transform: rotate(-$i * 1deg);
  }

  .rotated-n#{$i} {
    transform: rotate($i * 1deg);
  }
}

// SHADOWS

.shadow-none {
  box-shadow: none !important;
}
