.editable-typography {
  //max-width: 100% !important;
  &__icon {
    stroke: var(--color-primary-base);
  }
  &__input {
    min-width: 400px !important;
    display: inline-block;

    > div {
      textarea {
        font-weight: 500;
        &::placeholder {
          color: var(--color-gray-base);
        }
      }
    }
    &-faint {
      > div {
        textarea {
          color: var(--color-gray-base);
        }
      }
    }
    &-big {
      font-size: 26px !important;
    }
    &-small {
      font-size: 14px !important;
    }

    &:hover {
      > div {
        &::before {
          border-bottom: 2px solid var(--color-primary-base) !important;
        }

        &::after {
          border-bottom: 2px solid var(--color-primary-base) !important;
        }
      }
    }

    &_new-design {
      &.ant-input-lg {
        padding: 5px 15px;
      }
    }
  }

  &__title {
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    line-break: auto;
    hyphens: manual;
    //overflow: hidden;
    text-overflow: ellipsis;
    //min-width: 50px;
    &-error {
      border-bottom: 2px solid #e31818;
    }
    &-faint {
      color: var(--color-gray-base) !important;
    }
    &-placeholder {
      color: var(--color-gray-base) !important;
    }
    &-big {
      font-weight: 500;
      font-size: 26px !important;
    }
    &-small {
      font-size: 14px !important;
    }
  }
}
