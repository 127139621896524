.relation-button {
  border-color: #5672ff;
  color: #5672ff;
  text-transform: initial !important;

  &-active {
    color: #ffffff !important;
    background-color: #5672ff !important;
  }
}

.active-TagBtn {
  color: #ffffff !important;
  background-color: #5672ff !important;
}

.board-toggle_button_group {
  border: none !important;

  button {
    max-height: 30px !important;
    padding: 4px 16px;
    border-radius: 4px !important;
    color: var(--color-primary-base);
    border: none !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: none;
    &:not(:first-of-type) {
      margin-left: 8px !important;
    }
  }

  .Mui-selected {
    background-color: var(--color-primary-base) !important;
    color: #ffffff !important;
  }
}
