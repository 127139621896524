.google-auth-info {
  &__title {
    margin-bottom: 8px;
  }
}

.google-auth-info-switch {
  display: grid;
  grid-template-columns: 44px 1fr;
  gap: 16px;

  .d-stack.spacing-2.align-center {
    .ant-switch {
      margin-right: 0 !important;
    }
  }

  &__text {
    white-space: pre-line;
  }
}

.google-auth-info-disconnect-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  height: 56px;
  margin-bottom: 12px;
  padding: 8px 16px;
  border: 1px solid var(--color-disabled-select-border);
  border-radius: var(--border-radius-md);
  box-sizing: border-box;

  &_no-margin {
    margin-bottom: 0;
  }

  &__left {
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 16px;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__email {
    overflow: hidden;
    text-overflow: ellipsis;
  }

}