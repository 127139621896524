$card-segment-width: 200px;
$card-margin: 8px;

.orgchart-card-content {
  .ant-typography.ant-typography-secondary {
    color: var(--color-txt-semisecondary)
  }
}

.orgchart-card__wrapper {
  .orgchart-card {
    &:hover {
      .orgchart-card__actions__wrapper {
        opacity: 1;
      }
    }
  }

  .orgchart-card__actions__wrapper {
    opacity: 0;
    transition: all 0.2s;
  }

  .orgchart-card__inner {
    //width: max-content;
    min-width: $card-segment-width;
    //width: 100%;

    &.__sticky {
      position: sticky;
      left: 0;
    }

    &.__wide {
      width: $card-segment-width * 2;
    }

    .orgchart-card__menu {
      //
    }

    .orgchart-card__inner__users {
      //
    }

    .orgchart-card__inner__users__user {
      max-width: fit-content;

      .user-info__name {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .orgchart-orgchart-card__inner__users__executors {
      &.__bordered {
        border-left: 1px dashed var(--color-text-weakest);

        .__position_name {
          position: relative;

          &:before {
            content: "";
            z-index: 1;
            display: block;
            position: absolute;
            left: -8px;
            top: 50%;
            width: 6px;
            height: 6px;
            flex-shrink: 0;
            border-radius: 50%;
            transform: translateY(-50%);
            background: var(--color-text-weaker);
            //background: var(--color-dark-weak);
          }
        }
      }
    }
  }

  .orgchart-card__children__wrapper {
    //display: inline-flex;
  }
}

.orgchart-card {

  &__children__wrapper {
    position: relative;
  }

  &__line {
    position: absolute;
    top: -4px;
    left: -6px;
    background: var(--color-brdr-default);
    //background: red;
    width: 1px;
    height: calc(100% - 30px);

    &:before {
      content: '';
      position: absolute;
      pointer-events: none;
      background-color: var(--color-brdr-default);
      width: 4px;
      height: 1px;
      left: 1px;
      bottom: 0;
    }

  }
}

.orgchart-card-metric-wrap {
  padding-left: 8px;
}

.orgchart-card-metric {
  transition: opacity .2s ease-in;
  text-decoration: underline;


  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  &_show-more {
    margin-left: 0;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}
