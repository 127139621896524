.uiKit-iconButton {
  //border-radius: 50% !important;
  //display: flex !important;
  //align-items: center !important;
  //justify-content: center !important;
  //background: transparent !important;
  color: var(--color-icn-default);
  transition: color .2s ease-in;

  &_bg {
    background: var(--color-srf-primary);
  }

  &.ant-btn-link:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      color: var(--color-icn-accent);
    }
  }

  &:hover {
    color: var(--color-icn-accent);
  }

  &:not(.uiKit-iconButton_border) {
    border: none !important;
    //padding: 4px !important;
    //height: unset !important;
    box-shadow: none !important;

    &::after {
      display: none !important;
    }
  }
  svg {
    //opacity: 0.8;
    pointer-events: none;
    //transition: all 0.15s;
  }

}
