.center-page-content_layout {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  &_child {
    padding-right: 24px;
    padding-left: 24px;
    max-width: 1178px;
    width: 100%;
    &__horizontal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
