.orgchart-function-drawer__wrapper {
  .orgchart-function-drawer__tabs_content {
    .ant-tabs-content-holder, .ant-tabs-content, .ant-tabs-tabpane {
      height: 100%;
    }
  }

  .ant-tabs-tabpane {
    > .ant-spin-nested-loading {
      height: 100%;

      .ant-spin {
        max-height: unset;
      }

      .ant-spin-container {
        padding: 12px 24px;
        overflow: auto;
        height: 100%;
      }
    }
  }
}

//.orgchart-function-drawer_main {
//  //display: flex;
//  //flex-direction: column;
//  //max-width: 1110px;
//  //width: 100%;
//
//  .ant-drawer-content-wrapper {
//    .ant-drawer-body {
//      overflow: hidden;
//      display: flex;
//      flex-direction: column;
//
//      .ant-tabs {
//        flex-grow: 1;
//        overflow: hidden;
//        display: flex;
//        flex-direction: column;
//        .ant-tabs-nav {
//          margin-bottom: 12px !important;
//        }
//        .ant-tabs-content {
//          overflow: auto;
//        }
//        .ant-tabs-content-holder {
//          overflow: auto;
//        }
//      }
//    }
//  }
//
//  .orgchart-function-drawer_direction-select {
//    display: flex;
//  }
//
//  .orgchart-function-drawer_header {
//    flex-grow: 0;
//    width: 100%;
//
//    .__function-name {
//      font-size: 18px;
//      color: var(--color-dark-strong);
//      display: block;
//    }
//
//    .__function-vfp {
//      margin-top: 6px;
//      font-size: 15px;
//      opacity: 0.7;
//      color: var(--color-gray-base);
//      display: flex;
//      flex-direction: row;
//      align-items: baseline;
//
//      .__function-vfp-text {
//        margin-left: 12px;
//        color: var(--color-primary-base);
//      }
//    }
//  }
//
//  .orgchart-function-drawer_content {
//    // для чего эти стили?
//    //flex-grow: 1;
//    //overflow: auto;
//    //width: 100%;
//  }
//}
//
//.orgchart-function-drawer_tooltip {
//  max-width: unset !important;
//}
//
//.orgchart-function-tooltip_color-picker {
//  width: 100% !important;
//  flex-wrap: nowrap !important;
//}
//
//.orgchart-function__tabs {
//  .ant-tabs-nav::before {
//    display: none;
//  }
//}
