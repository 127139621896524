.calendar-planning-sidebar_visible {
  flex-shrink: 0;
  //width: 260px;
  //width: 100%;
  //max-width: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  //transition: width 0.3s;
}

.calendar-planning-sidebar_hidden {
  //width: 0;
  overflow: hidden;
  //transition: 0.3s;
}

.communication-header {
  .ant-btn-default {
    background: var(--color-srf-secondary);
    border-radius: var(--border-radius-xsm);
  }

}

.communication-header-row {
  display: flex;
  gap: 16px;
}

.communication-kanban {
  background: var(--color-bg-kanban);
}
