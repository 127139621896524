.global-search__popover {
  .ant-popover-inner {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid var(--color-layout-divider);
    clip-path: inset(0px -36px -36px -36px);
  }
}

.global-search__input {
  .ant-input-prefix {
    svg {
      transition: all 0.5s;
      opacity: 0.5;
    }
  }

  .ant-input {
    font-size: 14px;

    &::placeholder {
      color: var(--color-txt-secondary);
    }

  }

  &.__is-focused {
    input {
      color: var(--color-primary-base) !important;
    }
    .ant-input-prefix {
      svg {
        opacity: 1;
        stroke: var(--color-primary-base);
      }
    }
  }
}
