.uiKit-tooltip {
  //
  z-index: 2001;

  &_no-wrap {
    max-width: max-content;

    .ant-tooltip-inner {
      white-space: nowrap;

    }
  }
  &_break-spaces {
    white-space: break-spaces;
  }
}
