.education-breadcrumbs {
  display: flex;
  gap: 4px;

  &__item {
    padding: 6px 8px;
    font-size: 14px;
    color: var(--color-text-second);
    transition: color .2s ease-in;

    &_link {
      cursor: pointer;

      &:hover {
        color: var(--color-primary-base);
      }
    }

    &_active {
      pointer-events: none;
      color: var(--color-calendar-text-base);
    }
  }
}