.create-tests-form {
  display: grid;
  gap: 32px;
  margin-top: 16px;

  &__title {
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
    color: var(--color-calendar-text-base);
  }

  &__add-answer-btn {
    svg {
      stroke: var(--color-text-weak);
    }
    &:hover {
      svg {
        stroke: var(--color-primary-base);
      }
    }
    padding: 0 !important;
  }
}
