//.planning-page-admin-header {
//  &_tabs {
//    flex-grow: 0;
//    flex-shrink: 0;
//    overflow: hidden;
//    .ant-tabs-tab,
//    .ant-tabs-tab-btn {
//      padding: 0 !important;
//    }
//    a {
//      text-transform: uppercase;
//      font-weight: 500;
//      padding: 8px 12px !important;
//      display: block;
//    }
//    .ant-tabs-tab:not(.ant-tabs-tab-active) {
//      a {
//        color: var(--color-text-weaker) !important;
//      }
//      &:hover {
//        a {
//          color: var(--color-text-weak) !important;
//        }
//      }
//    }
//  }
//}

.planning-page-admin-header_tabs {
  //width: 100%;
  max-width: calc(100% - 276px);
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  //box-shadow: none!important;

  .ant-tabs-tab,
  .ant-tabs-tab-btn {
    padding: 0 !important;
    text-transform: none;
  }

  .ant-tabs-nav-list {
    gap: 4px!important;
  }

  .ant-tabs-tab {
    margin: 0!important;
  }

  .ant-card-body {
    padding: 0 12px!important;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;

    &:before {
      display: none;
    }
  }

  a {
    text-transform: none;
    font-weight: normal;
    padding: 16px 12px!important;
    display: block;
  }


  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    a {
      color: var(--color-primary-weaker-second) !important;
    }

    &:hover {
      a {
        color: var(--color-calendar-primary-base) !important;
      }
    }
  }

  .ant-tabs-tab-active {

    a {
      color: var(--color-calendar-primary-base) !important;
    }

  }

}