.filter-dropdown-content {
  width: 340px;
  max-width: 340px;

  &.d-stack-column.spacing-2 {
    .tags-block {
      margin-top: 12px;
      margin-bottom: 20px !important;
    }
  }

}