.answer-wrap {
  display: grid;
  gap: 12px;
}

.answer-list {
  display: grid;
  gap: 4px;
}

.answer-row {
  display: grid;
  grid-template-columns: 23px 1fr 28px;
  gap: 5px;
  align-items: flex-start;

  .ant-input {
    padding: 0!important;
  }

  .question-answer-checkbox {
    padding-top: 2px;
  }
}