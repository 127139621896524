.tab-filter-item {
  padding: 8px 12px;
  transition: color .2s ease-in-out, border-color .2s ease-in-out;
  border-bottom: 2px solid transparent;
  color: var(--color-calendar-text-base);
  cursor: pointer;
  font-size: 14px;

  &_active {
    cursor: default;
    color: var(--color-calendar-primary-base);
    border-bottom-color: var(--color-calendar-primary-base);
  }

  &:hover {
    color: var(--color-calendar-primary-base);
  }
}
