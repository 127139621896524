.planning-employee-card {
  padding: 16px;
  border-radius: var(--border-radius-xl);
  background: var(--color-srf-primary) !important;
  cursor: pointer;
  box-shadow: none!important;
  transition: box-shadow 0.2s ease-in!important;

  &:after {
    display: none;
  }

  &:hover {
    box-shadow: 0 8px 32px 0 var(--color-shadow-card)!important;
  }

  .user-card {
    cursor: pointer;
  }

  & > .ant-card-body {
    padding: 0;
  }

}


.planning-subordinates-card {
  &__container {
    gap: 25px;

    .ui-label {
      padding: 0 8px;
      width: 155px;
    }

  }

  &__subordinate {
  }

  &__summary {
  }

  &__tasks {
    font-size: 14px;
  }

  &__fact {
    font-size: 14px;
  }

  &__indicator {
    min-width: 200px;
    height: 40px;
    font-weight: 700;
    border-radius: var(--border-radius-sm);
  }


}
