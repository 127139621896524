.uiKit-textArea {

  &_no-border {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  //&.ant-input-borderless {
  //  &:hover,

  //  &:focus {
  //    background: var(--color-background-strong);
  //  }
  //}


  //&.__placeholder-trimmed {
  //  &:placeholder-shown {
  //    //text-overflow: ellipsis;
  //    height: 2em !important;
  //
  //    &.ant-input-sm {
  //      height: 1.6em !important;
  //    }
  //
  //    &.ant-input-lg {
  //      height: 2.56em !important;
  //    }
  //  }
  //
  //  &::placeholder {
  //    white-space: nowrap;
  //    overflow: hidden;
  //    text-overflow: ellipsis;
  //  }
  //}
}
