.list-staff {
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  //flex-grow: .39;
  width: 49%;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
