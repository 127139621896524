.metrics {
  display: grid;
  gap: var(--indent-lg);
  //grid-template-columns: repeat(3, minmax(0, 1fr));
  //grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));

  &_row {
    display: flex;
  }


  .ant-card {
    background: var(--color-srf-primary);
  }


}

.empty-metric {
  display: flex;

  > .ant-card {
    width: 100%;
    height: 100%;
  }
}
