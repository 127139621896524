.planning-collapse {
  .collapse-panel > .ant-collapse-header {
    font-weight: 700;
    font-size: 14px;
  }

  .collapse-panel > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .collapse-admin-text {
    font-weight: 700;
    color: var(--color-text-base);
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border: none !important;
  }

  .ant-table table {
    border-radius: 0 !important;
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
    border-top: none !important;
  }

  .planing-info-item {
    padding-right: 16px;
  }

  .planing-info-block {
    border-top: 1px solid var(--color-bg-primary)!important;
  }


  .ant-table {
    color: var(--color-txt-primary) !important;
  }

  .ant-table-tbody {
    background: var(--color-srf-primary);
  }

  .ant-table-cell {
    border: none!important;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-radius: 8px!important;
    }
  }

  .ant-collapse-header {
    background: var(--color-srf-primary);
    padding: 18px 12px!important;
    border-radius: 0!important;
  }

  .ant-table-thead {
    background: var(--color-srf-primary);

    th {
      color: var(--color-txt-secondary) !important;
      background: var(--color-srf-primary);
      font-size: 12px;
      line-height: 1.4;
      font-weight: 400;
      border-bottom: 1px solid var(--color-bg-primary)!important;
      padding-left: 12px!important;
      padding-right: 12px!important;

      &:before {
        display: none;
      }

    }

    .issue-info {
      color: var(--color-txt-active) !important;
    }
  }

  td:not(.issue-info) {
    padding-left: 8px!important;
    padding-right: 8px!important;
  }

  .ant-picker {
    height: 32px;
    border-color: transparent!important;
    transition: border-color .2s ease-in;
    border-radius: var(--border-radius-md);
    padding-left: 8px!important;
    padding-right: 8px!important;

    &:hover {
      border-color: var(--color-brdr-default)!important;
    }
  }

  .ant-picker-suffix {
    font-size: 16px;
  }

  input {
    font-size: 12px!important;
    line-height: 1!important;
    padding-top: 2px!important;
  }

  .issue-plan-fact {
    font-size: 12px;
  }

  .ant-table-row {
    & + .ant-table-row  {
      .ant-table-cell {
        border-top: 1px solid var(--color-bg-primary) !important;
      }
    }
  }

}

.planning-collapse-panel-wrap {
  border-radius: var(--border-radius-md);
  overflow: hidden;

  .planing-info-block {
    margin-top: -1px;
  }

}

.planning-collapse-panel {
  position: relative;
  z-index: 1;
  background: transparent!important;
  border: none;

  .collapse-panel, .ant-collapse-content {
    border: none!important;

  }

  .ant-collapse-content {
    background: var(--color-srf-primary);
    margin-top: 4px;
    margin-bottom: 1px;
  }

}

.issue-plan-icon-button {
  width: 66px;
  min-width: 66px;
  max-width: 66px;

  .ant-btn {
    font-size: 16px;
    color: var(--color-txt-secondary);
    transition: color .2s ease-in;
  }
}
