.info-icon {
  margin-top: 3px;

  g {
    path {
      &:first-child {
        display: none;
      }

      fill: var(--color-primary-base);
    }
  }

  path {
    fill: var(--color-primary-base);
  }
}

.header-text {
  color: var(--color-primary-base);
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.comment-text {
  white-space: pre-wrap;
  font-size: 13px;
  word-break: break-word;
  max-width: 90%;

  a {
    margin-right: 5px;
  }
}

.flex-container {
  display: flex;
  align-content: center;
  white-space: pre-wrap;

  p {
    word-break: break-all;
    white-space: pre-wrap;
    margin: 0;
  }
}

.card-content {
  //background-color: var(--color-layout-fill-base);
  //padding: 8px 8px 5px;
}

.issue-card-history {
  //
}
//  //box-sizing: border-box;
//  //border: 1px solid var(--color-background-stronger);
//  //box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.05);
//  //border-radius: var(--border-radius-md);
//  overflow: hidden;
//  //padding: 8px;
//
//  &__content {
//    &-body {
//      //background-color: var(--color-primary-weaker);
//      //padding: 5px;
//
//      &-success {
//        p {
//          color: var(--color-success-base) !important;
//        }
//
//        .action-icon {
//          fill: var(--color-success-base) !important;
//        }
//      }
//
//      &-error {
//        p {
//          color: var(--color-error-base) !important;
//        }
//
//        .action-icon {
//          fill: var(--color-error-base) !important;
//        }
//      }
//
//      &-text {
//        font-size: 14px;
//        width: 100%;
//        flex-wrap: wrap;
//
//        &__primary {
//          color: var(--color-primary-base);
//        }
//      }
//    }
//  }
//}

.issue-card-history__files-container {
  > div {
    > div {
      &:first-child {
        //padding-left: 48px;
      }
      &:last-child {
        //padding-right: 48px;
      }
    }
  }
}
