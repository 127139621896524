.orgchart_switcher-overlay {
  ul {
    overflow: auto !important;
    max-height: 50vh !important;
  }
}

.orgchart-switcher-content {
  .ant-popover-inner {
    padding: 4px!important;
  }
}

.orgchart-switcher-content-list {
  overflow: auto;
  max-height: 50vh;
}

.orgchart-switcher-item {
  display: flex;
  gap: 8px;
  padding: 5px 12px;
  transition: background-color .2s ease-in;
  user-select: none;
  color: var(--color-text-base);

  .ant-typography {
    color: inherit!important;
  }

  &:hover {
    background: var(--color-layout-fill-tertiary);
  }

  &_active {
    color: var(--color-text-weakest);
  }
}