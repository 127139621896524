.employee-card_roles-list {
  .__role-name {
    color: var(--color-txt-active);
    font-size: 12px;
    //opacity: 0.9;
    white-space: initial;
  }
  //p {
  //  color: var(--color-dark-weak);
  //  font-size: 12px;
  //  opacity: 0.9;
  //}
}

.__role-divider {
  font-weight: bold;
  color: var(--color-txt-active);
  font-size: 12px;
  opacity: 0.9;
}

.__role-more {
  color: var(--color-txt-active);
  font-size: 12px;
  opacity: 0.9;
}
