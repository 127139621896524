.orgchar-info {
  &__container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }

  &__avatar {
    &__smChar {
      span {
        font-size: 14px !important;
      }
    }
  }

  &__name {
    flex-grow: 1;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5em;
    margin: 0 0 0 8px;
  }
}
