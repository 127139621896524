.plan-history {
  &__list {
    margin-top: 16px;
    //max-height: 500px;
    overflow-y: auto;
  }
}

.plan-history-card {
  box-shadow: none!important;
  background: transparent;

  .toolbar,
  .editor-shell .editor-container.container-background,
  .ant-btn-default:not(:hover) {
    background: transparent;
  }
}