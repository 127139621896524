.tags-block {

}

.tags-block-header {
  display: flex;
  justify-content: space-between;

  &__left {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__right {
    display: flex;
    gap: 8px;
  }

  &__icon {
    display: flex;
    color: var(--color-text-second);
  }

  &__action {
    color: var(--color-text-second);
    transition: color .2s ease-in;

    &:hover {
      color: var(--color-primary-base);
    }
  }
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  max-height: 96px;
  overflow: hidden;
  transition: max-height .2s ease-in;

  &_open {
    max-height: 255px;
    overflow: auto;
  }

  .ant-tag {
    margin: 0!important;
    cursor: pointer;
  }

}

.tags-block-arrow {
  width: 24px!important;
  height: 24px;
}