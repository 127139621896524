.uiKit-collapse {
  &__arrow {
    transition: transform 0.2s ease-in, color 0.2s ease-in;
    color: var(--color-icn-default) !important;

    &_active {
      transform: rotate(-180deg);
    }

    &:hover {
      color: var(--color-icn-accent) !important;
    }
  }
}
