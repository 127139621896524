.file-viewer__dialog {
  .ant-modal-title {
    margin-right: 3em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-modal-content {
    //height: 95vh !important;
  }

  .file-viewer__menu {
    width: 20%;
    max-width: 250px;

    ul {
      height: 100%;
      overflow-y: auto;

      li {
        margin-left: 0 !important;
        &:first-child {
          margin-top: 0 !important;
        }
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .file-viewer__iframe {
    border-radius: 5px;
    border: 0;
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

//.file-viewer__backdrop {
//  background: rgba(0, 0, 0, 0.5);
//  position: fixed;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//  z-index: 54321;
//.file-viewer__container {
//  .file-viewer__header {
//    border-radius: 4px 4px 0 0;
//    background: var(--color-primary-base);
//    display: flex;
//    align-items: center;
//
//    p {
//      color: white;
//      font-size: 14px !important;
//      overflow: hidden;
//      text-overflow: ellipsis;
//      white-space: nowrap;
//    }
//  }
//
//  .file-viewer__iframe {
//    flex-grow: 1;
//    border-radius: 0 0 4px 4px;
//    overflow: hidden;
//    background: var(--color-white);
//  }
//}
//
//.file-viewer__list {
//  background: var(--color-white);
//  border-radius: 4px;
//  overflow: hidden;
//
//  .__caption {
//    overflow: hidden;
//    text-overflow: ellipsis;
//    white-space: nowrap;
//  }
//}
//}
