.PeriodsSelect-removeButton {
  background: var(--color-layout-fill-base) !important;
  border-radius: 4px !important;

  svg {
    stroke: var(--color-gray-weak);
  }

  &:hover {
    svg {
      stroke: var(--color-error-base);
    }
  }

  .MuiTouchRipple-root span {
    border-radius: 4px !important;
  }
}

.PeriodsSelect-subheader {
  font-size: 12px !important;
  color: var(--color-dark-weak);
}
