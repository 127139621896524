.indicator {
  &-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    border-radius: 4px;
    width: 25px;

    svg {
      width: 15px;
      height: 15px;
    }

    //&:not(.__stroke) {
    //  svg {
    //    fill: var(--color-layout-fill-base) !important;
    //  }
    //}
    //
    //&.__stroke {
    //  svg {
    //    stroke: var(--color-layout-fill-base) !important;
    //  }
    //}

    g {
      path {
        &:first-child {
          display: none;
        }

        //fill: var(--color-layout-fill-base) !important;
      }
    }

    path {
      //fill: var(--color-layout-fill-base) !important;
    }

    &__midddle {
      height: 32px;
      width: 32px;

      svg {
        width: 19px;
        height: 19px;
        //fill: var(--color-layout-fill-base) !important;
      }
    }
  }

  font-size: 12px;
  height: 25px;
  border-radius: var(--border-radius-sm) !important;

  &__midddle {
    height: 32px !important;
    max-height: 32px !important;
    font-size: 14px;
    border-radius: var(--border-radius-sm) !important;
  }
}

.simplified-indicator {
  span:not(.anticon):empty {
    display: none;
  }
}
