// COLORS (Common)
:root {
  --color-transparent: #00000000;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray-base: #b1b1b1;
  --color-gray-second: #747474;
  --color-gray-weak: #c4c4c4;
  --color-gray-weaker: #cfcfcf;


  --color-gray-strong: #928e8e;

  --color-gray-stronger: #808080;
}

// COLORS (Light)
:root {
  --color-shadow: 210, 210, 210;
  --color-shadow-second: rgba(0, 0, 0, 0.08);
  --color-shadow-card:  #5672FF0F;
  --color-primary: #5672ff;
  --color-primary-light: #ADC6FF;
  --color-primary-light2: #F0F5FF;
  --color-warning: #FAAD14;
  --color-warning-light: #FFE58F;
  --color-warning-light2: #FFFBE6;
  --color-error: #F5222D;
  --color-error-light: #FFA39E;
  --color-error-light2: #FFF1F0;
  --color-success: #52C41A;
  --color-success-second: #008067;
  --color-success-light: #B7EB8F;
  --color-success-light2: #F6FFED;
  --color-primary-base: #5672ff; // Primary
  --color-primary-weak: #788eff;
  --color-primary-weaker: #9aaaff;
  --color-primary-weaker-second: #9ba6c2;
  --color-primary-strong: #3c50b3;
  --color-primary-stronger: #2b3980;
  --color-info-base: #5672ff; // Info
  --color-info-weak: #788eff;
  --color-info-weaker: #9aaaff;
  --color-info-weaker-second: #7F85A1;
  --color-info-strong: #3c50b3;
  --color-info-stronger: #2b3980;
  --color-warning-base: #ffcc4d; // Warning
  --color-warning-weak: #ffd671;
  --color-warning-weaker: #ffe094;
  --color-warning-strong: #b38f36;
  --color-warning-stronger: #806627;
  --color-error-base: #fd5a44; // Error
  --color-error-base-opacity: #FD5A441A; // Error
  --color-error-second: #E31D02;
  --color-error-weak: #fd7b69;
  --color-error-weaker: #fe9c8f;
  --color-error-strong: #b13f30;
  --color-error-stronger: #7f2d22;
  --color-success-base: #00b894; // Success
  --color-success-weak: #33c6a9;
  --color-success-weaker: #66d4bf;
  --color-success-strong: #008168;
  --color-success-stronger: #005c4a;
  --color-text-base: #000000e0; // Text
  --color-text-black: #000000; // Text
  --color-text-second: #8E939D;
  --color-text-weak: #000000a6;
  --color-text-weaker: #00000073;
  --color-text-weakest: #00000040;
  --color-text-contrast: #ffffffd9;
  --color-layout-background: #f5f5f5; // Layout
  --color-layout-container: #ffffff;
  --color-layout-silver: #f2f2f2;
  --color-layout-light-gray: #FAFAFC;
  --color-layout-drawer: #ffffff;
  --color-layout-divider: #9ba6c233;
  --color-layout-divider-second: #D2D2D280;
  --color-layout-fill-base: #f5f5f5;
  --color-layout-fill-primary: #00000026;
  --color-layout-fill-secondary: #0000000f;
  --color-layout-fill-tertiary: #0000000a;
  --color-layout-fill-quaternary: #00000005;
  --color-disabled-select-border: #d9d9d9;
  --color-calendar-card-border: #D2D2D280;
  --color-border: #F0F0F0;
  --color-border-second: #8e939d33;
  --color-border-third: #E2E6EE;
  --color-tentative: #FFA755;
  --color-sidebar-item-border: #c9c9c933;
  --color-sidebar-item-icon: #c9c9c9;
  --color-function-card: #F7F8FA;
  --color-radio-border: #9ba6c2;
  --color-info-card: #F8FEEF;
  --color-avatar-group-bg: #FFD8BF;
  --color-avatar-group-text: #FA541C;


  //calendar
  //--color-calendar-primary-base: #5672FF;
  --color-calendar-default:  #EFF0F5;
  --color-calendar-default1: #EFF0F5;
  --color-calendar-faint-base: #E0E6FF;
  --color-calendar-primary-base:  #5672FF;
  --color-calendar-primary-weaker: #F0F3FF;
  --color-calendar-primary-weaker1: #F0F3FF;
  --color-calendar-success-base: #00b894;
  --color-calendar-green-base: #CFF3BE;
  --color-calendar-green-light: #e7f7e0;
  --color-calendar-warning-base: #FFCC4D;
  --color-calendar-warning-lighter: #FFFCE4;
  --color-calendar-warning-darker: #E5A400;
  --color-calendar-error-base: #FDF2F0;
  --color-calendar-error-darker: #EC5B56;
  --color-calendar-layout-divider: #D2D2D280;
  --color-calendar-layout-divider-light: #D2D2D266;
  --color-calendar-text-base: #171E40;
  --color-calendar-text-second: #8E939D;
  --color-calendar-text-third: #171E40;
  --color-calendar-sidebar: #EFF0F5;
  --snow-flakes-color: #E0E6FF;
  --snow-flakes-color-second: #F0F3FF;

  //Цвета добавленный при редизайне темной темы
  //background
  --color-bg-primary: #F9FAFB;
  --color-bg-secondary: #FFFFFF;
  --color-bg-kanban: #F2F4F7;

  //surface
  --color-srf-primary: #FFFFFF;
  --color-srf-primary-second: #FFFFFF;
  --color-srf-secondary: #F2F4F7;
  --color-srf-semisecondary: #F9FAFB;
  --color-srf-tertiary: #E4E7EC;
  --color-srf-success: #F6FEF9;
  --color-srf-warning: #FEFBE8;
  --color-srf-error: #FEF3F2;
  --color-srf-active: #F0F3FF;
  --color-srf-menu: #FFFFFF;
  --color-srf-menu-hover: #F2F4F7;
  --color-srf-tooltip: #243041;
  --color-srf-accent: #5672FF;
  --color-srf-sidebar: #F2F4F7;
  --color-srf-gradient: linear-gradient(180deg, #F3F6FF 0%, #FAFBFF 32.87%, #F5F5FF 70.3%, #EBEFFF 100%);



  //button
  --color-btn-primary-default: #5672FF;
  --color-btn-primary-hover: #788EFF;
  --color-btn-primary-fill-disabled: #F2F4F7;
  --color-btn-clear-hover: #F2F4F7;
  --color-btn-texticon-disabled: #98A2B3;
  --color-btn-destructive: #F04438;
  --color-btn-destructive-hover: #F97066;
  --color-btn-texticon-primary: #ffffff;

  //text
  --color-txt-primary: #222A38;
  --color-txt-inverse: #FFFFFF;
  --color-txt-semisecondary: #667085;
  --color-txt-secondary: #98A2B3;
  --color-txt-tertiary: #D0D5DD;
  --color-txt-link: #5672FF;
  --color-txt-link-hover: #788EFF;
  --color-txt-active: #5672FF;
  --color-txt-success: #12B76A;
  --color-txt-warning: #EAAA08;
  --color-txt-error: #F04438;
  --color-txt-alwaysdark: #222A38;
  --color-txt-alwayswhite: #fff;

  //border
  --color-brdr-light: #F2F4F7;
  --color-brdr-default: #E4E7EC;
  --color-brdr-dark: #98A2B3;
  --color-brdr-success: #6CE9A6;
  --color-brdr-warning: #FDE272;
  --color-brdr-error: #FDA29B;
  --color-brdr-active: #9AAAFF;
  --color-brdr-inverse: #FFFFFF;
  --color-brdr-accent: #5672FF;
  --color-brdr-secondary: #E4E7EC;

  //Controls
  --color-ctr-active: #788EFF;
  --color-ctr-active-hover: #5672FF;
  --color-ctr-active-disabled: #98A2B3;
  --color-ctr-fill-disabled: #F2F4F7;
  --color-ctr-inactive: #98A2B3;
  --color-ctr-inactive-hover: #667085;
  --color-toggle-inactive: #D0D5DD;
  --color-toggle-inactive-hover: #98A2B3;
  --color-toggle-circle: #FFFFFF;


  //Task status
  --color-status-incoming: #E4E7EC;
  --color-status-accepted: #DDE3FF;
  --color-status-inwork: #9AAAFF;
  --color-status-waiting-approval: #5672FF;
  --color-status-done: #12B76A;
  --color-status-declined: #FAC515;
  --color-status-recurring: #DDE3FF;

  //input
  --color-inp-border-default: #E4E7EC;
  --color-inp-border-hover: #9AAAFF;
  --color-inp-border-focus: #5672FF;
  --color-inp-label-default: #222A38;
  --color-inp-text-placeholder: #98A2B3;
  --color-inp-text-filled: #222A38;
  --color-inp-fill-disabled: #F2F4F7;
  --color-inp-label-disabled: #98A2B3;
  --color-inp-icon: #98A2B3;
  --color-inp-border-error: #F04438;

  //icon
  --color-icn-accent: #5672FF;
  --color-icn-default: #98A2B3;
  --color-icn-dark: #222A38;
  --color-icn-light: #D0D5DD;
  --color-icn-inverse: #FFFFFF;
  --color-icn-success: #12B76A;
  --color-icn-warning: #FAC515;
  --color-icn-violation: #F04438;
  --color-icn-orgstr: #BBC7FF;

  //tags
  --color-tag-txt-gray: #475467;
  --color-tag-bg-gray: #4754670D;
  --color-tag-brdr-gray: #4754674D;

  --color-tag-bg-pink: #DD25900D;
  --color-tag-txt-pink: #EE46BC;
  --color-tag-brdr-pink: #DD25904D;

  --color-tag-txt-green: #66C61C;
  --color-tag-bg-green: #4CA30D0D;
  --color-tag-brdr-green: #4CA30D4D;

  --color-tag-txt-red: #F04438;
  --color-tag-bg-red: #D92D200D;
  --color-tag-brdr-red: #D92D204D;

  --color-tag-txt-orange: #EAAA08;
  --color-tag-bg-orange: #EAAA080D;
  --color-tag-brdr-orange: #EAAA084D;

  --color-tag-txt-turquoise: #15B79E;
  --color-tag-bg-turquoise: #15B79E0D;
  --color-tag-brdr-turquoise: #15B79E4D;

  --color-tag-txt-blue: #0BA5EC;
  --color-tag-bg-blue: #0BA5EC0D;
  --color-tag-brdr-blue: #0BA5EC4D;

  --color-tag-txt-purple: #7A5AF8;
  --color-tag-bg-purple: #6938EF0D;
  --color-tag-brdr-purple: #6938EF4D;

  //Calendar card
  --color-cal-platformevent: #E4E7EC;
  --color-cal-event: #DDF8AB;
  --color-cal-task: #F2F4F7;
  --color-cal-reccurtask: #DDE3FF;
  --color-cal-declinedtask: #FAC515;
  --color-cal-approvtask: #5672FF;

  //Orgstructure
  --color-orgst-purple: #EBE9FE;
  --color-orgst-peach: #FFEAD5;
  --color-orgst-pink: #FCE7F6;
  --color-orgst-sky: #E0F2FE;
  --color-orgst-teal: #D5FBF3;
  --color-orgst-lightgreen: #E4FBCC;
  --color-orgst-blue: #DDE3FF;
  --color-orgst-yellow: #FEF7C3;
  --color-orgst-red: #FEE4E2;
  --color-orgst-gray: #EAECF5;
  --color-orgst-product: #E4E7EC;

  //Priority
  --color-priority1-bg: #79CFEA1A;
  --color-priority1-text: #36BFFA;
  --color-priority2-bg: #2EBDE533;
  --color-priority2-text: #0BA5EC;
  --color-priority3-bg: #0086C933;
  --color-priority3-text: #0086C9;
  --color-priority4-bg: #66C61C1A;
  --color-priority4-text: #66C61C;
  --color-priority5-bg: #4CA30D33;
  --color-priority5-text: #4CA30D;
  --color-priority6-bg: #FAC5151A;
  --color-priority6-text: #FAC515;
  --color-priority7-bg: #EAAA0826;
  --color-priority7-text: #EAAA08;
  --color-priority8-bg: #CA850433;
  --color-priority8-text: #CA8504;
  --color-priority9-bg: #F9706633;
  --color-priority9-text: #F97066;
  --color-priority10-bg: #D92D2033;
  --color-priority10-text: #D92D20;

  //Metrics
  --color-metric1: #222A38;
  --color-metric2: #FD853A;
  --color-metric-quota: #F0F0F066;
  --color-metric-gridlines: #E4E7EC;
  --color-metric-rise: #12B76A;
  --color-metric-fall: #F04438;
  --color-metric-risedouble: #5672FF;
  --color-metric3: #7A5AF8;
  --color-metric4: #0BA5EC;
  --color-metric5: #15B79E;
  --color-metric6: #EE46BC;
  --color-metric7: #912018;
  --color-metric8: #2F46BB;
  --color-metric9: #4CA30D;
  --color-metric10: #717BBC;

  //Schedule
  --color-workday-bg-default: #E4FBCC;
  --color-workday-bg-hover: #DDF8AB;
  --color-workday-txt: #326212;
  --color-weekend-bg-default: #FEF7C3;
  --color-weekend-bg-hover: #FEEE95;
  --color-weekend-txt: #854A0E;
  --color-vacation-bg-default: #FFEAD5;
  --color-vacation-bg-hover: #FDDCAB;
  --color-vacation-txt: #9C2A10;
  --color-sickday-bg-default: #FEE4E2;
  --color-sickday-bg-hover: #FECDCA;
  --color-sickday-txt: #D92D20;

}

// COLORS (Dark)
@media screen {
:root[data-app-theme-id="1"] {
  --color-shadow: 93, 98, 121;
  --color-shadow-second: rgba(255, 255, 255, 0.08);
  --color-shadow-card:  #2F54EB0F;
  --color-primary: #2F54EB;
  --color-primary-light: #ADC6FF;
  --color-primary-base: #4c64dc; // Primary
  --color-primary-weak: #7083e3;
  --color-primary-weaker: #94a2ea;
  --color-primary-strong: #35469a;
  --color-primary-stronger: #26326e;
  --color-info-base: #4c64dc; // Info
  --color-info-weak: #7083e3;
  --color-info-weaker: #94a2ea;
  --color-info-strong: #35469a;
  --color-info-stronger: #26326e;
  --color-warning-base: #dcb044; // Warning
  --color-warning-weak: #e3c069;
  --color-warning-weaker: #ead08f;
  --color-warning-strong: #9a7b30;
  --color-warning-stronger: #6e5822;
  --color-error-base: #da503d; // Error
  --color-error-second: #E31D02;
  --color-error-weak: #e17364;
  --color-error-weaker: #e9968b;
  --color-error-strong: #99382b;
  --color-error-stronger: #6d281f;
  --color-success-base: #039f81; // Success
  --color-success-weak: #35b29a;
  --color-success-weaker: #68c5b3;
  --color-success-strong: #026f5a;
  --color-success-stronger: #025041;
  --color-text-base: #ffffffd9; // Text
  --color-text-black: #fff;
  --color-text-second: #C9CCD4;
  --color-text-weak: #ffffffa6;
  --color-text-weaker: #ffffff73;
  --color-text-weakest: #ffffff40;
  --color-text-contrast: #000000e0;
  --color-layout-background: #090909; // Layout
  //--color-layout-background: #000000; // Layout
  --color-layout-container: #141414;
  --color-layout-silver: rgba(255, 255, 255, 0.04);
  --color-layout-light-gray: #3c3f41;
  --color-layout-drawer: #1f1f1f;
  --color-layout-divider: #9ba6c226;
  --color-layout-divider-second: #D2D2D280;
  --color-radio-border: #9ba6c2;
  --color-info-card: #444;
  --color-layout-fill-base: #141414;
  --color-layout-fill-primary: #ffffff2e;
  --color-layout-fill-secondary: #ffffff1f;
  --color-layout-fill-tertiary: #ffffff14;
  --color-layout-fill-quaternary: #ffffff0a;
  --color-disabled-select-border: #424242;
  --color-border: #1A1A1A;
  --color-border-third: #58585E;

    //calendar
    //--color-calendar-primary-base: #5672FF;
    --color-calendar-default: #1b1b2c;
    --color-calendar-default1: #58585E;
    --color-calendar-faint-base: #6066A6;
    --color-calendar-primary-base: #5672FF;
    --color-calendar-primary-weaker: #0e0e16;
    --color-calendar-primary-weaker1: #6066A6;
    --color-calendar-success-base: #00b894;
    --color-calendar-green-base: #CFF3BE;
    --color-calendar-green-light: #e7f7e0;
    --color-calendar-warning-base: #FFCC4D;
    --color-calendar-error-base: #7D655D;
    --color-calendar-layout-divider: #D2D2D280;
    --color-calendar-layout-divider-light: #d2d2d266;
    --color-calendar-text-base: #ffffff;
    --color-calendar-text-second: #C9CCD4;
    --color-calendar-sidebar: #9ba6c226;
    --color-sidebar-item-border: #9ba6c226;
    --color-sidebar-item-icon: #9ba6c226;
    --color-avatar-group-bg: #FFB48C;
    --color-avatar-group-text: #FA541C;

    --snow-flakes-color: #E0E6FF80;
    --snow-flakes-color-second: #F0F3FF70;


    //Цвета добавленный при редизайне темной темы
    //background
    --color-bg-primary: #161921;
    --color-bg-secondary: #222A38;
    --color-bg-kanban: #161921;

    //surface
    --color-srf-primary: #222A38;
    --color-srf-primary-second: #161921;
    --color-srf-secondary: #243041;
    --color-srf-semisecondary: #243041;
    --color-srf-tertiary: #344054;
    --color-srf-success: #054F31;
    --color-srf-warning: #542C0D;
    --color-srf-error: #55160C;
    --color-srf-active: #344054;
    --color-srf-menu: #243041;
    --color-srf-menu-hover: #475467;
    --color-srf-tooltip: #344054;
    --color-srf-accent: #788EFF;
    --color-srf-sidebar: #344054;
    --color-srf-gradient: #344054;


  //button
    --color-btn-primary-default: #5672FF;
    --color-btn-primary-hover: #788EFF;
    --color-btn-primary-fill-disabled: #475467;
    --color-btn-clear-hover: #475467;
    --color-btn-texticon-disabled: #667085;
    --color-btn-destructive: #F97066;
    --color-btn-destructive-hover: #F04438;
    --color-btn-texticon-primary: #ffffff;

    //text
    --color-txt-primary: #FFFFFF;
    --color-txt-inverse: #161921;
    --color-txt-semisecondary: #D0D5DD;
    --color-txt-secondary: #98A2B3;
    --color-txt-tertiary: #475467;
    --color-txt-link: #788EFF;
    --color-txt-link-hover: #5672FF;
    --color-txt-active: #788EFF;
    --color-txt-success: #12B76A;
    --color-txt-warning: #EAAA08;
    --color-txt-error: #F04438;
    --color-txt-alwaysdark: #222A38;
    --color-txt-alwayswhite: #fff;

    //border
    --color-brdr-light: #344054;
    --color-brdr-default: #475467;
    --color-brdr-dark: #667085;
    --color-brdr-success: #039855;
    --color-brdr-warning: #CA8504;
    --color-brdr-error: #D92D20;
    --color-brdr-active: #2F46BB;
    --color-brdr-inverse: #222A38;
    --color-brdr-accent: #788EFF;
    --color-brdr-secondary: #475467;

    //Controls
    --color-ctr-active: #788EFF;
    --color-ctr-active-hover: #9AAAFF;
    --color-ctr-active-disabled: #98A2B3;
    --color-ctr-fill-disabled: #667085;
    --color-ctr-inactive: #667085;
    --color-ctr-inactive-hover: #98A2B3;
    --color-toggle-inactive: #667085;
    --color-toggle-inactive-hover: #98A2B3;
    --color-toggle-circle: #FFFFFF;


    //Task status
    --color-status-incoming: #475467;
    --color-status-accepted: #788EFF;
    --color-status-inwork: #9AAAFF;
    --color-status-waiting-approval: #5672FF;
    --color-status-done: #039855;
    --color-status-declined: #FAC515;
    --color-status-recurring: #717BBC;

    //input
    --color-inp-border-default: #475467;
    --color-inp-border-hover: #2F46BB;
    --color-inp-border-focus: #788EFF;
    --color-inp-label-default: #FFFFFF;
    --color-inp-text-placeholder: #98A2B3;
    --color-inp-text-filled: #FFFFFF;
    --color-inp-fill-disabled: #344054;
    --color-inp-label-disabled: #98A2B3;
    --color-inp-icon: #98A2B3;
    --color-inp-border-error: #F97066;

    //icon
    --color-icn-accent: #788EFF;
    --color-icn-default: #98A2B3;
    --color-icn-dark: #F2F4F7;
    --color-icn-light: #667085;
    --color-icn-inverse: #161921;
    --color-icn-success: #32D583;
    --color-icn-warning: #FAC515;
    --color-icn-violation: #F97066;
    --color-icn-orgstr: #9AAAFF;

    //tags
    --color-tag-txt-gray: #D0D5DD;
    --color-tag-bg-gray: #E4E7EC0D;
    --color-tag-brdr-gray: #E4E7EC4D;

    --color-tag-txt-pink: #F670C7;
    --color-tag-bg-pink: #F670C70D;
    --color-tag-brdr-pink: #F670C74D;

    --color-tag-txt-green: #A6EF67;
    --color-tag-bg-green: #A6EF670D;
    --color-tag-brdr-green: #A6EF674D;

    --color-tag-txt-red: #F97066;
    --color-tag-bg-red: #F970660D;
    --color-tag-brdr-red: #F970664D;

    --color-tag-txt-orange: #FAC515;
    --color-tag-bg-orange: #FAC5150D;
    --color-tag-brdr-orange: #FAC5154D;

    --color-tag-txt-turquoise: #2ED3B7;
    --color-tag-bg-turquoise: #2ED3B70D;
    --color-tag-brdr-turquoise: #2ED3B74D;

    --color-tag-txt-blue: #36BFFA;
    --color-tag-bg-blue: #36BFFA0D;
    --color-tag-brdr-blue: #36BFFA4D;

    --color-tag-txt-purple: #9B8AFB;
    --color-tag-bg-purple: #9B8AFB0D;
    --color-tag-brdr-purple: #9B8AFB4D;

    //Calendar card
    --color-cal-platformevent: #475467;
    --color-cal-event: #DDF8AB;
    --color-cal-task: #667085;
    --color-cal-reccurtask: #717BBC;
    --color-cal-declinedtask: #FAC515;
    --color-cal-approvtask: #5672FF;

    //Orgstructure
    --color-orgst-purple: #BDB4FE;
    --color-orgst-peach: #FEB273;
    --color-orgst-pink: #FAA7E0;
    --color-orgst-sky: #7CD4FD;
    --color-orgst-teal: #5FE9D0;
    --color-orgst-lightgreen: #A6EF67;
    --color-orgst-blue: #9AAAFF;
    --color-orgst-yellow: #FDE272;
    --color-orgst-red: #FDA29B;
    --color-orgst-gray: #C8CCE5;
    --color-orgst-product: #243041;

    //Priority
    --color-priority1-bg: #7CD4FD1A;
    --color-priority1-text: #7CD4FD;
    --color-priority2-bg: #36BFFA33;
    --color-priority2-text: #36BFFA;
    --color-priority3-bg: #0BA5EC33;
    --color-priority3-text: #0BA5EC;
    --color-priority4-bg: #85E13A1A;
    --color-priority4-text: #85E13A;
    --color-priority5-bg: #66C61C33;
    --color-priority5-text: #66C61C;
    --color-priority6-bg: #FDE2721A;
    --color-priority6-text: #FDE272;
    --color-priority7-bg: #FAC51526;
    --color-priority7-text: #FAC515;
    --color-priority8-bg: #EAAA0833;
    --color-priority8-text: #EAAA08;
    --color-priority9-bg: #F9706633;
    --color-priority9-text: #F97066;
    --color-priority10-bg: #F0443833;
    --color-priority10-text: #F04438;

    //Metrics
    --color-metric1: #E4E7EC;
    --color-metric2: #FD853A;
    --color-metric-quota: #F0F0F01A;
    --color-metric-gridlines: #475467;
    --color-metric-rise: #32D583;
    --color-metric-fall: #F97066;
    --color-metric-risedouble: #788EFF;
    --color-metric3: #9B8AFB;
    --color-metric4: #36BFFA;
    --color-metric5: #2ED3B7;
    --color-metric6: #F670C7;
    --color-metric7: #B42318;
    --color-metric8: #5672FF;
    --color-metric9: #66C61C;
    --color-metric10: #9EA5D1;

    //Schedule
    --color-workday-bg-default: #A6EF6733;
    --color-workday-bg-hover: #A6EF674D;
    --color-workday-txt: #F3FEE7;
    --color-weekend-bg-default: #FAC51533;
    --color-weekend-bg-hover: #FAC5154D;
    --color-weekend-txt: #FEFBE8;
    --color-vacation-bg-default: #FEB27333;
    --color-vacation-bg-hover: #FEB2734D;
    --color-vacation-txt: #FFF6ED;
    --color-sickday-bg-default: #FDA29B33;
    --color-sickday-bg-hover: #FDA29B4D;
    --color-sickday-txt: #FEF3F2;
  }
}

:root {
  // SHADOWS
  --shadow-none: none;
  // Bottom
  --shadow-down-sm: 0px 1px 1px rgba(var(--color-shadow), 0.2), 0px 0px 1px rgba(var(--color-shadow), 0.2);
  --shadow-down-md: 0px 4px 4px rgba(var(--color-shadow), 0.2);
  --shadow-down-lg: 0px 4px 40px rgba(var(--color-shadow), 0.15);
  // Right
  --shadow-right-sm: 1px 0px 1px rgba(var(--color-shadow), 0.2), 0px 0px 1px rgba(var(--color-shadow), 0.2);
  --shadow-right-md: 4px 0px 4px rgba(var(--color-shadow), 0.2);
  --shadow-right-lg: 4px 0px 40px rgba(var(--color-shadow), 0.15);

  // INDENTS
  --indent-xs: 2px;
  --indent-sm: 4px;
  --indent-md: 8px;
  --indent-lg: 16px;
  --indent-xl: 32px;

  // BORDER-RADII
  --border-radius-none: 0;
  --border-radius-xs: 2px;
  --border-radius-xsm: 4px;
  --border-radius-sm: 6px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
}


