.metric-data-dialog {
  max-width: 938px;
  //max-height: 740px;

  .ant-table-thead {
    .ant-table-cell {
      padding-left: 4px!important;
      padding-right: 4px!important;
      font-size: 14px;
      text-align: center;
    }
  }
}

.metric-data-dialog-table {
  max-height: 520px;
}


.metrics-table-delete-button {
  color: var(--color-text-second);
}
