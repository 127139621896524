.uiKit-empty {
  .ant-empty-description {
    //opacity: 0.6;
  }

  &.__is-vertically-centered {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.__custom-image {
    //
  }

  &:not(.__custom-description) {
    .ant-empty-description {
      color: var(--color-text-weaker);
    }
  }
}

@media screen {
  html[data-app-theme-id="1"] {
    .uiKit-empty {
      .ant-empty-image {
        filter: invert(85%) saturate(20%) brightness(300%) contrast(150%);
      }
    }

    .uiKit-empty.__custom-image {
      .ant-empty-image {
        filter: brightness(60%) contrast(200%);
      }
    }
  }
}
