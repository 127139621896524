.function-card-item {
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 12px;
  border-radius: var(--border-radius-xsm);
  padding: 8px 16px;
  background: var(--color-function-card);
  //height: 40px;
  color: var(--color-calendar-text-base);

  &__icon {
    padding: 0!important;
    width: auto!important;
    height: auto!important;
    color: var(--color-text-second);
    background: none!important;
    transition: color .2s ease-in;

    &:hover {
      color: var(--color-primary-base);
    }

  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}