.orgchart-template-info {
  display: flex;
  gap: 4px;
  align-items: center;

  &__icon {
    display: flex;
    cursor: pointer;
    transition: color .2s ease-in;

    &:hover {
      color: var(--color-primary-base);
    }
  }
}