.orgchart-type-select-form {
  overflow: auto;

  .__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .__title {
      font-size: 24px;
      line-height: 1em;
      font-weight: 600;
    }

    .__subtitle {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 600;
      opacity: 0.8;
      white-space: pre;
      line-height: 1.2em;
    }
  }

  .__subtype_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .__subtype_title {
      font-size: 24px;
      line-height: 1em;
      font-weight: 700;
    }

    .__subtype_subtitle {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 600;
      opacity: 0.8;
      white-space: pre;
      line-height: 1.2em;
    }
  }

  .__content {
    .__subtype_popover {
      min-height: 130px;
      min-width: 236px;
    }
  }

  .__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-popover-title {
  color: var(--color-text-base) !important;
  font-weight: 400 !important;
}

.orgchart-type-card {
  width: 240px;
  height: 240px;
  transition: border-color 0.15s ease-in!important;
  border: 1px solid transparent;

  .ant-card-body {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orgchart-type-card__inner {
    display: grid;
    grid-template-rows: 164px auto;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  &:hover {
    border-color: var(--color-primary-base);
  }

  &.__selected {
    border-color: var(--color-primary-base);

    span {
      //color: var(--color-primary-base);
    }
  }

  img {
    max-height: 150px;
    max-width: 350px;
  }

  span {
    transition: 0.2s;
  }
}

.orgchart-type-card-wrap {
  display: grid;
  grid-template-rows: 100px auto;
  gap: 40px;
  width: 100%;
  height: 100%;
  padding: 39px 15px;
  justify-content: center;
  text-align: center;

  img {
    margin: 0 auto;
  }

}

.orgchart-grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 242px);
  gap: 32px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1064px;

  .ant-card-body {
    padding: 0;
  }

}
