.uiKit-input {
  &.ant-input-borderless {
    &:hover,
    &:focus {
      background: var(--color-background-strong);
    }
  }
  &.ant-tooltip-open {
    &.ant-input-lg {
      font-size: 16px !important;
    }
  }

  &_new-design {
    border: 1px solid var(--color-brdr-default);
    border-radius: var(--border-radius-md);
    //border: 1px solid #D2D2D280


    &.ant-input-affix-wrapper-lg, &.ant-input-lg {
      font-size: 14px;
      line-height: 1.4;
      padding: 7px 15px;
    }

    .ant-input-lg {
      line-height: 1.4;
    }
  }

  &_big {
    height: 40px;
  }
}
