.uiKit-table {
  .ant-table {
    color: var(--color-txt-primary);
  }

  .ant-table-cell {
    color: var(--color-txt-primary);
  }

  &_new-style {

    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >thead >tr>th::before,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >thead >tr>th::before,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >thead >tr>th::before,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >thead >tr>th::before  {
      background-color: #0000000F!important;
    }

    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >thead>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >thead>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >thead>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >thead>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >thead>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >thead>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >thead>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >thead>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >tbody>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >tbody>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >tbody>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >tbody>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >tbody>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >tbody>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >tbody>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >tbody>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >tfoot>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >tfoot>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >tfoot>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >tfoot>tr>th,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-content >table >tfoot>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-header >table >tfoot>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-body >table >tfoot>tr>td,
    &:where(.css-dev-only-do-not-override-1liqky).ant-table-wrapper .ant-table.ant-table-bordered
      >.ant-table-container >.ant-table-summary >table >tfoot>tr>td {
      border-inline-end: none!important;
    }
  }
}
