.forgot_password_link {
  text-decoration: none;
  margin-top: 8px;
  color: var(--color-txt-link);
  display: block;

  &:hover {
    color: var(--color-txt-link-hover);
  }
}

.overflow-container {
  //overflow-y: auto;
  //width: 100%;
}

.column-container {
  display: flex;
  flex-direction: column;
}

.popover-send-issueHistory {
  width: 180px;
  padding-top: 10px;
  padding-bottom: 10px;

  button {
    font-weight: 400 !important;
    font-size: 13.5px !important;
  }
}

.issue-options-options {
  position: absolute;
  right: 0;
}

.date-created {
  //margin-top: 6px !important;
  font-size: 13px !important;
  font-weight: 400;
  color: #b1b1b1 !important;
}

.faint-text {
  //margin-bottom: 8px;
  font-size: 14px !important;
  font-weight: 400;
  color: #b1b1b1 !important;
}

.action-button {
  color: #fff !important;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  border-radius: 5px !important;
  width: fit-content;

  &:hover {
    filter: brightness(90%);
  }

  &:active {
    filter: brightness(80%);
  }

  span {
    margin: 0 auto;
  }
}

.report-driver-link {
  cursor: pointer;
  text-align: start;
  font-size: 14px !important;
  margin-top: 16px !important;
  font-weight: 400 !important;

  &:hover {
    color: var(--color-primary-base);
  }
}

.custom-select {
  > div {
    font-size: 14px !important;
  }
}
