.uiKit-card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: var(--color-txt-primary);

  @media print {
    background: #fff;
  }

  .ant-card-body:before,
  .ant-card-body:after {
    //display: none !important;
  }

  .ant-card-head {
    margin-bottom: 1px;
  }

  .ant-card-extra {
    margin-left: var(--indent-md);
  }

  &.__clickable:hover:after,
  &.__clickable:active:after {
    z-index: 0;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &.__clickable {
    > * {
      z-index: 1;
    }
  }

  //&.__clickable:hover:after {
  //  background: rgba(0, 0, 0, 0.015);
  //}
  //
  //&.__clickable:active:after {
  //  background: rgba(0, 0, 0, 0.035);
  //}
  //
  //&.__clickable.__secondary:hover:after {
  //  background: rgba(0, 0, 0, 0.035);
  //}
  //
  //&.__clickable.__secondary:active:after {
  //  background: rgba(0, 0, 0, 0.05);
  //}

  &.__clickable {
    overflow: hidden;
    transition: background 500ms;
    cursor: pointer;
  }

  &.__transparent {
    border-radius: 0;

    > .ant-card-body {
      border-radius: 0;
    }
  }

  &.__secondary {
    //
  }

  &.__no-shadow:not(.ant-card-hoverable:hover) {
    box-shadow: none !important;
    //&.ant-card-hoverable:hover {
    //}
  }

  &.ant-card-hoverable:hover {
    //box-shadow: none !important;
    //border-left: 4px solid var(--color-primary-base);
    //box-shadow: var(--shadow-down-md) !important;
  }

  &.__ss-card-body {
    overflow: hidden;
    max-height: 100%;

    .ant-card-body {
      flex-grow: 1;
      overflow: auto;
    }
  }


  &.__no-padding {
    .ant-card-body {
      padding: 0;
    }

  }

}
//
//html[data-app-theme-id="1"] {
//  .uiKit-card {
//    &.__clickable:hover:after {
//      background: rgba(0, 0, 0, 0.1);
//    }
//
//    &.__clickable:active:after {
//      background: rgba(0, 0, 0, 0.2);
//    }
//
//    &.__clickable.__secondary:hover:after {
//      background: rgba(0, 0, 0, 0.1);
//    }
//
//    &.__clickable.__secondary:active:after {
//      background: rgba(0, 0, 0, 0.2);
//    }
//  }
//}
