$grid-el-height: 70px;
$grid-el-width: 84px;
$grid-el-offset: 4px;

.schedule-cell__wrapper {
  border-radius: 4px;
  cursor: pointer;
  width: $grid-el-width;
  height: $grid-el-height;
  //border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //border: 1px solid transparent;
  transition: background-color .2s ease-in, border-color .2s ease-in;

  &.__disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  span {
    z-index: 1;
    white-space: pre;
    text-align: center;
    line-height: 1.2em;
  }


  &.__active {
    outline: 1px solid var(--color-brdr-accent);
  }

  &.__selected {
    outline: 1px solid var(--color-brdr-accent);
  }


  &_default {
    background: var(--color-srf-tertiary);
    color: transparent;
  }

  &_workday {
    background: var(--color-workday-bg-default);
    color: var(--color-workday-txt);

    &:hover {
      background: var(--color-workday-bg-hover);
    }
  }

  &_weekend {
    background: var(--color-weekend-bg-default);
    color: var(--color-weekend-txt);

    &:hover {
      background: var(--color-weekend-bg-hover);
    }
  }

  &_vacation {
    background: var(--color-vacation-bg-default);
    color: var(--color-vacation-txt);

    &:hover {
      background: var(--color-vacation-bg-hover);
    }
  }

  &_sickday {
    background: var(--color-sickday-bg-default);
    color: var(--color-sickday-txt);

    &:hover {
      background: var(--color-sickday-bg-hover);
    }
  }
}
