.planning-work-plans {
  height: 100%;
  overflow: hidden;
  background: var(--color-bg-primary);
  padding: 16px;
  margin-top: -8px;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    gap: 32px;
    max-width: 1192px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.planning-work-plans-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  height: 100%;
  //gap: 32px;
  overflow: hidden;
}

.planning-work-plans-header {
  width: 100%;
  max-width: 1290px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 1192px;
  }

  @media (min-width: 1921px) {
    max-width: 87%;
  }

}

.planning-work-plans-content {
  overflow: auto;
  flex-grow: 1;

  &_empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .page-content__wrapper {
    max-width: 1354px;
    margin: 0 auto;
    height: auto;
    min-height: 100%;
    overflow: visible;
    //padding: 32px;

    @media (max-width: 1440px) {
      max-width: 1256px;
    }

    @media (min-width: 1921px) {
      max-width: calc(87% + 64px);
    }
  }
}
