.education-card-cover {
  position: relative;
  background: var(--color-calendar-primary-weaker);

  svg {
    max-width: 100%;
    height: auto;
  }

  &__title {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #91A3FC;
    font-size: 32px;
    font-weight: 800;
    line-height: 1;
    text-align: right;
    padding: 16px;
    text-transform: uppercase;
  }
}