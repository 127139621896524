.selected-tag {
  position: relative;
  min-width: 40px;

  .ant-tag {
    width: 100%;
  }

  .ant-dropdown-trigger {
    width: 12px!important;
    height: 100%!important;
    background: transparent!important;
    color: inherit!important;

    //.ant-btn-icon {
    //  color: inherit!important;
    //}

    .anticon {
      font-size: 12px!important;

      svg {
        color: inherit !important;
      }
    }
  }

  .ant-tag-close-icon {
    color: var(--color-calendar-text-second) !important;
  }


  .color-picker {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 11px;
    font-size: inherit!important;
    font-family: inherit!important;
    color: inherit!important;
    background: transparent!important;
    border: none!important;
    outline: none!important;
    line-height: 1.1!important;
  }

  &:hover, &_active {
    .selected-tag-action {
      right: 0;
      opacity: 1;
    }
  }

  &__value {

    &_transparent {
      color: transparent!important;
    }
  }
}

.selected-tag-action {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 4px;
  gap: 4px;
  transition: right .3s ease-in, opacity .3s ease-in;

  &__close {
    display: flex;
    cursor: pointer;
  }
}