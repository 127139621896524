.column_drag-start {
  border: 2px dashed var(--color-primary-base);
  border-radius: var(--border-radius-lg);
}

.column_drag-possibility-drop {
  border: 2px dashed var(--color-success-base);
  border-radius: var(--border-radius-lg);
}

.column_drag-lock-drop {
  border: 2px dashed var(--color-error-base);
  border-radius: var(--border-radius-lg);
  cursor: no-drop !important;
}

.todo-list {
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: 4px;
  width: 355px;
  flex-shrink: 0;
  padding: 8px;
  position: relative;
  height: 100%;
  //background-color: var(--color-background-strong);

  //& > *:last-child {
  //  height: 90%;
  //}

  &__pre-drag-loader {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 48px;
    bottom: 0;
    padding: 8px;
    //display: flex;
    //align-items: center;
    border-radius: 4px;
    //justify-content: center;
    z-index: 3;

    &__allow {
      background-color: var(--color-success-weak);
    }

    &__disabled {
      cursor: no-drop !important;
      background-color: var(--color-error-weak) !important;
    }

    > span {
      color: white;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 4px;
      padding: 16px 8px;
      display: block;
      position: sticky;
      top: 8px;

      span {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        font-weight: bold;
        color: burlywood;
      }
    }
  }

  &__loader-container {
    min-height: 300px;
    height: 100%;
  }
}
