.priorities-input {
  //width: 96px;

  .ant-input-wrapper {
    height: 41px;

    .ant-input-group-addon {
      min-width: 97px;
      padding: 0 16px;
    }

    > input {
      display: none;
    }
  }
}
