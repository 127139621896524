.zoom-wrap {
  //background: var(--color-layout-silver);
  //border-radius: var(--border-radius-xsm);
}

.zoom-input {
  display: flex;
  align-items: center;
  //background: var(--color-layout-silver);

  input {
    border: none;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none!important;
    text-align: center;
    width: auto;
    -moz-appearance:textfield;
    background: transparent;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }



  }

}

.app-content__wrapper {
  //&_communication, &_regulation {
    .zoom-wrap {
      background: var(--color-srf-secondary);
      border-radius: var(--border-radius-xsm);
    }
  //}
//TK-1788
  &_regulation {
    #page-header__wrapper {
      padding: 0!important;
    }
  }
}
