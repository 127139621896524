.uiKit-dialog {
  .ant-modal-header {
    margin-bottom: 16px !important;
  }
  &.__ss-dialog-body {
    .ant-modal-content {
      max-height: calc(100vh - 24px);
      display: flex;
      flex-direction: column;
    }
    .ant-modal-body {
      //max-height: 70vh;
      overflow: auto;
    }
  }

  &_new-style {
    .ant-modal-content {
      padding: 0 !important;
    }

    .ant-modal-header {
      padding: 24px !important;
      margin: 0!important;
      font-size: 16px !important;
      line-height: 1.4!important;
    }

    .ant-modal-body {
      padding: 8px 24px 0!important;
    }

    .ant-modal-footer {
      margin-top: 0!important;
      padding: 24px 24px 18px!important;
    }

  }
}
