.end-access-time-dialog {
  .ant-modal-content {
    padding-right: 348px!important;

    &:before {
      content: "";
      width: 348px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background:
        url("../../../../assets/images/end-access-time-dialog-bg.png") center center no-repeat,
        radial-gradient(201.84% 94.15% at 50% 50%, #FFFFFF 0.93%, #E1E5F5 100%);
      border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    }


  }
}

.end-access-time-dialog-title {
  &__icon {
    width: 40px;
    height: 40px;
    background-color: var( --color-error-base-opacity);
    color: var(--color-error-base);
    border-radius: var(--border-radius-md);
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.end-access-time-dialog-text {
  white-space: pre-line;
}

.end-access-time-dialog-footer {
  display: grid;
  gap: 16px;
  width: 100%;

  &__buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding-top: 16px;
    margin-bottom: 24px;

    &_resident {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .uiKit-button {
        width: 100%;
        max-width: 400px;
      }
    }
  }

  &__ps {
    text-align: left;
    white-space: pre-line;
    font-style: italic;
  }

  &__text {
    font-style: normal;
  }
}
