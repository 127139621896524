.issue-activity-priority-row {
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 24px;

  .issue-activity-text {
    min-width: 24px;
    padding: 4px;
    box-sizing: border-box;
    justify-content: center;
  }
}
