.comment-card__reaction {
  .user-reaction-chip_new-style {
    span.reaction-symbol {
      &_new-style {
        margin-right: 2px!important;
      }
    }
  }
}

.user-reaction-chip {
  cursor: pointer;
  border-radius: 15px;
  padding: 2px 7px;
  background-color: var(--color-faint-weakest);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-gray-weaker);
  }

  &:active {
    background-color: var(--color-gray-weak);
  }

  &_new-style {
    font-size: 12px;
    line-height: 1;
    padding: 3px 8px 4px;
    border-radius: var(--border-radius-lg);
    color: var(--color-layout-container);
  }

}

.reaction-symbol {
  font-size: 16px;

  &_new-style {
    font-size: inherit;
    line-height: 1;
    margin-right: 2px!important;
  }
}

.reaction-overlay {
  .ant-popover-content {
    div {
      //padding: 0 !important;
    }

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      padding: 0 !important;

      .ant-popover-inner-content {
        padding: 0 !important;
      }
    }
  }
}
