.user-selection-dialog__content {
  width: 100%;
  overflow: hidden;


  > .ant-spin-nested-loading {
    height: 100%;

    > .ant-spin-container {
      height: 100%;
    }
  }

  .user-selection-dialog__left-bar {
    flex-grow: 1;
    min-width: calc(50% - 9px);
    max-height: 100%;


    .user-selection-dialog__left-bar__list {
      overflow: auto;
      .user-selection-dialog__left-bar__label {
        cursor: pointer;
        &:hover {
          color: var(--color-primary-base) !important;
        }
        &-active {
          color: var(--color-primary-base) !important;
        }
      }
    }
  }

  .user-selection-dialog__right-bar {
    flex-grow: 1;
    min-width: 200px;
  }
}
