.uiKit-badge {

  &.__blink {
    sup {
      animation: blinker 1s ease-in-out infinite;
    }
  }

  .ant-badge-count {
    min-width: 16px;
    height: 16px;
    line-height: 16px;
  }
}
