.CompaniesManageForm-list {
  //background: var(--color-layout-fill-base);
  //border-radius: 4px;
}

.CompaniesManageForm-listItem {
  background: var(--color-layout-fill-base) !important;
  border-radius: 4px !important;
}

.companies-manage-card {
  background: var(--color-srf-secondary)!important;
}
