.dashboard-sidebar {
  &__wrapper {
    width: 260px;
    background: var(--color-srf-sidebar);
    //overflow-y: auto;
    overflow: hidden;

    .dashboard-sidebar__content {
      overflow-y: auto;
      flex-grow: 1;
    }

    //.ant-collapse-header, .ant-collapse-content {
    //  background: var(--color-srf-primary)!important;
    //}

  }

  &__arrow-button {
    cursor: pointer;
    color: var(--color-text-base);

    &:hover {
      color: #5672ff;
    }
  }


}
