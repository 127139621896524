.create-regulation-section-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  padding-bottom: 24px;
}

.regulations-transfer-list {
  position: relative;
  overflow: hidden;
  height: 385px;
  box-shadow: var(--shadow-down-sm);

  &__wrapper {
    //max-width: 1200px;
    border: 1px solid var(--color-gray-weaker);
    border-radius: 10px;
    background-color: var(--color-white);
    overflow: hidden;
  }

  &-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__button-group {
      display: flex;
      align-items: center;

      > :not(:last-child) {
        margin-right: var(--indent-lg);
      }
    }

    h2 {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
  }

  width: 100%;
  display: flex;

  &__left-container {
    padding: 16px;
    width: 50%;
    height: 100%;
    overflow-y: auto;
  }

  &__right-container {
    padding: 16px;
    width: 50%;
    height: 100%;
    overflow-y: auto;
  }

  .block-info {
    height: 100%;
    //width: calc(100% - 86px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__with-text {
      span {
        font-size: 15px;
        font-weight: 500;
        color: var(--color-gray-base);
      }
    }
  }
}
