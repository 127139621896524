.CompanyEditFormScheduleForm-Wrapper {
  //background: var(--color-background-stronger);
  //border-radius: 4px;

  .__date-error {
    font-size: 14px !important;
    color: var(--color-txt-error);
    line-height: 1.1em;
  }
}

.CompanyEditFormScheduleForm-control {
  span {
    white-space: nowrap !important;
  }

  .MuiFormControlLabel-label {
    flex-grow: 1 !important;
  }

  .rc-time-picker-input {
    width: inherit;
  }
}

.company-schedule {

}

.company-schedule-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  .uiKit-checkbox_new-design span.ant-checkbox + * {
    padding-inline-start: 8px!important;
    padding-inline-end: 0!important;
  }
}
