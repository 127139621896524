.top-app-bar-menu_container {
  flex: 0 0 auto;
  //width: 240px;
  height: 48px;
  //border-right: 1px solid var(--color-background-stronger);
  margin: auto !important;
  background: inherit;
  box-shadow: none !important;
  transition: color 220ms linear;
  cursor: pointer;
  //border-radius: var(--border-radius-sm);

  &:hover {
    //background-color: var(--color-background-stronger);
    .__name {
      color: var(--color-txt-active);
    }
  }

  .__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-txt-primary);
    transition: color .2s ease-in;
    margin-right: 4px;
    margin-bottom: 2px;
  }

  .__email {
    font-size: 12px;
    color: var(--color-txt-secondary);
  }

  .__name,
  .__email {
    line-height: 1.4em;
  }

  .__icon {
    transition: all 0.25s;
    color: var(--color-icn-default);
    font-size: 24px;

    &.__open {
      transform: rotate(180deg);
    }
  }
}

.top-app-bar-menu-overlay {
  //overflow: hidden;
  //border-radius: 0 0 8px 8px;
  ul {
    //width: 240px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    max-height: 70vh;
    overflow: hidden auto;
    clip-path: inset(0px -36px -36px -36px);
    border-top: 1px solid var(--color-layout-divider);

    .ant-dropdown-menu-title-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
