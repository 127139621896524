.employees-header {
  padding: 24px 16px 16px;
  display: grid;
  width: 100%;
  border-radius: var(--border-radius-md);
  background: var(--color-srf-primary);
  gap: var(--indent-lg);

  &__search {
    width: 100%;
    max-width: 500px;
    background: var(--color-srf-primary);
  }
}

.orgchart-select-employee {
  .ant-select-selection-overflow-item {
    .ant-select-selection-item {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      height: 22px !important;
      border-radius: 24px;
      background-color: var(--color-background-stronger);
      border: 1px solid var(--color-background-stronger);
      font-size: 12px;
      line-height: 22px !important;
    }
  }
}

.employee-container {
  overflow: hidden;
  padding: 12px 0;
  background: var(--color-srf-primary);
  border-radius: var(--border-radius-sm);

  > .flex-grow-1 {
    padding: 0 16px;
  }

}
