.regulation-editor-toolbar-action-drop-down {
  width: 242px;
  box-shadow: var(--shadow-down-lg);
  background: var(--color-srf-menu);
}

.regulation-editor-toolbar-action-item {
  display: grid;
  gap: 4px;
  padding: 8px 12px 6px;
  cursor: pointer;

  &:hover {
    .regulation-editor-toolbar-action-item__title {
      color: var(--color-txt-active);
    }
  }

  &__title {
    font-size: 14px;
    line-height: 1.4;
    color: var(--color-txt-primary);
    transition: color .2s ease-in;
  }

  &__description {
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-txt-secondary);

  }
}
