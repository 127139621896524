.uiKit-dropdownButton {
  width: auto !important;

  &.ant-btn-icon-only {
    width: 28px !important;
    height: 28px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-icon-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
