.drawer {
  &-bar {
    box-shadow: 4px 4px 30px -9px rgba(34, 60, 80, 0.2);
    overflow: hidden;
    padding: 8px 16px;
    max-height: 45px;
    height: 100%;
  }

  &-body {
    width: 100%;
    overflow-y: auto;
    //padding: 8px 16px;

    ul {
      margin-top: 0 !important;
    }

    b {
      margin-right: 2px;
    }
  }
}

.hint {
  &-highlighted {
    background-color: #ffe500c2;
  }

  b {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-bottom: 8px !important;
    word-break: break-word !important;
    white-space: initial;
    &__center {
      text-align: center;
    }
  }

  &-subtitle {
    font-size: 14px !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important;
    word-break: break-word !important;
    white-space: initial;
  }

  &-description {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
    margin: 0 0 16px 0;
  }

  &-marker {
    margin-right: 4px;
    color: var(--color-gray-strong);
    font-weight: 700;
  }

  &-image {
    max-width: 100%;
    margin-bottom: 16px;
    &__gif {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  &-ul-item {
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: 400;
  }
}
