.no-access-resource-dialog {
  min-width: 645px;
  width: auto!important;
}

.no-access-resource {
  display: grid;
  gap: 14px;
  color: var(--color-calendar-text-base);
  line-height: 1.4;


  &__text {
    font-size: 14px;
  }
}

.no-access-resource-content {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-size: 12px;
  }
}