.guide-icon {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.15s;
  stroke: var(--color-primary-base);

  &:hover {
    opacity: 0.8;
  }
}
