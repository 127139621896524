.subordinate {
  transition: transform .2s ease-in;
  position: relative;

  .user-card {
    cursor: pointer;
    display: flex;
  }

  .ant-avatar {
    box-sizing: border-box;
  }

  &:not(&_popover) {
    &:hover {
      //z-index: 2;
      //transform: scale(1.1);
      z-index: 1;
    }

    .uiKit-avatar {
      //position: static;
      border: 2px solid var(--color-brdr-inverse) !important;
      transition: border .2s ease-in;
    }

    &.subordinate_checked {
      &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid var(--color-brdr-accent);
      }

      .uiKit-avatar {
        position: relative;
        //border: 2px solid var(--color-calendar-primary-base) !important;
        //transform: scale(1.1);
        z-index: 1;
      }

    }
  }



  &_popover {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    min-width: 318px;
    padding: 8px 10px;
    box-sizing: border-box;
    transition: background .2s ease-in;
    cursor: pointer;

    &:hover {
      background: var(--color-layout-background);
    }

    .user-card {
      cursor: default;
    }
  }

}

.subordinates-list-group {
  & >.ant-avatar {
    background: var(--color-avatar-group-bg);
    z-index: 2;

    .ant-avatar-string {
      color: var(--color-avatar-group-text);
    }
  }
}

.subordinates-list-group-popover {
  .ant-popover-inner {
    padding: 12px 0;
  }

  .ant-popover-inner-content {
    max-height: 406px;
    overflow-y: auto;
    padding: 0 12px;
  }
}
