.notification-center-card__wrapper {
  &.__readonly {
    .uiKit-card {
      cursor: not-allowed !important;
    }
  }

  .uiKit-iconButton {
    color: var(--color-icn-accent);

  }
}
