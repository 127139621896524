.TaskRecurringCard-Main {
  .task-recurring-card__title {
    font-size: 15px;
    font-weight: bold;
    color: var(--color-dark-main);
  }

  .task-recurring-card__subtitle {
    font-size: 12px;
    color: var(--color-dark-weak);

    .__text {
      color: var(--color-dark-middle);
    }
  }
}
