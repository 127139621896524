.regulations-stats--table {
  &__head {
    tr {
      th {
        padding: 16px !important;
        border: none;
      }
    }
  }

  &__body {
    border-radius: 4px;
    border: 1px solid var(--color-gray-weaker) !important;
    tr {
      &:hover {
        border-radius: 4px;
        background-color: var(--color-faint-light);
      }
      td {
        padding: 16px !important;
        font-weight: 500;
        border-color: var(--color-gray-weaker) !important;
      }
    }
  }
  &__cell-primary {
    color: var(--color-primary-base) !important;
  }
}
