.issue-card {
  //padding: 4px;
  color: var(--color-txt-primary);

  &__header {
    display: flex;
    gap: 4px;

    .ant-tag {
      margin-right: 0;
    }


    }


  &__content {
    padding: 12px;
  }

  &__footer {
    border-top: 1px solid var(--color-brdr-light);
    padding: 8px 12px;
  }

}


.todo-list {
  &__card {
    //width: 325px !important;
    //padding: 8px !important;
    //border-radius: var(--border-radius-sm);

    //border-color: var(--color-background-stronger) !important;
    border: none!important;
    background: var(--color-srf-primary);
    box-shadow: 0 2px 12px 0 #0000000D;

    margin-bottom: 8px;
    z-index: 2;

    > .ant-card-head {
      padding: 4px 2px 5px 4px!important;
      margin-bottom: 0!important;
    }

    > .ant-card-body {
      padding: 0!important;
    }

    &:hover {
      box-shadow: 0 8px 32px 0 var(--color-shadow-card)!important;

      .todo-list__card-deadline-icon {
        opacity: 1;
      }

    }

    &.__border {
      width: 100%;
      border-radius: var(--border-radius-sm);
      margin-bottom: 8px;
      padding: 0!important;

      &.__warning,
      &.__primary,
      &.__success,
      &.__background {
        padding: 0 4px 4px!important;
      }


      &.__warning {
        background-color: var(--color-status-declined);

        .ant-card-head {
          color: var(--color-txt-alwaysdark) !important;
        }

      }

      &.__primary {
        background-color: var(--color-status-waiting-approval);
      }

      &.__success {
        background-color: var(--color-status-done);
      }

      &.__background {
        background-color: var(--color-status-recurring);
      }
    }

    &-task {
      margin-bottom: 8px;

      h5 {
        line-height: 18.62px;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 1px;
      }
    }

    &-name {
      text-align: left;
      word-break: break-word;
      font-size: 14px;
    }

    &-identifier {
      padding: 4px;
      margin-left: 6px;
      white-space: nowrap;
      font-size: 12px !important;
      font-weight: 400 !important;
      color: var(--color-icn-default)!important;
      line-height: 1;
    }

    &-footer {
      &__author {
        display: flex;
        overflow: hidden;

        h5 {
          font-size: 12px;
        }
      }

      &__createdAt {
        white-space: nowrap;
        //margin-top: 6px !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        color: var(--color-text-second) !important;
      }
    }
  }

  &__card-deadline {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text-second);
    transition: opacity .15s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }

  &__card-deadline-icon {
    opacity: 0;
    color: var(--color-icn-default);
    transition: color .2s ease-in, opacity .2s ease-in;

    &:hover {
      color: var(--color-icn-dark);
    }
  }
}

.card-issue-deadline-tooltip {
  max-width: 320px!important;
}

.card-issue-deadline-tooltip-container {
  margin: 0 -8px 5px;

  &__title {
    white-space: nowrap;
    font-weight: bold;
    padding: 0 16px 4px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--color-border);
  }

  &__text {
    padding: 0 16px;
  }
}

.card-issue-tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-height: 82px;
  overflow: auto;
  padding: 0 4px;

  .ant-tag {
    margin: 0!important;
  }

  & + .card-issue-orgchart {
    margin-top: 8px;
  }

}

.card-issue-orgchart {
  padding: 0 4px;
}

.todo-list-plus {
  width: 24px!important;
  height: 24px!important;
  padding: 0!important;
  transition: color .2s ease-in, background-color .2s ease-in;
  font-size: 24px;
  color: var(--color-primary-base);

  &:hover {
    background: var(--color-calendar-primary-weaker);
  }
}
