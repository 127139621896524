.PhoneInput-container {
  width: 100%;
}

.PhoneInput-input {
  width: 100% !important;
  height: 40px !important;
  border-radius: 8px !important;
  background-color: var(--color-layout-fill-base) !important;
  border: 1px solid var(--color-layout-fill-primary) !important;
}

.PhoneInput-button {
  background-color: var(--color-layout-fill-base) !important;
  border: 1px solid var(--color-layout-fill-primary) !important;
  border-radius: 8px 0 0 8px !important;
  //border-right: 1px solid var(--color-layout-fill-primary) !important;

  .selected-flag:hover {
    background-color: var(--color-layout-fill-primary) !important;
  }

  .arrow {
    border-top-color: var(--color-primary-base) !important;
  }
}

.PhoneInput-dropdown {
  box-shadow: var(--shadow-down-sm) !important;
}
