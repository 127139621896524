.interval-card {
  white-space: pre;
  background: var(--color-layout-fill-base);
  border-radius: 4px;
  border: 1px solid var(--color-background-stronger);
  transition: 0.25s;
  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow-down-sm);

    svg {
      opacity: 1;
      stroke: var(--color-primary-base);
    }
  }

  svg {
    opacity: 0.7;
  }
}

.settings-module-page__collapse {
  //background: var(--color-layout-container);
  .uiKit-card {
    background: var(--color-srf-primary)!important;
  }


}
