.uiKit-tagCheckableGroup {
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  height: 32px;
  .uiKit-tagCheckableGroupItem {
    color: var(--color-primary-base);
    border: none;
    margin: 0;
    border-radius: 0;
    line-height: 32px;
    white-space: nowrap;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    background: var(--col-bg-alt);
    &:hover {
      background: var(--col-bg-hover);
    }
    &.ant-tag-checkable-checked {
      //font-weight: 500;
      background: var(--col-bg);
      &:hover {
        background: var(--col-bg-hover);
      }
    }
  }
}
