.icon {
  &:hover {
    color: var(--color-primary-base);
    cursor: pointer;
  }
}

.table-row-deleted {
  opacity: 0.5;
}

.issue-info {
  width: 400px;
  padding: 8px 12px!important;
}

.issue-plan-fact {
  white-space: break-spaces;
  max-width: 130px;
  width: 130px;
  min-width: 130px;
  padding: 8px 16px !important;
}

.issue-start-end {
  width: 158px;
  min-width: 158px;
  padding: 8px 16px !important;
}

.status {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
  padding: 8px 16px !important;
}

.planning-table {
  max-height: none;
  //overflow-y: auto;
  min-width: 100% !important;

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 2;

    th, td {
      background: transparent!important;
    }
  }

  .issue-info {
    max-width: 350px;
    width: 100%;
    min-width: 350px;

    &_google-event {
      max-width: none;
      width: 100%;
    }
  }

  .ant-select-selector {
    //padding-right: 30px!important;
    font-size: 12px!important;
  }

  .ant-select-selection-item {
    display: block;
  }


  &_with-border-radius {
    .ant-table-bordered, .ant-table-cell {
      border-radius: 0!important;
    }
  }
}

.issue-info-content {
  display: grid;
  gap: 8px;

  &__result {
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-text-second);
  }
}

.google-event-status {
  font-size: 12px;
  line-height: 1.35;
  padding: 8px 12px;
  color: var(--color-calendar-text-base);
  border-radius: var(--border-radius-sm);

  &_finished {
    background-color: var(--color-success-weaker);
    color: var(--color-black);

  }

  &_declined {
    background-color: var(--color-warning-weaker);
    color: var(--color-black);
  }

  &_unfinished {
    background-color: var(--color-calendar-default);
  }
}
