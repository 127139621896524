.calendar-planning-calendar__wrapper {
  background: var(--color-srf-primary);
  display: grid;
  grid-template-rows: 1fr auto;

  .fc-timegrid-event {
    border-radius: 4px!important;

    .fc-event-main {
      padding: 0;
    }

  }

  .fc-event-time {
    //font-size: 12px;
  }

  .fc-event-title {
    //font-size: 10px;
  }

  .fc-multimonth-title {
    font-size: 14px;
    font-weight: bold;
  }

  .fc-daygrid-day-frame .fc-daygrid-day-top a {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-base);
    font-size: 14px;
    line-height: 1;
    padding: 0;
    border-radius: 50%
  }

  .fc-theme-standard th {
    border-bottom-width: 4px;
  }
}

.rbc-day-bg {
  &:hover {
  }
}

.fc-daygrid-day-events {
  .fc-daygrid-event-harness + .fc-daygrid-event-harness {
    margin-top: 4px!important;
  }
}

.calendar-card-tag {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-radius: 4px 0 0 4px
}

.calendar-card {
  padding: 0 8px;

    .fc-event-main {
    position: static;
  }

  &_lg {
    padding: 4px;

    .calendar-card-wrapper_tag {
      padding-left: 4px;
    }
  }

  &_small {
    padding: 5px 8px 3px;
    border: none;
    //color: var(--color-text-base);
    //background: transparent!important;

    &.fc-daygrid-event {
      border-radius: 4px!important;
    }


    .calendar-card-wrapper-header {
      align-items: center;
      gap: 8px;
    }

    span {
      line-height: 1.4;
    }

    &:after {
      display: none;
    }

  }

  &_done {
    opacity: 0.6;
  }

}



.calendar-card-wrapper {



  &__dot {
    display: flex;
    width: 4px;
    min-width: 4px;
    height: 4px;
  }

  &__title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.calendar-card-wrapper-header {
  display: flex;
  gap: 5px;
  //justify-content: space-between;

  &__icon {
    display: flex;
    align-items: center;
    height: 16px;

  }

}

.fc-daygrid-day-frame {
  padding: 16px 7px 10px 4px;
}


$days: ("sun" "mon" "tue" "wed" "thu" "fri" "sat");
@each $day in $days {
  .fc-day-#{$day} {
    background: var(--date-#{$day}-color);
  }
}

.fc-col-header-cell {
  &.fc-day-sat, &.fc-day-sun {
    span {
      color: var(--color-txt-secondary) !important;
    }
  }
}


:root {
  //--fc-page-bg-color: var(--color-layout-drawer);
  --fc-page-bg-color: transparent;
  //--fc-neutral-bg-color: var(--color-layout-divider);
  //--fc-neutral-bg-color: var(--color-layout-background);
  //--fc-neutral-bg-color: gold;
  //--fc-neutral-bg-color: var(--color-layout-drawer);
  //--fc-border-color: var(--color-calendar-layout-divider);
  --fc-border-color: var(--color-brdr-inverse);

  --fc-event-bg-color: var(--color-srf-accent);

  --fc-event-border-color: var(--color-brdr-inverse);

  --fc-daygrid-event-dot-width: 2px;
  --fc-today-bg-color: transparent;
  //--fc-today-bg-color: rgba(0, 255, 0, 0.05);
  --fc-now-indicator-color: var(--color-txt-error);
  --date-sat-color: var(--color-bg-primary);
  --date-sun-color: var(--color-bg-primary);
}
