.file-uploader {
  &__sub-container {
    //padding-bottom: 8px;
    padding-right: 0 !important;
    position: relative;
    //cursor: grabbing;
  }

  &__container {
    //background: var(--color-layout-fill-base);
    border-radius: 4px;

    .__scroll-wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;

      .scroll-button {
        &-arrow_to_prev {
          left: 0;
          margin-left: 4px;

          svg {
            transform: translateX(-1px);
          }
        }

        &-arrow_to_next {
          right: 0;
          margin-right: 4px;

          svg {
            transform: translateX(1px);
          }
        }

        &-arrow_to_prev,
        &-arrow_to_next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 100;
          background: var(--color-layout-container) !important;
          background: 50%;
          padding: 2px !important;
          opacity: 0.9;
          box-shadow: var(--shadow-down-sm) !important;
          transition: 0.15s;

          svg {
            stroke: var(--color-primary-base);
            //fill: var(--color-primary-base);
          }

          &:hover {
            opacity: 1;

            svg {
              //stroke: var(--color-primary-base);
              //fill: var(--color-primary-base);
            }
          }
        }
      }
    }

    .file-uploader__files-number {
      color: var(--color-dark-weak);
      text-align: end;
    }

    .file-uploader__dnd-zone {
      border-radius: 4px;
      border: 3px dashed var(--color-primary-base);
      transition: 0.15s;
      z-index: 2500;
      position: relative;
      background: var(--color-layout-fill-base);
      //cursor: copy;

      p {
        text-align: center;
        color: var(--color-dark-weak);
        transition: 0.15s;
        //pointer-events: none;
      }

      .hovered {
        background: var(--color-white);

        p {
          color: var(--color-primary-base);
        }
      }
    }

    .file-drop {
      position: relative;
      width: 100%;
      margin-bottom: 8px;
      border-radius: 4px;
      border: 3px dashed var(--color-primary-base);
      transition: 0.15s;
    }

    .file-drop > .file-drop-target {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .file-drop > .file-drop-target.file-drop-dragging-over-frame {
      border: none;
      box-shadow: none;
      z-index: 50;
      height: 100px;
    }

    .file-drop > .file-drop-target.file-drop-dragging-over-target {
      color: var(--color-primary-base);
      background: var(--color-white);
    }
  }
}
