.subordinates-list {
  display: flex;
  gap: 8px;
  align-items: center;
}

.subordinates-list-clear {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  cursor: pointer;
  font-size: 14px;

  &__icon {
    display: flex;
    color: var(--color-text-second);
    transition: color .2s ease-in;
  }

  &__text {
    color: var(--color-calendar-text-base);
    transition: color .2s ease-in;
  }

  &:hover {
    .subordinates-list-clear__icon,
    .subordinates-list-clear__text {
      color: var(--color-primary-base);
    }
  }
}