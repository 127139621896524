.collapse {
  &__custom-panel {
    .ant-collapse-header {
      padding: 12px !important;
      min-height: 40px;
      line-height: 1.25em !important;
      //align-items: center;

      &:hover {
        .ant-collapse-expand-icon svg {
          stroke: var(--color-primary-weak);
        }
      }
    }

    .ant-collapse-expand-icon {
      height: 18.85px !important;
      //height: 22px !important;
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  &__item {
    cursor: pointer;

    span {
      //transition: all 0.1s;
    }

    &.__is_active {
      .dashboard-item-text {
        color: var(--color-txt-active);
      }
    }

    &:hover .dashboard-item-text {
      color: var(--color-txt-active);
    }
  }
}
