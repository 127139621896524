.calendar-planning-footer {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  margin-left: 1px;
  max-width: 100%;

  &__item {
    padding: 3px 4px;
    border: 1px solid var(--color-brdr-default);

    &_first {
      color: var(--color-icn-accent);
      text-align: center;
    }

    &_day-off {
      background: var(--color-bg-primary);
    }

  }
}
