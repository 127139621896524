.print-metric-table {
  page-break-before: always;

  table {
    table-layout: auto!important;
  }
  .ant-table-cell, .ant-input-number-input {
    text-align: center!important;
  }

  .ant-input-number-input {
    height: auto!important;
  }

  th.ant-table-cell {
    font-size: 10px!important;
    padding: 12px 5px!important;
  }

  .ant-table-cell {
    padding: 4px 5px!important;
  }

  .ant-table-cell, .ant-input-number, .user-info__name  {
    font-size: 11px!important;
  }

  .ant-avatar-sm {
    width: 20px!important;
    height: 20px!important;
    line-height: 20!important;
  }

  .dashboard-chart-label-list {
    font-size: 14px;
  }
}
