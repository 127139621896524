.executor-select__wrapper {
  .__cards-container {
    --gap: 8px;
    display: inline-flex;
    flex-wrap: wrap;
    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    .uiKit-tag {
      margin: var(--gap) 0 0 var(--gap) !important;
    }
  }
}
