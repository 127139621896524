.select-tags {
  min-height: 48px;

  .ant-select-selector {
    max-height: 88px;
    overflow: auto;
    padding: 12px 48px 12px 12px!important;
    min-height: 48px;

    &:after {
      display: none!important;
    }
  }

  .ant-select-selection-overflow {
    gap: 8px;
  }

  .ant-select-selection-search {
    margin-left: 0!important;
  }

  .ant-select-selection-search-mirror {
    height: 22px!important;
    line-height: 22px!important;
  }
}

.select-tags-dropdown {
  padding: 12px;

  .rc-virtual-list-holder-inner {
    flex-wrap: wrap;
    flex-direction: row!important;
    gap: 8px;
  }

  .ant-select-item-option {
    padding: 0!important;
    min-height: auto!important;
  }

  .ant-select-item-option-selected {
    display: none!important;
  }

  .ant-select-item {
    background: none!important;
  }

    //.ant-select-selection-overflow {
  //  gap: 8px;
  //}
}
