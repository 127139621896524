@keyframes app-preloader__loader {
  0%,
  75%,
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
  25%,
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes app-preloader__background {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.15;
    border-radius: 8px;
  }

  100% {
    transform: translateY(-150vh) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes app-preloader-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.app-preloader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  z-index: 2147483647;

  &.__dark {
    background-color: #141414;

    .app-preloader__loader {
      span::after {
        color: #35469a;
      }
    }

    .app-preloader__text-primary {
      color: #7083e3;
    }

    .app-preloader__text-secondary {
      opacity: 0.6;
      color: #ffffff40;
    }
  }

  &.__out {
    animation: app-preloader-out ease-in 1.8s 1 normal forwards;
  }
}

.app-preloader__loader {
  font-family: "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 84px;
  font-weight: 800;
  text-align: center;
  //backdrop-filter: blur(12px);

  span {
    font-family: "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif !important;
    display: inline-block;
    margin: 0 -0.025em;
    /*margin: 0;*/
    position: relative;
    color: rgba(0, 0, 0, 0.2);

    &::after {
      font-family: "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif !important;
      position: absolute;
      top: 0;
      left: 0;
      content: attr(data-text);
      color: #5672ff;
      opacity: 0;
      transform: scale(1.5);
      animation: app-preloader__loader 3s infinite;
    }
  }
}

.app-preloader__text-primary,
.app-preloader__text-secondary {
  pointer-events: none;
}

.app-preloader__text-primary {
  color: #788eff;
  font-size: 16px;
  opacity: 0.8;
}

.app-preloader__text-secondary {
  color: #00000073;
  font-size: 14px;
  opacity: 1;
}

@for $i from 0 to 15 {
  .app-preloader__loader span:nth-child(#{$i + 2})::after {
    animation-delay: #{(0.1 * $i)}s;
  }
}

//@media screen and (max-width: 960px) {
//  .app-preloader__wrapper {
//    font-size: 42px;
//  }
//}

.app-preloader__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #5672ff;
    animation: app-preloader__background 25s linear infinite;
    bottom: -150px;
  }

  li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
}
