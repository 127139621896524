.function-selection-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: var(--color-layout-background);
}

.function-selection-form {
  display: grid;
  gap: 12px;

  &__row {
    display: grid;
    gap: 8px;
  }

}

.function-tree {
  .ant-tree-list-holder-inner {
    gap: 8px;
  }

  .ant-tree-treenode {
    padding-bottom: 0!important;
    width: 100%;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .ant-tree-title {
    width: 100%;
  }

  .ant-tree-indent-unit {
    width: 8px!important;
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.function-selection-info {
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 12px;
  border-radius: var(--border-radius-xsm);
  border: 1px solid #C2E899;
  background: var(--color-info-card);
  font-size: 12px;
  line-height: 1.4;
  color: var(--color-calendar-text-base);

  &__close-icon {
    display: flex;
    padding-top: 1px;
    cursor: pointer;
    color: var(--color-text-second);
    transition: color .2s ease-in;

    &:hover {
      color: var(--color-primary-base);
    }

  }
}

.function-selection-radio-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.function-selection-radio-item {
  display: flex;
  gap: 8px;

  &__icon {
    font-size: 20px;
    color: var(--color-radio-border);
  }

}