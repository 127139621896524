$grid-el-height: 70px;
$grid-el-width: 84px;
$grid-el-offset: 4px;

.schedule-row__wrapper {
  .schedule__row__role__user-card__wrapper {
    width: 288px;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    z-index: 2;
    box-sizing: content-box;
    padding-right: $grid-el-offset * 2;

    .schedule__row__role__user-card {
      //background-color: var(--color-layout-background);
      //margin-right: $grid-el-offset;
      border-radius: 4px;
      background-color: var(--color-srf-primary);

      .ant-card-body {
        border-radius: 4px;
        padding: 0 8px !important;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        height: $grid-el-height;
        overflow: hidden;
      }

      .__substitute {
        margin-left: auto;
        padding-left: 8px;
        text-align: right;
        font-size: 14px;
        color: var(--color-text-weak);
      }
    }
  }

  .schedule__row__role__expander {
    width: 288px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: sticky;
    left: 0;

    &.__expanded {
      svg {
        transform: rotate(90deg);
      }
    }

    .__role-name {
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      .__role-name {
        //overflow: visible;
        color: var(--color-primary-base);
      }
    }

    svg {
      transition: all 0.15s;
    }
  }
}
