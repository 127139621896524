.notification-center__wrapper {
  max-width: 740px !important;
  width: 100% !important;
  background: transparent!important;

  .ant-popover-inner {
    background: var(--color-srf-primary)!important;
  }

  .ant-badge-count {
    //color: var(--color-txt-inverse);
  }

  .ant-tabs-tabpane {
    max-height: 520px;
    //  //height: 100vh;
    //  //display: flex;
    //  //flex-direction: column;
    overflow: auto;
    //position: relative;
    //display: flex;
    //flex-direction: column;
  }

  //.ant-badge-status-dot {
  //  cursor: pointer !important;
  //}
}
