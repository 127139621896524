.regulation-view-header {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 20px 16px;
  align-items: center;
}

.regulation-view-header-buttons {
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.regulation-view-header-breadcrumbs {
  margin: 0 -4px;
  padding: 0 4px;
  overflow: hidden;

  .breadcrumbs-new-container {
    li:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}