.ai-assistant-footer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .uiKit-input {
    background: var(--color-srf-primary);
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__btn {
    &_disabled {
      background: transparent!important;
      pointer-events: none;
    }
  }

  &__input {
    position: relative;

    .uiKit-textArea {
      padding: 12px 40px 12px 16px;
    }

    .ai-assistant-footer__btn {
      position: absolute;
      bottom: 13px;
      right: 16px;
    }

  }

}

