.history-list {
  display: grid;
  gap: 28px;
  margin-top: 28px;
  //max-height: 500px;
  overflow-y: auto;
  padding: 0 16px;

  .comment-card {
    margin: 0!important;
  }

}