.create-regulation-form {
  //margin-top: 36px;
  //max-width: 1200px;
  //width: 100%;
}
.base-layout {
  display: flex;
  justify-content: center;
  width: 100%;
}



.create-regulation-form {
  .editor-container {
    width: 75%;
    min-width: 453px;
    margin: 0 auto;
  }
  .text-editor-placeholder {
    left: 24px!important;
    top: 8px!important;
  }

  .editor-wrapper {
    margin-top: 24px;
  }
}

.create-regulation-form-tests-form-wrap {
  width: 55.83%;
  min-width: 453px;
  margin: 0 auto;
  padding: 0 36px;
}