.uiKit-avatar {
  position: relative;
  flex-shrink: 0;
  //overflow: visible !important;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &_no-flex {
    display: inline-block;
  }

  &.__outlined {
    //outline: 2px solid var(--color-primary-base);
    //outline-offset: 2px;
    border: 1px solid var(--color-primary-base);
    box-shadow: 0 0 4px var(--color-primary-base), 0 0 8px var(--color-primary-base);
  }
  span {
    text-transform: uppercase;
    //text-transform: capitalize;
  }
}
