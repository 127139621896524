.issue-common-dialog {
  .ant-modal-content {
    max-height: 90vh;
  }

  &.__warning {
    .ant-modal-content {
      border: 8px solid var(--color-warning-base);
      border-top: 40px solid var(--color-warning-base);
      position: relative;
      padding: 8px 24px;

      & > .ant-modal-header {
        position: absolute;
        top: -30px;
        left: 4px;
        background: var(--color-warning-base);
      }
    }
  }

  &.__primary {
    .ant-modal-content {
      border: 8px solid var(--color-primary-base);
      border-top: 40px solid var(--color-primary-base);
      position: relative;
      padding: 8px 24px;

      & > .ant-modal-header {
        position: absolute;
        top: -30px;
        left: 4px;
        background: var(--color-primary-base);
      }
    }
  }

  &.__success {
    .ant-modal-content {
      border: 8px solid var(--color-success-weak);
      border-top: 40px solid var(--color-success-weak);
      position: relative;
      padding: 8px 24px;

      & > .ant-modal-header {
        position: absolute;
        top: -30px;
        left: 4px;
        background: var(--color-success-weak);
      }
    }
  }

  &.__background {
    .ant-modal-content {
      border: 8px solid var(--color-calendar-faint-base);
      border-top: 40px solid var(--color-calendar-faint-base);
      position: relative;
      padding: 8px 24px;

      & > .ant-modal-header {
        position: absolute;
        top: -30px;
        left: 4px;
        background: var(--color-calendar-faint-base);
      }
    }
  }
}
