.subheader-text {
  color: var(--color-inp-label-default);
  font-size: 15px;
  line-height: 1.1em;
  display: block;
  //white-space: pre;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
