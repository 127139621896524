.time-picker-inline-primary__select__menu {
  max-height: 250px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  li {
    padding: 2px 0 !important;
    justify-content: center;
    font-size: 12px;
  }
}

.time-picker-inline-primary__select {
  position: relative;
  text-align: center;
  //font-size: 18px;
  //font-weight: bold;
  line-height: 40px !important;

  &.__square {
    .MuiSelect-select {
      //width: 2em !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .MuiSelect-select {
    background: var(--color-layout-fill-base);
    padding: 0 8px !important;
    box-sizing: border-box !important;
    width: 40px !important;
    height: 40px !important;

    &:hover {
      ~ fieldset {
        border: 1px solid var(--color-primary-base);
      }
    }
  }

  input {
    width: 100% !important;
    height: 100% !important;
  }

  fieldset {
    border: none;
    //transition: 0.05s;
  }

  .MuiSelect-icon {
    display: none;
  }
}
