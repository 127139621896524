.issue-switch-status {
  &__wrapper {
    width: min-content;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--color-calendar-text-base);
  }
}

.issue-switch-background {
  &__success {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-success-weaker);
          color: var(--color-black);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-black) !important;
          }
        }
      }
    }
  }

  &__warning {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-warning-weaker);
          color: var(--color-black);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-black) !important;
          }
        }
      }
    }
  }

  &__error {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-error-weaker);
          color: var(--color-black);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-black) !important;
          }
        }
      }
    }
  }

  &__primary {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-primary-weaker);
          color: var(--color-black);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-black) !important;
          }
        }
      }
    }
  }

  &__secondary {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-layout-fill-secondary);
        }
      }
    }
  }

  &__dark {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-gray-strong);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-text-contrast) !important;
          }
        }
      }
    }
  }

  &__faint {
    .ant-spin-container {
      .ant-select {
        .ant-select-selector {
          border: none;
          background-color: var(--color-gray-strong);
          color: var(--color-text-contrast);
        }
        .ant-select-arrow {
          svg {
            fill: var(--color-text-contrast) !important;
          }
        }
      }
    }
  }
}
