@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(1.05deg) scale(1.1);
  }
  50% {
    transform: rotate(0deg) scale(1);
  }
  75% {
    transform: rotate(-1.05deg) scale(1.1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.app-content__wrapper__unauthorized {
  background-color: var(--color-primary-base);
  //background: linear-gradient(red, transparent),
  //linear-gradient(to top left, lime, transparent),
  //linear-gradient(to top right, blue, transparent);
  background-blend-mode: screen;
  padding: 8px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  transform: scale(1);

  &:before {
    content: "";
    position: absolute;
    top: -24px;
    bottom: -24px;
    left: -24px;
    right: -24px;
    background: url("../assets/images/bgd-texture.jpg") no-repeat center center;
    background-size: cover;
    animation: spin 25s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    mix-blend-mode: multiply;
    filter: blur(6px);
    //opacity: 0.6;
  }
}

html[data-app-theme-id="1"] {
  .app-content__wrapper__unauthorized {
    background-color: var(--color-primary-strong);

    &::before {
      filter: brightness(60%) blur(6px);
    }
  }
}

.layout-card-unauthorized__wrapper {
  background: var(--color-srf-primary);
  //background: rgba(255, 255, 255, 0.2) !important;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  //backdrop-filter: blur(6.3px) !important;
  //-webkit-backdrop-filter: blur(6.3px) !important;
  //border: 1px solid rgba(255, 255, 255, 0.3) !important;
}
