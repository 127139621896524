.issues-archive {
  display: flex;
  flex-direction: column;
}

.issues-archive-wrap {
  overflow-y: auto;
  margin-right: -12px;
  padding-right: 12px;
}

.issues-archive-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 12px 8px;
  background: var(--color-calendar-sidebar);


  .todo-list__card.__border {
    margin: 0;
  }
}