.uiKit-inputPhoneNumber {
  height: 40px;

  &:hover {
    input,
    .flag-dropdown {
      transition: 0.25s;
      border: 1px solid #8099ff;
    }
  }

  &.no-interact {
    .arrow {
      display: none;
    }
  }

  input {
    max-width: 100%;
    width: 100% !important;
    height: 40px !important;
    border-radius: 8px !important;
    //background-color: var(--color-white) !important;
    font-size: 16px !important;
    //border: 1px solid #d9d9d9;

    &::placeholder {
      color: #bfbfbf;
    }
    &:not(:hover) {
      border: 1px solid var(--color-layout-divider) !important;
    }
    &:hover {
      //border: 1px solid var(--color-primary-base) !important;
    }
  }

  .flag-dropdown {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
    &:not(:hover) {
      border: 1px solid var(--color-layout-divider) !important;
    }
  }

  .selected-flag {
    border-radius: 8px 0 0 8px !important;
    background: transparent !important;
  }

  &.react-tel-input {
    .country-list {
      margin: 4px 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      background: var(--color-srf-menu) !important;

      li {
        margin: 4px !important;
        padding: 8px 6px !important;
        border-radius: 5px !important;

        &.highlight {
          background: var(--color-layout-fill-secondary) !important;
        }

        &:hover {
          background: var(--color-layout-fill-tertiary) !important;
        }
      }

      .country {
        .dial-code {
          color: var(--color-txt-secondary);
        }
      }

    }
  }
}

.react-tel-input {
  .invalid-number-message {
    background: var(--color-layout-background) !important;
    border-radius: 5px !important;
    padding: 0 8px !important;
    color: var(--color-txt-error) !important;
  }
}
