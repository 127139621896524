.reaction-button {
  &_border {
    line-height: 1;

    .uiKit-button {
      width: auto;
      height: auto;
      padding: 2px 7px 3px;
      border: 1px solid var(--color-calendar-text-second)!important;
      border-radius: var(--border-radius-lg);

      svg {
        width: 12px;
        height: auto;
      }

    }
  }

  &_new-style {
    .ant-btn {
      &:hover {
        background: var(--color-calendar-layout-divider-light);
        color: var(--color-calendar-text-second)!important;
      }
    }

  }

}