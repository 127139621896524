.list-companies {
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  //flex-grow: .50;
  width: 49%;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}
