.calendar-time-block-action {
  position: absolute;
  top: 11px;
  right: 42px;
}

.google-calendar-event {
  display: grid;
  margin: 0 -24px;
}

.google-calendar-event-guests {
  display: grid;
  gap: 10px;
}

.google-calendar-event-row {
  padding: 12px 24px;

  &_first, & + & {
    border-top: 1px solid var(--color-border-second);
  }

}

.google-calendar-event-info {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 12px;

  &__icon {
    display: flex;
    color: var(--color-text-second);
  }

  &__content {
    word-break: break-word;
    font-size: 14px;
    line-height: 1.4;
    color: var(--color-calendar-text-base);
  }
}