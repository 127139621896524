.change-password-dialog {
  .subheader-text {
    font-size: 14px;
  }

  .ant-form-item-explain-error {
    font-size: 12px!important;
  }
}

.form-row-popover-container {
  position: relative;
  span {
    display: block;
  }
}

.validation-info {
  display: grid;
  gap: 4px;
}

.validation-info-item {
  display: grid;
  gap: 8px;
  grid-template-columns: 16px 1fr;
  color: var(--color-txt-secondary);
  font-size: 12px;
  line-height: 1.4;

  &_active {
    color: var(--color-txt-success);
  }

  &_error {
    color: var(--color-txt-error);
  }
}

.change-password-error-popover {
  display: grid;
  gap: 12px;
  max-width: 314px;
  padding: 6px 16px 11px;
  color: var(--color-calendar-text-base);

  &__title {
    margin: 0 -16px;
    padding: 0 16px 4px;
    font-size: 16px;
    line-height: 1.5;
    border-bottom: 1px solid var(--color-border);
  }

  &__text {
    font-size: 12px;
    line-height: 1.4;

    span {
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
      transition: color .15s ease-in;

      &:hover {
        text-decoration: none;
        color: var(--color-primary-base);
      }

    }
  }

}
