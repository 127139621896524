.regulation-button-icons {
  color: var(--color-calendar-text-second);
  //&__edit {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    stroke: #9ba6c2;
  //    &:hover {
  //      stroke: var(--color-primary-base);
  //    }
  //  }
  //}
  //&__save {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    stroke: #9ba6c2;
  //    &:hover {
  //      stroke: var(--color-primary-base);
  //    }
  //  }
  //}
  //&__cancel {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    fill: #9ba6c2;
  //    &:hover {
  //      fill: var(--color-primary-base);
  //    }
  //  }
  //}
  //&__publish {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    stroke: #9ba6c2;
  //    &:hover {
  //      stroke: var(--color-primary-base);
  //    }
  //  }
  //}
  //&__access {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    fill: #9ba6c2;
  //    &:hover {
  //      fill: var(--color-primary-base);
  //    }
  //  }
  //}
  //&__delete {
  //  &:disabled {
  //    opacity: 0.6;
  //  }
  //  svg {
  //    transition: all 0.2s ease-in-out;
  //    fill: #9ba6c2;
  //    &:hover {
  //      fill: var(--color-error-base);
  //    }
  //  }
  //}
}
