.education-card {
  border: none;
  background: var(--color-srf-primary)!important;
  transition: box-shadow .2s ease-in;
  box-shadow: none!important;
  border-radius: 16px!important;

  &:hover {
    box-shadow: 0 4px 20px 0 var(--color-shadow-second)!important;
  }

  &-disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
  }

  button {
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }

  img {
    display: block;
    object-fit: cover;
    aspect-ratio: 16 / 8.2;
    height: 100%;
    width: 100%;
    border-radius: 16px 16px 0 0!important;
  }

  .ant-card-body {
    padding: 16px!important;
  }

  .ant-card-meta-title {
    font-weight: 700;
    color: var(--color-txt-primary);
  }

  .ant-card-meta-description {
    color: var(--color-txt-secondary);
  }

  &__progress {
    margin-top: 8px;

    .ant-progress-text {
      color: var(--color-txt-secondary)!important;
    }
  }
}

.education-card-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  height: 28px;
  align-items: center;
  background: var(--color-srf-primary);
  font-size: 12px;
  line-height: 1.4;
  padding: 0 10px;
  border-radius: var(--border-radius-lg);
  color: #9BA6C2;

  &_success {
    color: var(--color-calendar-success-base);
  }

}
//
//.education-card-cover {
//  background: #F0F3FF66;
//
//}
