.uiKit-inputNumber {
  //

  &_new-design {
    border-color: var(--color-inp-border-default);
    border-radius: var(--border-radius-md);

    .ant-input-number-input {
      padding-left: 16px;
    }

    &:hover {
      //border-color: var(--color-calendar-card-border);

      .ant-input-number-handler {
        border-right-color: var(--color-inp-border-default);
      }

    }

    &.ant-input-number-focused {
      border-color: var(--color-inp-border-focus);

      .ant-input-number-handler-up {
        border-top-color: var(--color-inp-border-focus);
        border-right-color: var(--color-inp-border-focus);
      }
      .ant-input-number-handler-down {
        border-bottom-color: var(--color-inp-border-focus);
        border-right-color: var(--color-inp-border-focus);
      }

    }

    .ant-input-number-handler-wrap {
      top: 1px;
      right: 0;
      height: calc(100% - 2px);
      //background: transparent;
    }

    .ant-input-number-handler-up {
      border-top: none!important;
      border-right: none!important;
      border-top-right-radius: var(--border-radius-md);
    }

    .ant-input-number-handler-down {
      top: -1px;
      border-bottom: none!important;
      border-right: none!important;
    }

    .ant-input-number-handler {
      position: relative;
      height: 50%!important;
      border: 1px solid var(--color-inp-border-default);
      border-right-color: transparent;
      //border: 1px solid var(--color-calendar-card-border);

      &:hover {
        border-color: var(--color-inp-border-hover);
        z-index: 1;
      }
    }



    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      font-size: 12px!important;
    }

  }
}
