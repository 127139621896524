.select-approver-form {
  display: grid;
  gap: 16px;

  .select-approver-form-row {
    .ant-form-item {
      margin-bottom: 0 !important;

      .ant-form-item-control-input {
        height: auto;
      }
    }

    &_checkbox {
      gap: 12px;
    }
  }
}