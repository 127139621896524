.uiKit-radio {

  color: var(--color-txt-primary)!important;
  line-height: 1.4!important;

  span.ant-radio + * {
    padding-inline-start: 12px!important;
  }

  &:hover {
    .ant-radio-inner {
      border-color: var(--color-ctr-active-hover)!important;
    }
  }

  .ant-radio-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-ctr-inactive)!important;
    background: none!important;


    &:after {
      position: relative!important;
      width: 8px!important;
      height: 8px!important;
      background: var(--color-ctr-active) !important;
      border-radius: 50%;
      margin: 0!important;
      inset-block-start: unset;
      inset-inline-start: unset;
    }

  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--color-ctr-active) !important;

      &:after {
        transform: scale(1) !important;
      }

    }
  }

  .anticon {
    color: var(--color-txt-secondary);
  }

}
