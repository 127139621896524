.planning-collapse-header {
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;
  color: var(--color-calendar-text-base);

  &__icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &_google {
      position: relative;
      top: -1px;
      border-radius: 0;
      color: var(--color-primary-weaker-second);
    }
  }
}
