.communication-mode-switch-wrapper {
  //background: var(--color-layout-silver);
  padding: 2px;
  //border-radius: var(--border-radius-xsm);

  .ant-card-body {
    //background: var(--color-layout-silver);

  }

  .ant-segmented-group {
    gap: 4px;
  }

  .ant-segmented-item {
    width: 32px;
    background: transparent;

    .ant-badge {
      color: inherit!important;
    }

  }

  .ant-segmented-item-selected {
    color: var(--color-icn-accent);
    background: var(--color-srf-primary);
    box-shadow: 0 2px 12px 0 #0000000D;

  }

  .ant-segmented-item-label {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
