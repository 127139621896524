.planning-page-menu {
  padding: 4px 8px 16px;
  margin-bottom: 16px;

  .ui-label {
    padding: 0 15px;
    width: 150px;
  }

}


.planning-page-admin-menu {
  &__input-search {
    > div {
      background-color: var(--color-white) !important;
    }

    &:hover :not(:focus-within) {
      fieldset {
        border-color: var(--color-primary-trans-1) !important;
      }
    }

    // Disable Chrome's autofill style
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--color-layout-fill-base) inset !important;
    }

    fieldset {
      border-color: transparent;
    }
  }

  &__select-option {
    //text-align: center;
  }
}


.planning-sidebar-collapse-button,
.page-work-back {
  width: 24px!important;
  height: 24px!important;
  padding: 0 !important;

  .anticon {
    font-size: 24px!important;
  }
}

.page-work-back {
  font-size: 24px;
}