.section-view-header {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 20px 16px;
  align-items: center;
}
//.section-view-header-buttons {
//  display: flex;
//  gap: 8px;
//  margin-left: auto;
//}

.section-view-header-breadcrumbs {
  padding: 0 4px;
  overflow: hidden;

  .breadcrumbs-new-container {
    li:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}