.regulation-grid-container {
  display: grid;
  width: 100%;
  //height: 100%;
  overflow: hidden;
  grid-gap: 16px;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(850px, 1fr));
  align-items: flex-start;
}

.regulation-list {
  //width: 100%;
  //height: 100%;
  //border-radius: var(--border-radius-sm);
  //background-color: var(--color-white);
  //padding: 8px;
  //overflow-y: auto;
  overflow: hidden;
}

.regulation-card {
  display: flex;
  //border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-white);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &-wrapper {
    .ant-card-body {
      padding: 0 !important;
    }

    //&:active {
    //  background-color: var(--color-gray-weak);
    //}
    //&:active {
    //  background-color: var(--color-gray-base);
    //}
  }

  &-finished {
    &__secondary {
      background: var(--color-layout-fill-base);
    }

    padding: 16px;
    //height: 70px;
    &__round {
      border-radius: 4px;
      //&:hover {
      //  border-radius: 4px !important;
      //}
    }

    //&:hover {
    //   background-color: #E2E6EE;
    //   border-radius: 0;
    // }

    &__title {
      width: 40%;
      word-break: normal;
      font-size: 14px !important;
      font-weight: 500 !important;

      &-fullwidth {
        width: 80%;
      }
    }

    &__author {
      width: 280px;
    }

    &__indicator {
      border-radius: 4px !important;
      color: var(--color-white) !important;
      min-width: 95px !important;
      padding: 8px;
      text-align: center;
    }

    &__sendor {
    }

    &__date {
      width: 95px;
      font-size: 14px !important;
      font-weight: 500;
      color: var(--color-text-weak) !important;
      text-align: right;
    }
  }

  &-progress {
    padding: 12px;

    &__title-container {
      padding: 8px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 29%;

      .title-icon {
        margin-right: 16px;
      }
    }

    &__title {
      width: 100%;
      word-break: normal;
      font-size: 15px !important;
      margin-top: 4px !important;
      font-weight: 500 !important;
    }

    &__date {
      width: 95px;
      font-size: 14px !important;
      font-weight: 500 !important;
      color: var(--color-text-weak) !important;
      text-align: right;
      margin-bottom: 0;
    }
  }
}
