.uiKit-audioRecorder {
  .__btn-record__wrapper {
    transition: all 0.25s;

    .ant-btn {
      z-index: 2;
    }
  }

  .__main__wrapper {
    opacity: 0;
    height: 32px;
    border-radius: 0 6px 6px 0;
    transition: opacity 0.25s;
    pointer-events: none;
  }

  .__visualizer__canvas {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: calc(100% + 8px);
    width: calc(100% + 8px);
    //width: calc(100% - 55px);
    transform: translateX(-4px) translateY(-4px) translateZ(0);
    transform-origin: 50% 50%;
    border-radius: 6px;
    overflow: hidden;
    transition: opacity 0.15s;
    z-index: 1;
    pointer-events: none;
  }

  &.__active {
    .__visualizer__canvas {
      opacity: 1;
    }

    .__btn-record__wrapper {
      border-radius: 6px 0 0 6px !important;
    }

    .__main__wrapper {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
