.employee-page-individual-container {
  padding: 12px;
  border-radius: var(--border-radius-xsm);
  background: var(--color-srf-primary);
}


.employee-page-individual_user-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 1em;
}

.employee-page-individual_email {
  font-size: 14px;
  color: var(--color-gray-weak);
}

.employee-page-individual_roles-list {
  margin: 4px 0;
  span {
    color: var(--color-txt-active);
    font-size: 14px;
    opacity: 0.7;
  }
}

.employee-page-individual_role-divider {
  margin: 0 6px;
  font-weight: bold;
}

.employee-page-individual_info-block {
  display: flex;
  flex-wrap: wrap;

  .employee-page-individual_info-element {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    svg {
      stroke: var(--color-icn-accent);
    }

    a {
      margin-left: 4px;
      margin-right: 16px;
      text-decoration: none;
      font-size: 12px;
      color: var(--color-txt-primary);
      transition: 0.25s;

      &:hover {
        color: var(--color-txt-active);
      }
    }

    span {
      margin-left: 4px;
      font-size: 12px;
      color: var(--color-txt-primary);
      transition: 0.25s;
      white-space: nowrap;
    }
  }
}

.employee-page-individual-personal-info {
  display: grid;
  gap: 8px;
  margin-top: 12px;
}

.employee-page-individual-date {
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 1.4;

  &__text {
    color: var(--color-txt-secondary);
  }

  &__value {
    display: flex;
    gap: 8px;
    color: var(--color-txt-primary);

    span {
      color: var(--color-icn-light);
    }
  }
}

.employee-page-individual-access-type {
  cursor: pointer;

  &_prevent {
    pointer-events: none;
  }
}
