.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  //max-height: calc(100vh - 175px);
  overflow: visible;
  //width: fit-content;

}


@media print {
  .regulation-router {
    overflow: visible !important;
  }
  //body {
  //  background: #2F54EB!important;
  //}
  //#root {
  //  background: #000!important;
  //
  //  & > .ant-app {
  //    background: yellow!important;
  //  }
  //
  //  .app-layout__wrapper {
  //    background: palegoldenrod!important;
  //    padding-bottom: 20px!important;
  //  }
  //
  //  .authorized-layout {
  //    padding-bottom: 20px !important;
  //    background: goldenrod!important;
  //  }
  //
  //
  //  .app-content__wrapper {
  //    background: silver!important;
  //    padding-bottom: 10px!important;
  //  }
  //
  //  .app-layout__wrapper .app-content__wrapper_regulation > .d-stack-row.ml-n2.my-n2.full-height {
  //    padding-bottom: 10px!important;
  //    background-color: orangered !important;
  //  }
  //  .flex-grow-1.d-flex.flex-column.pb-2 {
  //    overflow: visible !important;
  //  }

  //}
  //
  //
  //.editor-content {
  //  background: red!important;
  //}
  //.editor-container {
  //  background: #0BA5EC;
  //}
  //.editor-shell {
  //  background: #00b894!important;
  //}

}
