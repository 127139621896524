.meeting-business-engineer-dialog {
  .ant-modal-title {
    font-size: 20px;
    line-height: 28px;
  }

  .ant-modal-content {
    padding-right: 348px!important;

    &:before {
      content: "";
      width: 348px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background:
        url("../../../../assets/images/meeting-business-engineer-dialog-bg.png") center center no-repeat,
        radial-gradient(201.84% 94.15% at 50% 50%, #FFFFFF 0.93%, #E1E5F5 100%);
      border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    }


  }
}

.meeting-business-engineer-dialog-text {
  white-space: pre-line;
}

.meeting-business-engineer-dialog-footer {
  display: grid;
  gap: 16px;
  width: 100%;

  &__buttons {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 16px;
    margin-bottom: 24px;

    .uiKit-button {
      width: 100%;
      max-width: 400px;
    }
  }

  &__icon {
    position: relative;
    top: -2px;
  }
}
