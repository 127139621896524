.google-calendar-banner {
  display: grid;
  gap: 4px;
  padding: 12px;
  background: var(--color-srf-primary);
  border-radius: var(--border-radius-xsm);
  color: var(--color-txt-primary);
  user-select: none;
  font-size: 12px;
  line-height: 1.4;

  &_connect {
    gap: 0;
  }

}

.google-calendar-banner-header {
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &_connect {
    cursor: default;
    grid-template-columns: 16px 1fr 16px 16px;
  }

  &:hover {
    .google-calendar-banner-header__icon {
      color: var(--color-icn-accent);
    }
  }


  &__icon, &__text, &__arrow {
    display: flex;
  }

  &__arrow,  &__settings {
    color: var(--color-icn-default);
    transition: color .2s ease-in, transform .2s ease-in;
    cursor: pointer;
    display: flex;
    align-items: center;

    a {
      color: inherit;
      display: flex;
      align-items: center;
    }

    &:hover {
      color: var(--color-icn-accent);
    }
  }

  &__arrow {
    &_close {
      transform: rotate(-90deg);
    }
  }

}

.google-calendar-banner-content {
  padding-left: 24px;
  padding-right: 8px;

  &__text {
    &_success {
      color: var(--color-txt-success);
    }
  }

  &__toggle-block {
    margin-top: 12px;
    margin-right: -8px;
  }

  .google-auth-info-switch {
    grid-template-columns: 1fr 28px;

    &__text {
      order: -1;
      font-size: 13px;
    }
  }

}
