.regulation-page-header {
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 16px;
  align-items: center;
}

.create-regulation-editor  {
  box-shadow: none!important;

  .editor-shell {
    border-bottom: none;
  }

}