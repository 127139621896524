.dashboards-page {
  width: 100%;

  &__content-tabs {
    background: var(--color-layout-container);
    border-radius: var(--border-radius-sm);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.25);
  }

  &__content-metrics {
    display: grid;
    gap: var(--indent-md);
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
  }

  &__content-board {
    height: calc(100% - 62px);
  }

  // TODO: Check
  div.ant-typography-edit-content {
    margin-bottom: calc(1em - 16px) !important;
  }
}

//.ant-tabs-top > .ant-tabs-nav {
//  margin: 0 !important;
//
//  &:before {
//    display: none;
//  }
//}

//.ant-tabs-tab {
//  padding: 8px 0 !important;
//}
//
//.ant-tabs-tab-btn {
//  color: #9ba6c2;
//}
//
//.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//  color: #5672ff !important;
//}
//
//.ant-tabs-ink-bar {
//  background-color: #5672ff !important;
//}

.sidebar-visible {
  flex-shrink: 0;
  width: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
}

.sidebar-hide {
  width: 0;
  overflow: hidden;
  transition: 0.3s;
}

.dashboard-title {
  display: none;
  padding: 24px 0;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  color: var(--color-calendar-text-base);
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    &::placeholder {
      color: transparent!important;
    }

  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-left: none;
    background-clip: padding-box;
  }



  .metrics {
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0px, 1fr))!important;

    > .ant-card {
      page-break-inside: avoid;
      page-break-after: auto;
      box-shadow: none;
    }
  }

  .metric-to-dashboard__info-icon,
  .metric-to-dashboard__action,
  .recharts-tooltip-wrapper {
    display: none!important;
  }

  .metric-to-dashboard-description,
  .dashboard-title {
    display: block;
  }

  .metrics_print {
    grid-template-columns: repeat(1, minmax(0px, 1fr))!important;

  }

  .metric-to-dashboard__container__header {
    margin-bottom: 16px;
  }

  .dashboard-content {
    overflow: visible;
    //padding-bottom: 300px;
  }


  .ant-table-wrapper {
    .ant-table-sticky-holder {
      background: #ffffff;
    }

    .ant-table {
      background: #ffffff;
    }

    .ant-table-thead {
      > tr {
        > th, > td {
          border-bottom-color: #f0f0f0;
          background: #fafafa;
          color: rgba(0, 0, 0, 0.88);

          &:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
            &::before {
              background: #f0f0f0;
            }
          }
        }
      }
    }

    .ant-table-tbody {
      >tr {
        >td, >th {
          border-bottom-color: #f0f0f0;
        }
      }
    }
  }


  .ant-input-number .ant-input-number-input {
    color: rgba(0, 0, 0, 0.88);
  }

}
