.uiKit-waveForm {
  .wave-form__wrapper {
    .recharts-layer.recharts-bar-rectangle {
      path {
        //fill: url(#progress) !important;
      }
    }
  }

  .wave-form__loader {
    .ant-spin-container {
      opacity: 1;

      &:after {
        display: none !important;
      }
    }
  }
}
