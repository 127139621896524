.box-shadow-block {
  width: 100px;
  height: 100px;
  background: var(--color-primary-base);
  margin: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--color-white);
    font-size: 22px;
  }
}

.show-links-button {
  position: absolute !important;
  top: 4px;
  left: 4px;
  z-index: 111;

  &.__hidden {
    display: none;
  }
}

//.rich-text-editor__wrapper {
//  box-sizing: border-box;
//  padding: 8px;
//  overflow-y: auto;
//  background-color: var(--color-white);
//  width: 100%;
//  min-height: 200px;
//  border-radius: 4px;
//  border: 0.5px solid var(--color-gray-base);
//  &:hover{
//    border: 1px solid var(--color-primary-base);
//  }
//  //&:focus{
//  //  border: 1px solid var(--color-primary-base);
//  //}
//
//}
