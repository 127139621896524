.expandable-container__wrapper {
  .expandable-container__button {
    cursor: pointer;
    .expandable-container__title {
      transition: 0.1s;
    }
    &:hover {
      .expandable-container__title {
        color: var(--color-primary-base);
      }
    }
    svg {
      transition: transform 0.3s;
    }
  }
}
