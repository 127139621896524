.communication_sort-menu {
  &__container {
    flex-shrink: 0;
    flex-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 4px;
    border-radius: 4px;
  }
  &__text {
    font-size: 14px;
    margin-left: 4px;
    color: var(--color-primary-base) !important;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.sort-text {
  cursor: pointer;
  text-align: start;
  font-size: 14px !important;

  font-weight: 400 !important;
  &-select_text {
    color: var(--color-primary-base);
    //color: black !important;
    &:hover {
      opacity: 0.8;
    }
  }
}
.icon-paper {
  button {
    border-radius: 4px !important;
    span {
      border-radius: 4px !important;
    }
  }
}
