//.countdown {
//  display: flex;
//  gap: 20px;
//  justify-content: center;
//  align-items: center;
//}

.time-count-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 40px;
  overflow: hidden;
  position: relative;

  &__value {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    transition: transform 0.5s ease, opacity 0.5s ease;
    font-size: 14px;

    &_new {
      opacity: 0;
      transform: translateY(70%);
    }
  }

  &__label {
    font-size: 12px;
  }
}


.time-count-item-wrapper {
  position: relative;
  height: 20px;
  width: 100%;
}


.fade-up-new-value {
  animation: fadeUpNewValue 0.5s forwards;
}

.fade-up-old-value {
  animation: fadeUpOldValue 0.7s forwards;
}


@keyframes fadeUpNewValue {
  0% {
    transform: translateY(70%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeUpOldValue {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}


