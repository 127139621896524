.list-users-dialog {


  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 95vh;
    overflow: hidden;
  }

  .ant-modal-body {
    margin-right: -15px;
    padding-right: 15px;
    overflow: auto;
  }

}

.list-users {
  display: grid;
  gap: 16px;
  justify-content: start;
}