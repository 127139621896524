.time-picker-quick-value {
  display: flex;
  padding: 2px 8px;
  cursor: pointer;
  background: var(--color-calendar-default);
  color: var(--color-gray-second);
  font-size: 12px;
  transition: color .2s ease-in, background-color .2s ease-in;
  border-radius: var(--border-radius-md);

  &:hover {
    background: var(--color-primary-weaker);
    color: var(--color-layout-drawer);
  }
  
  &_active {
    background: var(--color-primary-weaker-second);
    color: var(--color-layout-drawer);
    pointer-events: none;
  }
}