.control-buttons {
  display: flex;
  gap: 8px;
}


//.control-buttons {
  //&__icon-container {
  //  width: 40px;
  //  height: 30px;
  //  border-radius: var(--border-radius-sm);
  //  border: 1px solid var(--color-primary-base);
  //}
  //
  //&__icons {
  //  color: var(--color-text-base);
  //  cursor: pointer;
  //  &:hover {
  //    color: var(--color-primary-base);
  //  }
  //}
//}
