.ai-assistant-loader {
  display: inline-flex;
  gap: 2px;
  height: 36px;
  padding: 12px 21px;
  border-radius: 24px;
  background: var(--color-srf-primary);
  align-items: flex-end;

  &__dot {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: var(--color-txt-primary);
    animation: dot-pulse-up 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.4s;
    }

    &:nth-child(2) {
      animation-delay: -0.2s;
    }

  }
}

@keyframes dot-pulse-up {
  0%, 100% {
    transform: scale(0.6) translateY(0);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.0) translateY(-6px);
    opacity: 1;
  }
}


