.company-card-main_sheet__wrapper {
  border-bottom: 1px solid var(--color-brdr-default);
  border-radius: 0;
  padding: 4px;


  &:hover {
    //box-shadow: var(--shadow-down-sm);
    //border-left: 4px solid var(--color-primary-base);
  }

  .company-card-main_sheet {
    position: relative;
    //overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.company-card-image {
  background: var(--color-primary-base);
}

.company-card-name {
  font-weight: bold;
  font-size: 15px;
  display: block;
  //color: var(--color-dark-main);
}

.company-card-secondary {
  font-size: 12px;
  color: var(--color-txt-secondary);

  &.__content {
    color: var(--color-txt-primary);
  }
}

.company-card__boards-table {
  background: var(--color-layout-fill-base) !important;
}
