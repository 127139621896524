.format-select {
  cursor: pointer;
  transition: 0.2s background ease-in-out;
  border-radius: var(--border-radius-md);

  color: var(--color-icn-default);

  .uiKit-text {
    color: var(--color-txt-secondary);
  }

  &:hover {
    //opacity: 0.8;
    background: var(--color-layout-fill-secondary);

  }
}
