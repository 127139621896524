.ai-assistant-message {
  display: grid;
  gap: 16px;

  &__request {
    display: inline-flex;
    margin-left: auto;
    max-width: 70%;
    padding: 8px 20px;
    border-radius: 24px;
    background: var(--color-icn-accent);
    color: var(--color-txt-alwayswhite);
  }

  &__response {
    color: var(--color-txt-primary);
    font-size: 14px;

    .ant-btn {
      margin-top: 4px;
    }

    * {
      user-select: text;
      -webkit-user-select: text;
    }

    hr {
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
}


