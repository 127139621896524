.forgot_password_link {
  text-decoration: none;
  margin-top: 8px;
  color: var(--color-txt-link);
  display: block;

  &:hover {
    color: var(--color-txt-link-hover);
  }
}
