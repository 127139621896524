.regulation-content-header {
  width: 1000px;
  max-width: 95%;
  min-width: 453px;
  margin: 0 auto;

  .editable-typography__title-big {
    font-size: 36px!important;
    font-weight: 700!important;
  }

}

.regulation-content-header-access-icon {
  color: var(--color-radio-border);
  display: flex;
  font-size: 20px;

  @media print {
    display: none;
  }
}
