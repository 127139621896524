.role-card {
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  gap: 12px;
  align-items: center;
  padding: 7px 15px;
  border: 1px solid var(--color-text-weakest);
  border-radius: var(--border-radius-xsm);

  &__title {
    color: var(--color-calendar-text-base);
    font-size: 12px;
  }

  &__close {
    display: flex;
    cursor: pointer;
    color: var(--color-calendar-text-second);
    transition: color .2s ease-in;

    &:hover {
      color: var(--color-primary-base);
    }

  }
}