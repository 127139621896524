.uiKit-shape {
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;

  &.__square {
    //
  }

  &.__circle {
    border-radius: 50%;
  }
}
