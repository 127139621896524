.report-card {
  &_active {
    td {
      color: var(--color-primary-base) !important;

      &:hover {
        text-decoration: underline;
        //color: var(--color-primary-base);
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7 !important;
    //td{
    //  color: var(--color-primary-base);
    //}
  }
}

.recurring-issue-history {
  &__dialog {
    width: 80% !important;
  }
  &__table {
    td {
      white-space: nowrap;
    }
    .__cell-clipped {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
