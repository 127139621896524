.uiKit-autocompleteAsync {
  max-width: 100%;

  &_no-border {
    .ant-select-selector {
      border: none!important;
    }
  }
}

