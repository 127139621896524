.companies-management-info-block {
  display: grid;
  gap: 8px;

  &__title {
    font-size: 12px;
    color: var(--color-txt-secondary);
  }

  &__number {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-txt-primary);
  }
}
