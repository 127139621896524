.communication-header-content__calendar-planning__wrapper {
  .ant-select-selector {

    border-color: var(--color-srf-secondary);

    &:hover {
      border-color: var(--color-brdr-active)!important;
    }

  }

  .toggle-sidebar-open {
    width: 28px;
    height: 28px;
    background: transparent;
    color: var(--color-txt-secondary)!important;
    transition: border-color .2s ease-in;
    border-color: var(--color-brdr-light)!important;

    &:hover {
      border-color: var(--color-brdr-active)!important;
    }

    &_close {
      border-color: var(--color-brdr-accent)!important;
    }
  }
}
