.orgchart-function-drawer-header__wrapper {
  .orgchart-function-drawer__tabs_header {
    .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-tab {
      padding: 8px;

      .ant-tabs-tab-btn {
        text-transform: none !important;
      }
    }
  }

  .orgchart-function-drawer-header__description__wrapper {
    &:not(.__expanded) {
      position: relative;

      textarea {
        position: relative;
        //overflow-y: hidden;
      }

      //&:before {
      //  content: "";
      //  position: absolute;
      //  right: 0;
      //  top: 0;
      //  height: 100%;
      //  width: 36px;
      //  background: var(--color-layout-drawer);
      //  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--color-layout-drawer) 100%);
      //  z-index: 1;
      //}
    }
  }

  .orgchart-function-drawer-header__result__input {
    &:not(:focus) {
      color: var(--color-primary-base) !important;
    }
  }
}
