.regulation-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 16px;
  //padding-left: 8px;
  //padding-right: 8px;
  //min-height: 40px;
  padding: 16px;

  &__input-search {
    > div {
      background-color: var(--color-white) !important;
    }

    &:hover :not(:focus-within) {
      fieldset {
        border-color: var(--color-primary-trans-1) !important;
      }
    }

    // Disable Chrome's autofill style
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--color-layout-fill-base) inset !important;
    }

    fieldset {
      border-color: transparent;
      //box-shadow: var(--shadow-down-sm) !important;
    }
  }

  &__align {
    //padding-left: 24px;
    //padding-right: 24px;
  }

  &-group {
    display: flex;
    align-items: center;
  }

  &_button-group {
    margin-left: 8px;

    .stats-icon {
      span {
        svg {
          width: 19px !important;
          height: 19px !important;
        }
      }

      //fill: var(--color-white);
    }

    .add-icon {
      &-19 {
        span {
          svg {
            width: 19px !important;
            height: 19px !important;
          }
        }
      }

      &-17 {
        span {
          svg {
            width: 17px !important;
            height: 17px !important;
          }
        }
      }

      fill: var(--color-white);
    }

    .circle-button {
      padding: 6.5px;
      border-radius: 4px;
      background-color: #7e7e9c;

      &:hover {
        background-color: darken(#7e7e9c, 10%);
      }
    }
  }
}
