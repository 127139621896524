.uiKit-divider {
  box-sizing: border-box;
  width: 100%;
  border-style: solid;
  border-color: var(--color-brdr-default);

  &_new-design {
  }

  &.__vertical {
    width: auto;
    //height: 100%;
    height: auto;
  }

  &:before,
  &:after {
    border-style: solid;
    border-color: var(--color-brdr-default);
  }

  &.__empty {
    border-width: thin 0 0 0;

    &.__vertical {
      border-width: 0 0 0 thin;
    }
  }

  &:not(.__empty) {
    display: flex;
    align-items: center;
    border-width: 0 0 0 0;
    height: 100%;

    &:before,
    &:after {
      border-width: thin 0 0 0;
      position: relative;
      content: "";
      display: block;
      flex-grow: 1;
      height: 1px;
    }

    &:before {
      margin: 0;
      margin-right: 4px;
    }

    &:after {
      margin: 0;
      margin-left: 4px;
    }

    &.__align-start {
      &:before {
        max-width: 15%;
      }
    }

    &.__align-center {
      //
    }

    &.__align-end {
      &:after {
        max-width: 15%;
      }
    }

    &.__vertical {
      flex-direction: column;

      &:before,
      &:after {
        border-width: 0 thin 0 0;
        height: auto;
        width: 1px;
      }

      &:before {
        margin: 0;
        margin-bottom: 2px;
      }

      &:after {
        margin: 0;
        margin-top: 2px;
      }

      &.__align-start {
        &:before {
          height: 15%;
          flex-grow: 0;
        }
      }

      &.__align-center {
        //
      }

      &.__align-end {
        &:after {
          height: 15%;
          flex-grow: 0;
        }
      }
    }
  }
}
