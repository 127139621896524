.messenger-card {
  background-color: var(--color-layout-fill-base);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  &__bot-name {
    font-size: 14px;
    font-weight: 400;
  }
}
