.form-switch .ant-form-item-control-input {
  min-height: 0;
}

.chart-name-icon {
  cursor: pointer;
  opacity: 0.5;
  transition: 0.15s;
  stroke: var(--color-primary-base);

  &:hover {
    opacity: 0.8;
  }
}


.metric-edit-dialog {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 95vh;
    overflow: hidden;
  }

  .ant-modal-body {
    margin-right: -15px;
    padding-right: 15px;
    overflow: auto;
  }
}