.metric-to-dashboard-dialog-card__wrapper {
  //overflow-x: hidden;
  //display: grid;
  //grid-gap: 4px;
  .metric-to-dashboard-dialog-card {
    &__container {
      margin: 4px 0;
      background: var(--color-layout-divider);
    }
    &__content {
      font-size: 12px;
      min-width: 0;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      div {
        span {
          font-size: 10px;
          white-space: normal;
        }
      }
    }
  }
}
