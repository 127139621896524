.ui-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 32px;
  padding: 0 40px;
  box-sizing: border-box;
  border-radius: var(--border-radius-xl);
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 1;

  &_primary {
    border-color: var(--color-primary-weaker-second);
    color: var(--color-primary-weaker-second);
  }

  &_success {
    border-color: var(--color-success-second);
    color: var(--color-success-second);
  }

  &_error {
    border-color: var(--color-error-second);
    color: var(--color-error-second);

  }
}