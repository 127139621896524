.zoom-active {
  input {
    transition: all 0.9s;
    &::placeholder {
      transition: all 0.2s;
      color: var(--color-txt-active);
    }
  }

  > span {
    //transition: all 0.9s;
    svg {
      transition: all 0.2s;
      stroke: var(--color-txt-active);
    }
  }
}

.background-inherit {
  > div {
    //background-color: inherit !important;
  }
}

.text-field-custom-standard {
  > div {
    padding-left: 14px !important;

    input {
      padding-right: 14px !important;
      padding-top: 8.5px !important;
      padding-bottom: 8.5px !important;
    }
  }

  &:hover {
    > div {
      &::before {
        border-bottom: 3px solid var(--color-brdr-accent) !important;
      }

      &::after {
        border-bottom: 3px solid var(--color-brdr-accent) !important;
      }
    }
  }
}


.search-input {
  &_new-design {
    .ant-input-suffix {
      color: var(--color-txt-secondary);
    }
  }
}
