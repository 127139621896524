.full-calendar__dialog {
  width: auto !important;
  .ant-modal-content {
    width: 90vw !important;
    height: 90vh !important;
  }
  .ant-modal-body {
    height: 100%;
  }
}
