.file-uploader-item__container {
  margin-right: 8px !important;
  border: 1px solid var(--color-layout-background);
  border-radius: 4px;
  transition: 0.1s;
  //cursor: pointer;
  position: relative;
  min-width: 163px !important;
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  *:not(.file-uploader-item__progress) {
    z-index: 2;
  }
  &-small {
    min-width: 100px !important;
    min-height: 88px !important;
  }
  .file-uploader-item__button--delete,
  .file-uploader-item__button--download {
    visibility: hidden;
  }
  &:hover {
    .file-uploader-item__button--delete,
    .file-uploader-item__button--download {
      visibility: visible;
    }
  }

  .file-uploader-item__preview {
    &-box {
      flex-grow: 0.9;
      justify-content: center;
      align-items: center;
      //background: var(--color-layout-fill-base);
      border-radius: 4px;
      width: 100%;
      height: 110px;
      margin-right: 0 !important;
      padding: 0 !important;
      &-image {
        width: 100%;
        height: 110px;
        background-size: cover;
        background-repeat: no-repeat;
        max-height: 163px;
        object-fit: cover;
        border-radius: 4px;
        &-small {
          height: 60px;
          max-width: 98px;
        }
      }
      &-small {
        height: 60px !important;
        //  //.file-uploader-item__preview-box-small{
        //  //  height: 10px !important;
        //  //}
      }

      svg {
        stroke: var(--color-primary-base);
      }
    }
  }

  .file-uploader-item__name {
    margin-top: 4px;
    margin-bottom: 4px;
    flex-grow: 0.01;
    white-space: nowrap;
    //color: var(--color-gray-base);
    color: var(--color-dark-weak);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px;
    //white-space: nowrap;
  }

  //.file-uploader-item__button--download {
  //  opacity: 0.7;
  //  transition: 0.1s;
  //
  //  &:hover {
  //    opacity: 1;
  //  }
  //
  //  svg {
  //    stroke: var(--color-primary-base);
  //  }
  //}

  .file-uploader-item__button--delete {
    background-color: #a5adba !important;
    opacity: 0.7;
    transition: all 0.1s ease-in-out;
    //position: absolute;
    //right:  4px;
    //top: 4px;
    z-index: 1;
    //visibility: hidden;
    border-radius: 4px !important;
    //height: 2зч;
    &:hover {
      opacity: 1;
    }
    svg {
      stroke: #ffffff;
    }
  }

  .file-uploader-item__button__wrapper {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 100;
  }

  .file-uploader-item__button--download {
    background-color: #a5adba !important;
    opacity: 0.7;
    transition: all 0.1s ease-in-out;
    //position: absolute;
    //right:  4px;
    //top: 4px;
    position: relative;
    z-index: 1;
    //visibility: hidden;
    border-radius: 4px !important;
    //height: 2зч;
    &:hover {
      opacity: 1;
    }
    svg {
      stroke: #ffffff;
    }
  }

  .file-uploader-item__progress {
    position: absolute;
    transition: 0.1s;
    background: var(--color-layout-background);
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
  }
}
