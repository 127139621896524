@use "sass:math";

.app-content__wrapper {
  //.ant-dropdown-trigger {
  //  zoom: 0.8;
  //
  //  > * {
  //    zoom: 1.2;
  //  }
  //}

  .page-content__wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-top-left-radius: var(--border-radius-xsm);
    border-top-right-radius: var(--border-radius-xsm);
    box-shadow: none;

    > .ant-card-body {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    @media print {
      &, > .ant-card-body {
        height: auto !important;
      }
    }
  }

  .page-content__content {
    overflow: auto;
    height: 100%;
    width: 100%;

    &.__centered {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @for $i from 1 through 200 {
      &.__zoom-value-#{$i}.__zoom-combined {
        .__pztZoom {
          zoom: $i * 1%;
        }

        .__pztScale {
          transform-origin: 0 0;
          transform: scale($i * 1%);
          //height: -webkit-fill-available;
          //height: -moz-fill-available;
          height: fill-available;
          width: fill-available;
        }

        //    > div > * {
        //      transform: scale($i * 10%);
        //    }
      }
    }
  }

  .page-content__loader {
    height: 100%;
    width: 100%;

    .ant-spin {
      height: 100%;
      max-height: unset;
    }

    > .ant-spin-container {
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
  }

  @media print {
    &.app-content__wrapper_dashboard {
      padding: 8px 32px!important;
    }
  }
}



/* Warning: this is generally a bad idea */
@for $i from 5 through 15 {
  .__scale-z-#{$i * 10} {
    zoom: $i * 10%
    //zoom: $i / 100;
  }
  .__scale-t-#{$i * 10} > * {
    transform-origin: 0 0 !important;
    //transform: scale(math.div($i, 100));
    transform: scale($i * 10%);
  }
}

.page-content-sheet-tabs {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;

  .ant-tabs-tab,
  .ant-tabs-tab-btn {
    padding: 0 !important;
  }

  .ant-card-body {
    padding: 0 8px !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;

    &:before {
      display: none;
    }
  }

  a {
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 12px !important;
    display: block;
    color: var(--color-txt-active);
    pointer-events: none;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    a {
      pointer-events: all;
      color: var(--color-txt-secondary) !important;
    }

    &:hover {
      a {
        color: var(--color-txt-semisecondary) !important;
      }
    }
  }

  &_new-style {
    box-shadow: none!important;

    .ant-card-body {
      padding: 0 12px!important;
    }

    .ant-tabs-nav-list {
      display: grid;
      gap: 32px;
    }

    .ant-tabs-tab {
      margin: 0!important;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      a {
        color: var(--color-calendar-text-base) !important;
      }

      &:hover {
        a {
          color: var(--color-calendar-primary-base) !important;
        }
      }
    }


    a {
      text-transform: none;
      font-weight: normal;
      padding: 12px 0!important;
    }

    .ant-tabs-tab-active {

      a {
        color: var(--color-calendar-primary-base) !important;
      }

    }
  }
}
