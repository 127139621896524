//.uiKitPage-tabs-main-container {
//  display: inline-flex;
//  flex-wrap: wrap;
//  margin: -4px;
//
//  div {
//    background-color: var(--color-primary-base);
//    border-radius: 8px;
//    font-size: 20px;
//    color: white;
//    text-align: center;
//    margin: 4px;
//
//    &:hover {
//      cursor: pointer;
//    }
//  }
//}
//
.uiKitPage-elements-group {
  margin: -4px;
  display: inline-flex;
  flex-wrap: wrap;
  > * {
    margin: 4px;
  }
}
//
.uiKitPage-page-container {
  width: 350px;
  //margin: auto;
}

.uiKitPage-elements__wrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
