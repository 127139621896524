.employee-individual-block {
  border-radius: var(--border-radius-md);
  background: var(--color-srf-primary);
}

.employee-page-individual_regulation-block__wrapper {
  display: grid;
  grid-template-columns: calc(50% - 6px) calc(50% - 6px);
  gap: 18px 12px;
  //grid-template-rows: auto auto auto;
}
