.info-item {
  padding: 2px 8px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 1.4;

  &_line {
    display: flex;
    gap: 4px;
    font-size: 12px;

    .info-item__title {
      font-weight: bold;
    }

  }

  &__title {
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
    color: var(--color-txt-primary);
  }

  &__icon {
    cursor: pointer;
    color: var(--color-txt-secondary);
    transition: opacity .15s ease-in;

    &:hover {
      opacity: 0.8;
    }

  }

  &__value {
    color: var(--color-txt-secondary);

    &_inherit {
      color: inherit;
    }

    span {
      font-weight: bold;
    }

  }
}
