.dots-loader-wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  justify-content: center;
  align-items: center;
}

.dots-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--color-txt-primary);
    animation: dot-keyframes 1.5s infinite ease-in-out;
    transition: background-color .5s ease-in;

    @for $i from 1 to 64 {
      &:nth-child(#{$i}) {
        animation-delay: ($i - 1) * 0.5s;
      }
    }
  }
}

@keyframes dot-keyframes {
  0% {
    background: var(--color-txt-primary);
  }

  50% {
    background: var(--color-srf-accent);
  }

  90% {
    background: #C3CFFE;
  }

}

.dots-loader-text {
  max-width: 275px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-txt-semisecondary);
  text-align: center;
}
