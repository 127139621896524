$grid-el-height: 70px;
$grid-el-width: 84px;
$grid-el-offset: 4px;

.schedule__dates-list__wrapper {
  z-index: 3;
  //margin-bottom: $grid-el-offset;
  padding-bottom: $grid-el-offset * 2;
  position: sticky;
  top: 0;
  width: fit-content;
  background-color: var(--color-bg-primary);

  .schedule__dates-list__month,
  .schedule__dates-list__day {
    border-radius: 4px;
    overflow: hidden;

    .ant-card-body {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 28px;

      span {
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }

  .schedule__dates-list__day {
    .ant-card-body {
      width: $grid-el-width;
      height: $grid-el-height;
      background: var(--color-srf-primary);
    }

    span {
      span:first-of-type {
        font-size: 24px;
        margin-right: 0.25em;
      }

      span:last-of-type {
        font-size: 14px;
        text-transform: lowercase;
      }
    }

    &.__today {
      span {
        span:first-of-type {
          color: var(--color-primary-base);
        }
      }
    }
  }

  ////z-index: 109;
  //display: flex;
  //position: sticky;
  //top: 0;
  //z-index: 1;
  //padding-bottom: $grid-el-offset * 2.5;
  ////background: inherit;
  //.schedule-period-month_name {
  //  height: 28px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  margin-right: $grid-el-offset;
  //  margin-bottom: $grid-el-offset;
  //  border-radius: 4px;
  //  text-transform: capitalize;
  //  line-height: 1em;
  //  text-align: center;
  //
  //  &:last-child {
  //    margin-right: 0;
  //  }
  //}
}

.schedule__dates-list__limiter {
  //height: 80px;
  width: 288px;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: end;
  box-sizing: content-box;
  background-color: var(--color-bg-primary);
  //margin-right: $grid-el-offset;
  padding-right: $grid-el-offset * 2;
  //margin-right: $grid-el-offset;
}

.schedule__period-picker {
  height: 0 !important;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.schedule__period-picker__button {
  padding: 4px !important;
  border-radius: 4px;
  background: var(--color-srf-active);
  color: var(--color-txt-primary)!important;

  &:hover {
    background: var(--color-srf-accent) !important;
    color: var(--color-txt-alwayswhite)!important;
  }

  &.__no-wrap {
    span {
      white-space: nowrap !important;
    }
  }

  span {
    border-radius: 50%;
    transition: 0.15s;
    margin: auto !important;
    font-size: 12px;
    white-space: break-spaces;
    line-height: 0.8em;
  }

  svg {
    flex-shrink: 0;
  }
}
