.planning-page-metric-content {
  overflow: auto;
  padding-bottom: 8px;

  .ant-spin-nested-loading {
    width: 100%;
  }

  .metric-to-dashboard {
    width: 346px;
    min-width: 346px;
    max-width: 346px;
  }

  .metrics-wrap {
    display: flex;
    gap: 16px;
  }

  .scroll-trigger-block {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .scroll-trigger-wrap {
    padding: 8px 16px;
    margin: 0!important;

    .uiKit-text {
      white-space: nowrap;
    }
  }
}
