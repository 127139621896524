.video_dialog__container {
  //width: 100%;
  //height: 50vh;
  //max-width: 1200px;
  //width: 80vw;
  height: calc(85vh - 100px);
  max-height: 675px;
  max-width: 90vw;
  aspect-ratio: 16/9;
  //height: 100%;
  background: var(--color-layout-fill-base);
  border-radius: 4px;

  .video_dialog__iframe {
    border-radius: 4px;
    overflow: hidden;
  }
}
