.top-app-bar {
  //background-color: #ffffff !important;
  height: 56px !important;
  //border-bottom: 1px solid var(--color-layout-fill-base);
  padding: 0 !important;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  //box-shadow: var(--shadow-down-sm) !important;
  position: fixed;
  z-index: 1;
  width: 100%;

  .ant-btn-default {
    background: transparent!important;
  }

}
