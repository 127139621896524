.filter-clear-button {
  background: var(--color-layout-fill-base) !important;
  border-radius: 4px !important;
  height: 40px !important;
  width: 40px !important;
  flex-shrink: 0;

  svg {
    stroke: var(--color-gray-weak);
  }

  &:hover {
    svg {
      stroke: var(--color-error-base);
    }
  }

  .MuiTouchRipple-root span {
    border-radius: 4px !important;
  }
}

.no-arrow-select {
  .__span {
    display: flex;
    align-items: center;
    line-height: 1em;
  }
  .MuiSelect-select {
    padding-right: 14px !important;
  }
  .MuiButtonBase-root {
    padding: 0 !important;
  }
}
