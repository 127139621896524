.time-picker-quick-values-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.input-wrapper {
  position: relative;

  &_disabled {
    pointer-events: none;
  }

  input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0 38px;
    color: var(--color-txt-primary);
    font-size: 14px;
    line-height: 1;
    align-items: center;
    background: transparent;
    border: 1px solid var(--color-inp-border-default);
    border-radius: 6px;
    transition: border 0.2s, box-shadow 0.2s;
    outline: none;

    &:focus {
      border-color: var(--color-inp-border-focus);

      & + .input-wrapper__clear {
        display: flex;
      }
    }

    &:disabled {
      background: var(--color-layout-fill-tertiary);
      border-color: var(--color-disabled-select-border);
      cursor: not-allowed;
    }
  }

  &__suffix {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__suffix {
    left: 12px;
    color: var(--color-inp-icon);
  }

  //&__clear {
  //  display: none;
  //  right: 16px;
  //  z-index: 2;
  //  cursor: pointer;
  //}

  &:hover {
    input {
      border-color: var(--color-inp-border-hover);
    }

    .input-wrapper__clear {
      display: flex;
    }

    .input-wrapper-arrow {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.input-wrapper-arrow {
  display: grid;
  grid-template-rows: 1fr 1fr;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;

  &__button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    line-height: 1;
    border-style: solid;
    border-width: 0 0 1px 1px;
    border-color: var(--color-inp-border-default);
    color: var(--color-inp-icon);;
    cursor: pointer;
    transition: color .2s ease-in, border-color .2s ease-in;
    user-select: none;
    outline: none;
    background: transparent;

    &_minus {
      z-index: 2;
      margin-top: -0.5px;
      border-width: 1px 0 0 1px;

    }

    &:hover {
      z-index: 3;
      border-color: var(--color-inp-border-hover);
      color: var(--color-inp-border-hover);
    }


  }

}
