.custom-confirm-dialog {
  &__no-content {
    &.ant-modal-content {
      .ant-modal-header {
        border-bottom: none !important;
      }
      .ant-modal-footer {
        border-bottom: none !important;
      }
    }
  }

  .dialog-subtitle {
    white-space: pre-line;
  }
}
