.calendar-planning-sidebar {
  &__resizer {
    height: 100% !important;
    position: relative;
    overflow: hidden;

    .react-resizable-handle {
      position: absolute;
      background-color: var(--color-primary-base);
      background-clip: content-box;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.15s;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 1;
      }
    }

    .react-resizable-handle-e {
      right: 0;
      top: 0;
      height: 100%;
      width: 10px;
      cursor: ew-resize;
      padding-left: 8px;
    }
  }

  &__wrapper {
    width: 100%;
    //width: 260px;
    background: var(--color-srf-sidebar);
    //overflow-y: auto;
    overflow: hidden;

    .calendar-planning-sidebar__content {
      overflow-y: auto;
      flex-grow: 1;
    }
  }

  &__arrow-button {
    cursor: pointer;
    color: var(--color-text-base);

    &:hover {
      color: #5672ff;
    }
  }
}

.calendar-planning-sidebar-header {
  display: grid;
  gap: 12px;

  .uiKit-iconButton {
    background: transparent;
  }
}
