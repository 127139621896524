.uiKit-select {
  max-width: 100%;
  //min-width: 250px;
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-typography.uiKit-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:not(:hover):not(.ant-select-open).__filled {
    .ant-select-selector {
      border-color: transparent !important;
    }
  }
  &:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover {
    .ant-select-selector {
      border-color: var(--color-inp-border-focus);
    }
  }
  &_disabled {
    pointer-events: none;

    .ant-select-selector {
      color: var(--color-inp-text-placeholder)!important;
      //background: var(--color-layout-fill-tertiary)!important;
      cursor: not-allowed!important;
      border-color: var(--color-inp-fill-disabled)!important;
    }
    .ant-select-selection-item-remove {
      pointer-events: all;
    }

  }


  &_new-design {
    color: var(--color-inp-text-filled);


    &.ant-select-lg {
      height: 36px;

      .ant-select-selector {
        font-size: 14px;
        line-height: 1.4;
        padding: 0 15px!important;
      }

    }

    &.uiKit-select_big {
      height: 40px;
    }
  }

  &_no-border {
    .ant-select-selector {
      border: none!important;
    }
  }

  .ant-select-selection-search-input,
  .ant-select-selection-placeholder {
    height: 100% !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

}


.ant-input-group-addon {
  .uiKit-select {
    max-width: unset;
  }
}
