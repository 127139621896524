.progress-bar {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid var(--color-icn-success);
  background: transparent;
  max-width: 360px;
  min-width: 100px;
  width: 100%;

  .progress-bar__determinate {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--color-icn-success);
  }

  .progress-bar__percentage_placeholder {
    line-height: 20px;
    position: relative;
    display: block;
    font-size: 10px;
    text-align: center;
    visibility: hidden;
  }

  .progress-bar__percentage {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 20px;
    display: block;
    font-size: 10px;
    text-align: center;
    color: var(--color-txt-primary);
  }
}
