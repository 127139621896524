.type-function {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  height: 32px;
  font-size: 12px;
  cursor: pointer;
  color: var(--color-txt-active);
  background: var(--color-srf-secondary);
  border-radius: var(--border-radius-sm);
  transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &_icon {
    display: flex;
  }

  &:hover {
    background: rgba(var(--color-layout-container), 0.06)
  }

}
