.ai-assistant-content {
  position: fixed;
  top: 46px;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 560px;
  background: var(--color-srf-gradient);
  padding: 24px;
  margin-top: -4px;
  //max-height: 700px;
}

.ai-assistant-content-content-history-list {
  display: grid;
  gap: 16px;
}

.ai-assistant-content-header {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.ai-assistant-content-footer {
  margin-top: auto;
}

.ai-assistant-content-wrapper {
  overflow: auto;
  margin: 0 -24px;
  padding: 0 24px 8px;
}
