.section-content {
  .ant-card-head {
    padding: 16px 12px !important;
    border-bottom: none !important;
  }

  .ant-card-body {
    padding: 0 !important;
  }

}

.section-content-cell-title {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 8px;
  cursor: pointer;
  transition: color .2s ease-in;

  &:hover {
    color: var(--color-txt-active);
  }

  &__icon {
    display: flex;
    color: var(--color-icn-default);
  }
}

.section-content-cell-action {
  cursor: pointer;
  color: var(--color-icn-default);
}

.section-content-cell-favorites {
  cursor: pointer;
  transition: color .2s ease-in;
  color: var(--color-icn-light);

  &:hover {
    color: var(--color-txt-secondary);
  }

  &_active {
    color: var(--color-icn-warning);

    &:hover {
      color: var(--color-warning-weak);
    }
  }
}

.section-content-header {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 28px;
}

.section-content-header-access-icon {
  color: var(--color-radio-border);
  display: flex;
  font-size: 20px;
}
