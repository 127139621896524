.issue-activity-text {
  .editor-shell {
    padding: 0;
  }

  &_inline {
    display: flex;
    padding: 4px 8px;
    border-radius: var(--border-radius-xsm);
  }

  .uiKit-text {
    //color: #171E40;
  }

}
