.function-position-card__wrapper {
  //background: var(--color-layout-fill-base);
  //border-radius: 4px;
  //position: relative;
  //overflow: hidden;
  //margin-left: 4px;

  //&:before {
  //  content: "";
  //  position: absolute;
  //  left: -8px;
  //  top: -4px;
  //  opacity: 0.5;
  //  background: var(--color-lines-main);
  //  //height: $card-margin * 2 - 4;
  //  height: 100%;
  //  width: 1px;
  //  //bottom: -2px;
  //  //height: 100%;
  //}

  &:hover {
    .function-position-card__header {
      .__button-delete {
        opacity: 0.45;
      }
    }
  }

  .function-position-card__header {
    .__button-delete {
      transition: 0.15s;
      opacity: 0;
    }
  }

  .function-position-card__users {
    .__button-delete {
      transition: 0.15s;
      opacity: 0;
    }

    &:hover {
      .__button-delete {
        opacity: 0.45;
      }
    }

    .ant-select {
      input {
        cursor: pointer !important;
      }
      span {
        transition: 0.15s;
        font-weight: bold;
        color: var(--color-primary-base);
        font-size: 14px;
      }
      svg {
        display: none;
      }
      &:hover {
        span {
          opacity: 0.8 !important;
        }
      }
    }
    fieldset {
      border: none !important;
    }
  }
}
