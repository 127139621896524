.metric-to-dashboard {
  .metric-to-dashboard__container__header {
    max-width: 100%;



    .metric-to-dashboard__container__title {
      line-height: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .metric-to-dashboard__container__content {
    position: relative;
  }

  //  &__container {
  //    flex: 0 0 49%;
  //    //height: 350px;
  //    background: #FFFFFF;
  //    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  //    border-radius: var(--border-radius-sm);
  //    border: 1px solid #EFF0F5;
  //
  //    &:hover {
  //      border: 1px solid #5672FF;
  //      cursor: pointer;
  //    }
  //
  //    &__title {
  //      font-size: 12px;
  //    }
  //
  //    &__creator {
  //      color: #9BA6C2;
  //      font-size: 12px;
  //    }
  //
  //    &__button {
  //      color: #9BA6C2;
  //
  //      &:hover {
  //        color: #5672FF;
  //        cursor: pointer;
  //      }
  //    }
  //  }
}

.metric-to-dashboard-header-wrap {
  max-width: calc(100% - 24px);
}

.dashboard-metric-description {
  white-space: pre-wrap;
}

.metric-to-dashboard-description {
  display: none;
  margin-top: 8px;
  font-size: 7px;
  line-height: 1.4;
  color: var(--color-calendar-text-base);
}

.metric-to-dashboard-function {
  display: none;

  @media print {
    display: block;
  }
}
