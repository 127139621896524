.issue-options {
  svg {
    width: 24px;
    height: 24px;
    stroke: var(--color-gray-weak);
    transition: 0.25s;
  }

  &:hover {
    svg {
      stroke: var(--color-primary-base);
    }
  }
}
