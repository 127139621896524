//.recharts-layer.recharts-cartesian-axis.recharts-yAxis.recharts-layer {
//  pointer-events: none;
//  .recharts-layer.recharts-cartesian-axis-tick {
//    pointer-events: auto;
//  }
//}

.dashboard-chart__wrapper {
  --color-metric-line-1-normal: var(--color-metric1);
  --color-metric-line-1-increase: var(--color-metric-rise);
  --color-metric-line-1-decrease: var(--color-metric-fall);
  --color-metric-line-2-normal: var(--color-metric2);
  --color-metric-line-2-increase: var(--color-metric-risedouble);
  --color-metric-line-2-decrease: var(--color-metric-fall);
  --color-metric-quota-normal: var(--color-metric-quota);
  --color-metric-trend-1-increase: var(--color-metric-rise);
  --color-metric-trend-1-decrease: var(--color-metric-fall);
  --color-metric-trend-2-increase: var(--color-metric-risedouble);
  --color-metric-trend-2-decrease: var(--color-metric-fall);

  .recharts-legend-wrapper {
    max-width: calc(100% - 48px);
    overflow: hidden;
    left: 48px !important;
    //bottom: -4px !important;
    //.recharts-legend-item {
    //  line-height: 1.35em !important;
    //  font-size: 12px;
    //  display: flex !important;
    //  align-items: center;
    //}
  }
}

.dashboard-chart__tooltip {
  //background-color: rgba(0, 0, 0, 0.05);
  //backdrop-filter: blur(6px);
  //border: 1px solid white;
  //background-color: rgba(1, 1, 1, 0.05) !important;
  //opacity: 1;
  //pointer-events: all !important;
  //transition: all 0.15s;
  &:hover {
    //opacity: 0.3;
    //background-color: rgba(255, 255, 255, 1) !important;
  }
}

//html[data-app-theme-id="1"] {
//  .dashboard-chart__wrapper {
//    --color-metric-line-1-normal: #94a2ea;
//    --color-metric-line-1-increase: #56b375;
//    --color-metric-line-1-decrease: #ff4444;
//    --color-metric-line-2-normal: #ffa945;
//    --color-metric-line-2-increase: #5672ff;
//    --color-metric-line-2-decrease: #ff4444;
//    --color-metric-quota-normal: var(--color-layout-fill-tertiary);
//    --color-metric-trend-1-increase: #35b29a;
//    --color-metric-trend-1-decrease: #e17364;
//    --color-metric-trend-2-increase: #91a3fc;
//    --color-metric-trend-2-decrease: #e17364;
//  }
//}

.dashboard-chart-label-list {
  display: none;

  @media print {
    display: block;
    font-size: 18pt!important;
    color: var(--color-txt-primary);
  }
}
