.regulation-sidebar {
  &__resizer {
    height: 100% !important;
    position: relative;
    overflow: hidden;

    .react-resizable-handle {
      position: absolute;
      background-color: var(--color-primary-base);
      background-clip: content-box;
      box-sizing: border-box;
      opacity: 0;
      transition: opacity 0.15s;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 1
      }
    }

    .react-resizable-handle-e {
      right: 0;
      top: 0;
      height: 100%;
      width: 10px;
      cursor: ew-resize;
      padding-left: 8px;
    }
  }

  &__wrapper {
    width: 100%;
    //width: 260px;
    background: var(--color-srf-sidebar);
    //overflow-y: auto;
    overflow: hidden;

    .regulation-sidebar__content {
      overflow-y: auto;
      flex-grow: 1;

      .ant-tree-list-scrollbar {
        width: 6px!important;
        background: var(--color-layout-fill-base);

        .ant-tree-list-scrollbar-thumb {
          background: var(--color-primary-weak) !important;
          border-radius: 0 !important;
        }

      }

      .ant-tree-list-holder-inner {
        .ant-tree-treenode {
          font-size: 12px;
          border-bottom: 1px solid var(--color-brdr-default);

          &.ant-tree-treenode-selected {
            color: var(--color-txt-active) !important;
            //  cursor: not-allowed !important;
            .ant-tree-node-content-wrapper {
              background: transparent !important;
            }
          }
        }

        .ant-tree-switcher.ant-tree-switcher-noop {
          display: none;
        }

        .ant-tree-node-content-wrapper {
          display: flex;
          overflow: hidden;
          //white-space: nowrap;
          //overflow: hidden;
          //text-overflow: ellipsis;

          .ant-tree-icon__customize {
            flex-shrink: 0;
          }
        }

        .ant-tree-title {
          overflow: hidden;
          flex-grow: 1;

          > span {
            //pointer-events: all;
            //cursor: not-allowed;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inherit;
            width: 100%;
          }
        }
      }
    }
  }
}

.regulation-sidebar-header {
  display: grid;
  grid-template-columns: 1fr 32px;
  gap: 16px;
  margin-bottom: 12px;
}

.arrow-button {
  cursor: pointer;
  color: var(--color-icn-default);

  &:hover {
    color: var(--color-icn-accent);
  }
}


.tree-switcher-icon {
  color: var(--color-icn-light);
  transition: color .15s ease-in;

  &:hover {
    color: var(--color-icn-accent);
  }
}

.regulation-menu-item {
  align-self: auto!important;
  align-items: center!important;
  padding: 8px!important;
  border-bottom: 1px solid var(--color-sidebar-item-border) !important;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  &__content {
    display: flex;
    gap: 8px;
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    width: 16px!important;
    line-height: 16px!important;
    margin-right: 8px!important;
  }

  .ant-tree-indent-unit {
    width: 8px!important;
  }

  .ant-tree-node-content-wrapper {
    padding: 10px 0!important;
    margin: -10px 0;
  }

  &__content {
    display: flex;
    align-items: center;
  }


  &__icon {
    display: flex;
  }

  &__button {
    color: var(--color-icn-default);
    transition: color .2s ease-in;
  }

}


.regulation-menu-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 8px;

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &_draft {
      color: var(--color-radio-border);
    }
  }

  &__label {
    flex-shrink: 0;
    padding: 2px 4px;
    font-size: 10px;
    text-transform: uppercase;
    color: var(--color-radio-border);
    background: var(--color-calendar-default);
    border-radius: var(--border-radius-xsm);
  }
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.ant-tree-drop-indicator {
  bottom: 3px!important;
}
