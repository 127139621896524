.request-change-access-type-dialog {
  min-width: 645px;
  width: auto!important;

  .ant-modal-body {
    padding-bottom: 24px!important;
  }

}

.request-change-access-type {
  display: grid;
  gap: 20px;
  color: var(--color-calendar-text-base);
  line-height: 1.4;


  &__text {
    font-size: 14px;
  }
}

.request-change-access-type-content {
  display: grid;
  gap: 24px;

  &__title {
    font-size: 12px;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }
}

.request-change-access-type-content-wrap {
  display: grid;
  gap: 4px;
}