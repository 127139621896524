.sidebar-visible {
  flex-shrink: 0;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
}

.sidebar-hidden {
  width: 0;
  overflow: hidden;
  transition: 0.3s;
}

.planning-my-plan-sidebar {
  &__wrapper {
    width: 300px;
    background: var(--color-srf-sidebar);
    //overflow-y: auto;
    overflow: hidden;

    @media print {
      display: none;
    }

    .planning-my-plan-sidebar__content {
      overflow-y: auto;
      flex-grow: 1;
      margin: -16px;
      padding: 16px;


      //todo-list__card

      .ant-tree-list-holder-inner {
        .ant-tree-treenode {
          &.__active {
            color: var(--color-primary-base) !important;
            cursor: not-allowed;

            .ant-tree-node-content-wrapper {
              pointer-events: none;
            }
          }

          //max-width: 100%;
          font-size: 12px;
          border-bottom: 1px solid var(--color-layout-divider);
        }

        .ant-tree-switcher.ant-tree-switcher-noop {
          display: none;
        }

        .ant-tree-node-content-wrapper {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-tree-title {
          //text-overflow: ellipsis;
          //overflow: hidden;
          //white-space: nowrap;
        }
      }
    }
  }

  &__arrow-button {
    cursor: pointer;
    color: var(--color-text-base);

    &:hover {
      color: #5672ff;
    }
  }

  &__content {
    .card-issue {
      padding: 4px;
    }
  }
}

.planning-my-plan-sidebar-header {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .uiKit-button {
    background: transparent;

    .anticon {
      font-size: 24px;
    }

  }

}
