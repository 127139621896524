.board-collapse {

  .ant-collapse-item {
    .ant-collapse-header {
      //background-color: var(--color-background-strong);

      .ant-collapse-header-text {
        max-width: 85%;
      }
    }

    .ant-collapse-content {
      overflow: hidden;

      .ant-collapse-content-box {
        padding: 0;
        overflow-y: hidden;
        //background-color: var(--color-background-strong) !important;

        > div {
        }
      }
    }
  }
}

.collapse-header__chip {
  height: 23px;
  border-radius: 50%;
  text-align: center;
  background: var(--color-primary-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    padding: 0 8px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    alight-items: center;
    color: #ffffff;
  }
}
