.planning-page-main {
  @media print {
    display: none;
  }
}

.planning-page-main-content {
  &__wrapper {
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      max-width: 1172px;
    }

    @media (min-width: 1921px) {
      max-width: 87%;
    }

    .ui-label {
      padding: 0 8px;
      width: 155px;
    }

  }

  &__icon {
    width: 40px;
    height: 40px;
    background: var(--color-background-strong);
    border-radius: var(--border-radius-sm);
  }

  &__arrow-button {
    cursor: pointer;

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
  }

  &__text {
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-text-weak);
    align-self: flex-start;

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__total {
    height: 40px;
    background: var(--color-background-strong);
    border-radius: var(--border-radius-md);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
  }

  &__indicator {
    width: 200px;
    height: 40px;
    font-weight: 700;
    border-radius: var(--border-radius-sm);
  }
}

.empty-block-wrapper {
  height: calc(100% - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.planing-info-block {
  box-shadow: none!important;
  background: var(--color-srf-primary)!important;

  &_transparent {
    background: var(--color-srf-primary)!important;
  }

  .ant-card-body {
    display: flex;
    //align-items: center;
    justify-content: space-between;

    &:before, &:after {
      display: none;
    }
  }
}

.planing-info-wrap {
  display: flex;
  gap: 40px;
}

.planing-info-item {
  display: grid;
  gap: 4px;
  font-size: 14px;

  &__title {
    display: flex;
    gap: 4px;
    color: var(--color-calendar-text-base);

  }

  &__icon {
    cursor: pointer;
    color: var(--color-calendar-text-second);
    transition: opacity .15s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }

  &__value {
    font-size: 12px;
    color: var(--color-calendar-text-second);

    span {
      font-weight: 700;
    }
  }
}
