.__orgchart-vfp-block {
  > div {
    position: relative;
  }

  .__font-size_buttons {
    position: absolute;
    right: 8px;
    top: 8px;
    background: var(--color-layout-container);
    border-radius: 6px;
    box-shadow: var(--shadow-down-lg);
  }

  &:not(:hover) {
    .__font-size_buttons {
      display: none !important;
    }
  }
}
